import { RegRepFileInputFormat } from '@/firestore/api/regRepProcess.ts';

/**
 * Combined enum/type for S213 file format
 * All values from RegRepFileInputFormat are available
 * but additional formats are only available in local environment
 */
export const S213Format = {
    generic: 'generic',
    bob50: 'bob50',
    // Additional formats that are available in local environment
    ...(import.meta.env.VITE_ENVIRONMENT === 'local' ? {
        yardi: 'yardi',
        GP4: 'GP4',
        Multifonds: 'Multifonds',
    } : {})
} as const;

// Type created from the enum values
export type S213Format = typeof S213Format[keyof typeof S213Format];

// Format options mapped to display labels
export const s213FormatOptions = Object.entries(S213Format).map(([key, value]) => ({
    label: key.charAt(0).toUpperCase() + key.slice(1), // Capitalize first letter
    value
})); 