import { collection } from 'firebase/firestore';

import { getRequestQueries } from '@/api/utils/getRequestQueries';
import { FbModelBaseReviewer } from '@/types/modelBase.ts';

import { db } from '../config.ts';

export interface MathValidation extends Pick<FbModelBaseReviewer, 'deletedAt' | 'id'> {
    companyId: string;
    reportId: string;
    entityId: string;
    
    /**
     * Related to "reportExtractedValues" collection
     */
    extractedValueId: string;

    /**
         * IDs of the component values that should sum up to the total
         */
    components: string[];
    /**
         * Expected sum of all components
         */
    expectedValue: number;
    /**
         * Actual sum calculated from components
         */
    actualValue: number;

    valid: boolean
    type: 'sum'

    source: 'user' | 'system'
    /**
     * List of id's for which a sign should be changed. Compatible only with 'sum' operation.
     */
    changeSignForComponents?: string[]

    /**
     * Default 'vertical'
     */
    direction?: 'horizontal' | 'vertical'
}

export const MATH_VALIDATION_COLLECTION = 'mathValidation'

export const mathValidationRef = collection(db, MATH_VALIDATION_COLLECTION)

export const {
    useFBItemsQuery: useMathValidationQuery,
    useFBItemUpdateMutation: useMathValidationUpdateMutation,
    fetchFBItemsOnce: fetchMathValidationOnce,
} = getRequestQueries<MathValidation, {}>({
    url: MATH_VALIDATION_COLLECTION,
})
