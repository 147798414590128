import { FormItemProps } from 'antd';
import {
    collection, Timestamp,
} from 'firebase/firestore';

import { S213Format } from '@/pages/RegRepPage/RegRepNewModal';
import { FbModelBaseCommon } from '@/types/modelBase.ts';

import { db } from '../config.ts';

export type RegRepFileInputFormat = 'generic' | 'bob50' | 'yardi' | 'GP4' | 'Multifonds'

/**
 * Use it for form/table etc
 */
export const REG_REP_FORM_ITEMS: Record<string, FormItemProps> = {
    status: {
        label: 'Status',
        name: 'status',
        required: true,
    },
    s213format: {
        label: 'Choose S213 file format',
        name: 'fileS213format',
        rules: [{ message: 'The format specifies the list of files to be uploaded' }],
    },
    fileS213: {
        label: 'File S2.13 (Generic)',
        name: 'fileS213',
        // rules: [{
        //     message: 'Please upload S213 file in generic format',
        // }],
    },
    fileS214: {
        label: 'File S2.14 (Generic)',
        name: 'fileS214',
        // rules: [{
        //     message: 'Please upload S214 file in generic format',
        // }],
    },
    fileS216: {
        label: 'File S2.16 (Generic)',
        name: 'fileS216',
        // rules: [{
        //     message: 'Please upload S216 file in generic format',
        // }],
    },
    fileTPTTBS: {
        label: 'File TPTTBS',
        name: 'fileTPTTBS',
    },
    fileTPTIBS: {
        label: 'File TPTIBS',
        name: 'fileTPTIBS',
    },
    fileS213bob50: {
        label: 'Trial balance (BOB50)',
        name: 'fileS213bob50',
        // rules: [{ message: 'Please upload S213 in BOB50 format' }],
    },
    fileS213Bob50Mapping: {
        label: 'Mapping file',
        name: 'fileS213Bob50Mapping',
        // rules: [{ message: 'Please upload S213 mapping for BOB50 format' }],
    },
    clientCompanyId: {
        name: 'clientCompanyId',
        label: 'Client',
        rules: [{
            message: 'Please  select the company associated with this report',
            required: true,
        }],
    },
    entityId: {
        name: 'entityId',
        label: 'Entity',
        rules: [{
            required: true,
            message: 'Please select the entity name associated with this report',
        }],
    },
    fileTPTOBS: {
        label: 'File TPTOBS',
        name: 'fileTPTOBS',
        // rules: [{
        //     message: 'Please upload TPTOBS file',
        // }],
    },
    fileU11: {
        label: 'File U1.1',
        name: 'fileU11',
        // rules: [{
        //     message: 'Please upload TPTOBS file',
        // }],
    },
}

export type RegRepProcessStatus =
    /**
     * initial status
     */
    'initial' |
    /**
     * Status for s213 for preparation generic  file
     */
    'preparingS213' |
    /**
     * In proress
     */
    'processing' |
    /**
     * Processing is failed
     */
    'error' |
    /**
     * Processing is successfully finished
     */
    'done'

export type RegRepProcessValidationStatus =
    'processing' | 'warning' | 'failed' | 'noErrors'

export interface RegRepProcessFile {
    name: string,
    file: string
    format?: RegRepFileInputFormat,
}

export enum Period {
    quarterly = 'quarterly',
    monthly = 'monthly',
}

export enum UploadType {
    final = 'final',
    provisional = 'provisional',
}

export type RegRepSectors = 'securitisationVehicles' | 'investmentFunds' | 'financialCompanies'

export const regRepSectorOptions: Array<{ label: string, value: RegRepSectors, disabled?: boolean }> = [
    {
        value: 'securitisationVehicles',
        label: 'Securitisation Vehicles',
    },
    {
        value: 'investmentFunds',
        label: 'Investment Funds',
    },
    {
        value: 'financialCompanies',
        label: 'Financial Companies',
    },
]

export interface RegRepProcess extends FbModelBaseCommon{
    sector?: RegRepSectors
    companyId: string
    /**
     * @deprecated use createdBy
     */
    userId: string
    clientCompanyId: string
    entityId: string
    uploadType: UploadType
    period: Period

    s213format: S213Format
    
    /**
     * Reporting month selected by the user
     */
    reportMonth?: Timestamp

    status: RegRepProcessStatus

    validationStatus: RegRepProcessValidationStatus

    /**
     * Input files s213 pipeline
     */
    fileS213?: string
    fileS213bob50?: string
    fileS213Bob50Mapping?: string
    fileTPTOBS: string
    fileU11: string

    /**
     * S214
     */
    fileS214CheckList?: File
    fileS214Converted?: File

    fileS214TBTTBSValidation?: File

    /**
     * S216 and TPTIBS for Financial Companies
     */
    fileS216?: string
    fileTPTIBS?: string
    fileS216Converted?: File
    fileS216TPTIBSValidation?: File

    /**
     * Output files s213 pipeline
     */
    fileS213Errors: RegRepProcessFile,
    fileS0213Converted: RegRepProcessFile,
    fileU11Errors: RegRepProcessFile,
    fileU11Converted: RegRepProcessFile,
    fileTPTErrors: RegRepProcessFile,
    fileTPTConverted: RegRepProcessFile,
    fileS213TPTOBSErrors: RegRepProcessFile,
    fileU11TPTOBSErrors: RegRepProcessFile
    fileS214?: string
    fileTPTTBS?: string

    /**
     * @deprecated use createdAt
     */
    uploadedAt: Timestamp
    /**
     * Timestamp to retrigger listeners
     */
    retriggeredAt: Timestamp
}

type OutputFileKeys = Extract<keyof RegRepProcess,
'fileS213Errors' |
'fileS0213Converted' |
'fileU11Errors' |
'fileU11Converted' |
'fileTPTErrors' |
'fileTPTConverted' |
'fileS213TPTOBSErrors' |
'fileU11TPTOBSErrors' |
'fileS214CheckList' |
'fileS214Converted' |
'fileS214TBTTBSValidation' |
'fileS216Converted' |
'fileS216TPTIBSValidation'
>;

export const regRepResultFilesKeys: OutputFileKeys[] = [
    'fileS213Errors',
    'fileS0213Converted',
    'fileU11Errors',
    'fileU11Converted',
    'fileTPTErrors',
    'fileTPTConverted',
    'fileS213TPTOBSErrors',
    'fileU11TPTOBSErrors',
    'fileS214CheckList',
    'fileS214Converted',
    'fileS214TBTTBSValidation',
    'fileS216Converted',
    'fileS216TPTIBSValidation',
];

export const REG_REP_PROCESS_COLLECTION = 'regRepProcess'

export const regRepProcessRef = collection(db, REG_REP_PROCESS_COLLECTION)
