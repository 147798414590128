import React, { useState } from 'react'

import {
    Flex, Spin, 
} from 'antd';

import { COLORS } from '@/constants/colors.ts';

import { NCardProps } from './NCard.types'

export const NCard = (props: NCardProps) => {
    const { 
        children, 
        className,
        hoverable,
        loading,
        active,
        styles: customStyles,
        onClick,
    } = props;

    const [isHovered, setIsHovered] = useState(false);

    return (
        <Flex
            className={className}
            style={{
                cursor: hoverable ? 'pointer' : 'default',
                padding: 12,
                position: 'relative',
                border: `solid 1px ${active ? COLORS.focusedValue : isHovered && hoverable ? COLORS.focusedValue : '#d9d9d9'}`,
                borderRadius: 8,
                transition: 'all 0.2s',
                boxShadow: active ? `inset 5px 0 0 0 ${COLORS.focusedValue}` : undefined,
                ...customStyles?.body,
            }}
            onClick={onClick}
            onMouseEnter={() => hoverable && setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            {loading ? (
                <Spin size='small'/>
            ) : (
                children
            )}
        </Flex>
    )
}
