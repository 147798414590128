import React, { useEffect } from 'react'

import { CommentOutlined } from '@ant-design/icons';
import { useVeltClient, VeltCommentsSidebar } from '@veltdev/react';
import { Button, Flex } from 'antd';
import { useQueryParam } from 'use-query-params';

import { useReportExtractedValuesByIdQuery } from '@/firestore/api/reportExtractedValues.ts';
import { useFocusedValueId } from '@/hooks/useFocusedValueId.ts';
import { IDENTIFY_VALUE_QUERY_CONFIG } from '@/widgets/MagicButtons/MagicButtons.constants.ts';

import { MessagesProps } from './Messages.types'

export const Messages = (props: MessagesProps) => {
    const { selectedExtractedValSnapId, selectedAnnotationId , setSelectedExtractedValSnapId, setSelectedAnnotationId } = useFocusedValueId()
    const selectedId = selectedExtractedValSnapId || selectedAnnotationId

    const reportExtractedValuesByIdQuery = useReportExtractedValuesByIdQuery(selectedExtractedValSnapId, { enabled: !!selectedExtractedValSnapId })

    const [, setIdentifyValOpen] = useQueryParam(IDENTIFY_VALUE_QUERY_CONFIG.name, IDENTIFY_VALUE_QUERY_CONFIG.type)

    const { client: veltClient } = useVeltClient()
    
    useEffect(() => {
        if(!veltClient) return
        
        const locationConf = {
            // TODO: Change 'Annotation' to content of the annotation or something else dynamic
            locationName: reportExtractedValuesByIdQuery.data?.originalValue?.toString() || 'Annotation' || undefined,
            id: selectedId,
        }
        
        veltClient.setLocation(locationConf);

        // Activate filter in Sidebar by account
        const filters = {
            location: [
                selectedId ? { id: selectedId } : undefined,
            ].filter(Boolean),
        }
                 
        const commentElement = veltClient.getCommentElement();
        commentElement.setCommentSidebarFilters(filters);

        commentElement.disableCollapsedComments()
    }, [selectedId, veltClient, reportExtractedValuesByIdQuery.dataUpdatedAt]);
    
    return (
        <Flex
            vertical
            style={{ 
                overflowX: 'auto',
                width: '100%',
                alignItems: (!selectedExtractedValSnapId) ? 'center' : 'flex-start',
                justifyContent: (!selectedExtractedValSnapId) ? 'center' : 'flex-start', 
                flexGrow: 1,
            }}
        >
            {/*{!selectedId ? (*/}
            {/*    <Result*/}
            {/*        icon={<GatewayOutlined/>}*/}
            {/*        title='Select a value to begin commenting'*/}
            {/*        extra={(*/}
            {/*            <Button*/}
            {/*                onClick={() => {*/}
            {/*                    setIdentifyValOpen(true)*/}
            {/*                }}*/}
            {/*            >Create a New</Button>*/}
            {/*        )}*/}
            {/*    />*/}
            {/*) : (*/}
            <>
                {selectedId ? (
                    <Button
                        size='small'
                        color='primary'
                        block
                        onClick={() => {
                            setSelectedExtractedValSnapId(undefined)
                            setSelectedAnnotationId(undefined)
                        }}
                        type='link'
                        icon={<CommentOutlined/>}
                    >
                            View All Comments in the Report
                    </Button>
                ) : null}
                <Flex
                    style={{
                        flexGrow: 1,
                    }}
                >
                    <VeltCommentsSidebar
                    // onCommentClick={(...args) => {
                    //     debugger
                    // }}
                        filterConfig={{
                            location: {
                                enable: true,
                                name: 'Element',
                                enableGrouping: false,
                                multiSelection: false,
                            },
                            priority: {
                                enable: true,
                                enableGrouping: false,
                            },
                        }}
                        shadowDom={false}
                        onCommentClick={async (payload, ...args) => {
                            debugger
                            // const annotationId = payload.location.id;
                            //
                            // const { documentViewer, annotationManager } = PDFInstance.Core;
                            // const annotList = await annotationManager.getAnnotationsList();
                            // const annotations = await annotationManager.getAnnotationById(annotationId);
                            // annotationManager.selectAnnotation(annotList[0]);

                            // 
                            // Ищем аннотацию с указанным ID
                            // const annotation = annotations.find((anno: any) => anno.id === annotationId);

                            // const pageNumber = annotation.pageNumber;
                            // const rect = annotation.rect; // [left, top, right, bottom]

                            // Метод для перевода фокуса на аннотацию
                            // PDFInstance.UI.sc(pageNumber, rect);

                        // Выделить аннотацию, если это возможно
                        // if (PDFInstance.UI.selectAnnotation) {
                        //     PDFInstance.UI.selectAnnotation(annotation.id);
                        // }
                        }}
                        pageMode
                        embedMode
                    />
                </Flex>
            </>
            {/*)}*/}
        </Flex>
    )
}
