import React, {
    useEffect, useState, useRef, createContext, 
} from 'react'

import {
    Auth0ProviderOptions, useAuth0, 
} from '@auth0/auth0-react'
import {
    DocumentSnapshot, setDoc, 
} from '@firebase/firestore';
import * as Sentry from '@sentry/react'
import { VeltProvider } from '@veltdev/react';
import {
    Velt , User as VeltUser, 
} from '@veltdev/types';
import { UserContact } from '@veltdev/types/app/models/data/user-contact.data.model';
import { useAsyncEffect } from 'ahooks';
import {
    Button, Flex, Result, Spin, 
} from 'antd';
import { message } from 'antd/lib';
import { signInWithCustomToken } from 'firebase/auth';
import {
    doc, getDoc, getDocs, query, where, 
} from 'firebase/firestore';
import mixpanel from 'mixpanel-browser'
import { NavigateFunction } from 'react-router-dom';

import DocumentData = firebase.firestore.DocumentData;
import { AuthContextProps } from './AuthContext.types.ts'
import {
    Company, companyRef, 
} from '../../../firestore/api/company.ts';
import {
    User, userRef, 
} from '../../../firestore/api/user.ts';

import { firebaseAuth } from '@/firestore/config.ts';
import { CONFIG } from '@/config.ts';
import { identifyMixPanel } from '@/components/containers/AuthContext/AuthContext.utils.ts';
import { usePerformanceTrace } from '@/hooks/usePerformanceTrace'
import { useLocalStorage } from '@/hooks/useLocalStorage.ts';
import { ACTIVE_COMPANY_LS_KEY } from '@/widgets/Header';

import { LinkOutlined } from '@ant-design/icons';

if(CONFIG.MIXPANEL_KEY && CONFIG.IS_PROD) {
    mixpanel.init(CONFIG.MIXPANEL_KEY, {
        debug: true,
        persistence: 'localStorage',
        record_sessions_percent: 100,
        record_mask_text_selector: '',
        record_mask_text_class: '',
        record_inline_images: true,
    })
}

export const getAuthConfig = (navigate: NavigateFunction): Auth0ProviderOptions => ({
    domain: import.meta.env.VITE_AUTH0_DOMAIN,
    clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
    // Avoid redirect to auth0 in dev env
    // useRefreshTokens: process.env.NODE_ENV !== 'development',
    authorizationParams: {
        redirect_uri: window.location.origin,
        audience: import.meta.env.VITE_AUTH0_AUDIENCE,
    },
    onRedirectCallback: (appState) => {
        navigate(appState?.returnTo || window.location.pathname)
    },
})

export type AuthData = {
    user: User,
    company: DocumentSnapshot<Company, Company>
    usersInCompany: User[]
    setActiveCompany: (id: string) => void
    availableCompanies: DocumentSnapshot<Company, Company>[]
}

export const AuthDataContext = createContext<AuthData>({
    user: null,
    company: null,
    usersInCompany: [],
    setActiveCompany: null,
    availableCompanies: [],
})

/**
 * Logout if Any auth failed FB or Auth0
 */
export const AuthContext = (props: AuthContextProps) => {
    const { children } = props
    const {
        markStage, stopTrace, 
    } = usePerformanceTrace('pagePreparation')

    const [authContextState, setAuthContextState] = useState<AuthData>()
    const [isLoading, setIsLoading] = useState(true)
    const [veltClient, setVeltClient] = useState<Velt | null>(null)
    
    const {
        isAuthenticated, isLoading: isAuth0Loading, getAccessTokenSilently, user, logout, loginWithRedirect, loginWithPopup, getIdTokenClaims, getAccessTokenWithPopup, 
    } = useAuth0()

    const appInited = useRef(false)
    
    const [activeCompany, setActiveCompany] = useLocalStorage<string[]>(ACTIVE_COMPANY_LS_KEY);
        
    useEffect(() => {
        markStage('AuthContext: start')
    }, []);

    useEffect(() => {
        if(!authContextState) return
        identifyMixPanel(authContextState)
    }, [authContextState]);

    const [showSocialProfileLInking, setShowSocialProfileLInking] = useState(false)

    useEffect(() => {
        if(!user?.sub) return

        const logPassUser = user.sub.startsWith('auth0|')

        if(!logPassUser) {
            setShowSocialProfileLInking(true)
        }
    }, [user?.sub]);

    // Waith for Auth ready and setup Velt client
    useEffect(() => {
        if (authContextState && veltClient) {
            const contacts: UserContact[] = authContextState!.usersInCompany.map(el => ({
                name: el.displayName,
                email: el.email,
                photoUrl: el.avatarUrl,
                userId: el.uid,
                groupId: authContextState!.company.id,
                visibility: 'group',
            }))

            const user: VeltUser = {
                userId: authContextState!.user.uid,
                name: authContextState!.user.displayName,
                photoUrl: authContextState!.user.avatarUrl,
                email: authContextState!.user.email,
                contacts,
                groupId: authContextState!.company.id,
                organizationId: authContextState.company.id,
            }

            veltClient.identify(user).catch(console.error)

            const userAuth = authContextState.user

            const userSentry = {
                id: userAuth?.uid,
                email: userAuth?.email,
                name: userAuth?.displayName,
            }

            // Global user
            Sentry.setUser(userSentry)

            Sentry.withScope((scope) => {
                scope.setTag('companyName', authContextState.company.data()?.name)
                scope.setTag('companyId', authContextState.company.id )

                // One event infor. Probably can be deleted.
                scope.setUser(userSentry)
            })
        }
    }, [authContextState, veltClient])

    // Non-blocking update of user data
    useAsyncEffect(async () => {
        if(!user || isLoading) return

        const docRef = doc(userRef, user.sub);

        try {
            await setDoc<DocumentData<User>, DocumentData<User>>(docRef, {
                uid: user.sub,
                email: user.email,
                displayName: user.name,
                avatarUrl: user.picture,
                lastAuthorizedAt: new Date(),
            }, { merge: true })
        } catch (error) {
            debugger
            console.error('Error during user data update', error)
        }
    }, [user, isLoading]);
    
    useAsyncEffect(async () => {
        if (isAuth0Loading || appInited.current || !user) {
            return
        }
        markStage('AuthContext: Auth0 ready')
        
        appInited.current = true

        try {
            const idToken = await getAccessTokenSilently()

            markStage('AuthContext: Auth0 token ready')

            // Validating Auth0 token and generates custom token for FB
            const response = await fetch('/api/config/firebase-token', { headers: { 'Authorization': `Bearer ${idToken}` } });

            markStage('AuthContext: Firebase token ready')

            const { firebaseToken } = await response.json()

            await signInWithCustomToken(firebaseAuth, firebaseToken);

            markStage('AuthContext: Firebase signed in')

            const docRef = doc(userRef, user.sub);

            // Need to get doc like this because company can be updated manually later
            const docSnapshot = await getDoc(docRef)

            markStage('AuthContext: User doc fetched')
            
            const companyIds = docSnapshot.data()?.companyIds;

            if(companyIds?.length) {
                const activeCompId = (companyIds || []).includes(activeCompany) ? activeCompany : companyIds[0]
                setActiveCompany(activeCompId)
                
                const companiesSnapshotPromises = companyIds?.map(id => {
                    const companyDocRef = doc(companyRef, id)
                    return getDoc(companyDocRef)
                }) || []

                const companiesSnapshots = await Promise.all(companiesSnapshotPromises)
                const activeCompanySnapshot = companiesSnapshots.find(el => el.id === activeCompId)

                const showNexlyUsers = activeCompanySnapshot?.data()?.features?.includes('showNexlyUsers') || false
                
                const usersInCompanyStapshot = await getDocs(query(userRef, where('companyIds', 'array-contains-any', [activeCompId])))
                const usersInCompany = usersInCompanyStapshot.docs
                    .map(el => el.data())
                    // Hide nexly users from company
                    .filter((user) => (!user.email?.endsWith('@nexly.tech') && !user.email?.endsWith('@nexly.app')) || showNexlyUsers)

                const setActCopmany = (id: string) => {
                    setActiveCompany(id)
                    window.location.reload()
                }
                
                setAuthContextState({
                    user: docSnapshot.data(),
                    company: activeCompanySnapshot,
                    usersInCompany: usersInCompany,
                    setActiveCompany: setActCopmany,
                    availableCompanies: companiesSnapshots,
                })
            }

            markStage('AuthContext: Authenticated')
            stopTrace()
            setIsLoading(false)
        } catch (error) {
            debugger
            message.error('Error during authentication. Try again later')
            console.error(error)
            logout({ logoutParams: { returnTo: window.location.origin } })
        }
    }, [user, isLoading, isAuth0Loading])

    // Redirect to login if not authenticated
    useEffect(() => {
        if (!isAuth0Loading && !isAuthenticated) {
            loginWithRedirect({ appState: { returnTo: window.location.pathname + window.location.search } })
        }
    }, [isAuth0Loading, isAuthenticated])

    if (isLoading) {
        return (
            <Flex
                style={{
                    height: '100vh',
                    width: '100vw', 
                }}
                align='center'
                justify='center'
            >
                <Spin size='large'/>
            </Flex>
        )
    }
    
    const handleLogout = (delay?: number = 0) => {
        setTimeout(() => {
            logout({ logoutParams: { returnTo: window.location.origin } })
        }, delay)
    }
    
    const linkAccount = async () => {
        const idToken = await getIdTokenClaims()

        const { sub } = user
        
        const user2AccessToken = await getAccessTokenWithPopup({
            authorizationParams: {
                max_age: 0,
                // scope: 'update:users update:current_user_identities',
                scope: 'update:current_user_identities',
                response_type: 'id_token',
            },
        })

        const user2 = await getIdTokenClaims()

        if(user?.email !== user2.email) {
            message.error(
                'Account linking is only allowed for the same email address.',
            );
            
            handleLogout(3000)
            return
        }

        if(user?.sub === user2.sub) {
            message.error(
                'You can\'t link the same accounts.',
            );

            handleLogout(3000)
            return
        }
        
        if (!user2?.email_verified) {
            message.error(
                `Account linking is only allowed to a verified account. Please verify your email ${user2?.email}.`,
            );
            
            handleLogout(3000)
            return
        }
        
        const logPassUser = user2.sub.startsWith('auth0|')

        if(!logPassUser) {
            message.error(
                'Make sure you are linking to a profile provided by the Nexly team.',
            );
            
            handleLogout(3000)
            return
        }

        const provider = sub.split('|')[0]

        const providerIdByName: Record<string, string> = { windowslive: 'con_pt5ffbWkIMtEwnQD' }

        if(!providerIdByName[provider]) {
            message.error(
                'Unsupported connection.',
            );

            handleLogout(3000)

            return
        }
        
        // Make Log/Pass account as main and social as secondary
        try {
            const res = await fetch(`https://${CONFIG.auth0domain}/api/v2/users/${user2.sub}/identities`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user2AccessToken}`,
                },
                body: JSON.stringify({ link_with: idToken.__raw }),
            });

            if (!res.ok) {
                // Handle HTTP errors (status codes outside 200-299)
                const errorData = await res.json().catch(() => null);
                const error = `HTTP error! status: ${res.status}, message: ${errorData?.message || 'Unknown error'}`
                console.error(error)
                message.error('Unable to link accounts')
                handleLogout(3000)
                return
            }
        } catch (error) {
            // Handle network errors, parsing errors, and thrown HTTP errors
            console.error('Failed to link accounts:', error);
            // You can handle different types of errors differently
            if (error instanceof TypeError) {
                // Network errors, like no internet connection
                message.error('Network error. Please check your connection.');
            } else {
                // Other errors (including HTTP errors we threw above)
                message.error(error.message || 'Failed to link accounts');
            }
            
            handleLogout(3000)
            return
        }

        window.location.reload()
    };
    
    if(showSocialProfileLInking) {
        return (
            <Flex
                style={{
                    height: '100vh',
                    width: '100vw', 
                }}
                align='center'
                justify='center'
            >
                <Flex vertical align='center' justify='center' gap={16}>
                    <Result
                        icon={<LinkOutlined/>}
                        title='Link your social profile to the account provided by the Nexly team using your login and password.'
                        subTitle='Need help? Reach out to us at support@nexly.tech'
                    />
                    <Button
                        style={{ minWidth: 300 }}
                        type='primary'
                        onClick={linkAccount}
                    >Link Accounts</Button>
                    <Button
                        style={{ minWidth: 300 }}
                        onClick={() => {
                            logout({ logoutParams: { returnTo: window.location.origin } })
                        }}
                    >Logout</Button>
                </Flex>
            </Flex>
        )
    }

    if(!authContextState?.company) {
        return (
            <Flex
                style={{
                    height: '100vh',
                    width: '100vw', 
                }}
                align='center'
                justify='center'
            >
                <Flex vertical align='center' justify='center'>
                    <Result
                        status='403'
                        title='No access to the company'
                        subTitle='Contact us for access: support@nexly.tech'
                    />
                    <Button
                        onClick={handleLogout}
                    >Logout</Button>
                </Flex>
            </Flex>
        )
    }

    return (
        // Type will be not empty after checking in the previous if statement
        <VeltProvider
            apiKey={import.meta.env.VITE_VELT_KEY}
            onClientLoad={(client) => {
                setVeltClient(client)
            }}
        >
            <AuthDataContext.Provider value={authContextState as unknown as AuthData}>
                {children}
            </AuthDataContext.Provider>
        </VeltProvider>
    )
}
