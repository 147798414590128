import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';

export const useRenderMeter = () => {
    // Track number of renders in development mode only
    const renderCountRef = useRef(0);
    const startTimeRef = useRef(performance.now());
    const { id: docId } = useParams();
    const alertShownForDocId = useRef<string>();
    
    useEffect(() => {
        // Increment render count on each render
        renderCountRef.current++;
        
        const checkRenderCount = () => {
            const now = performance.now();
            const elapsed = now - startTimeRef.current;
            
            // Check every 5 seconds
            if (elapsed >= 5000) {
                const rendersPerSecond = (renderCountRef.current / elapsed) * 1000;
                
                console.log(`[Renders] Average renders per second: ${rendersPerSecond.toFixed(2)}`);
                
                // Alert if more than 500 renders in 5 seconds
                if (renderCountRef.current > 500 && alertShownForDocId.current !== docId) {
                    console.error(`[Renders] High render count detected: ${renderCountRef.current} renders in 5 seconds for document ${docId}`);
                    
                    Sentry.captureMessage('Excessive renders detected', {
                        level: 'warning',
                        tags: {
                            docId,
                            renderCount: renderCountRef.current,
                            rendersPerSecond: rendersPerSecond.toFixed(2)
                        }
                    });
                    
                    alertShownForDocId.current = docId;
                }
                
                // Reset counters
                renderCountRef.current = 0;
                startTimeRef.current = now;
            }
        };
        
        const timeoutId = setTimeout(checkRenderCount, 5000);
        
        return () => {
            clearTimeout(timeoutId);
        };
    });
    
    return renderCountRef.current;
}; 