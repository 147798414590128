import React, { useContext } from 'react'

import { CloseOutlined } from '@ant-design/icons';
import {
    Button, Flex, Tooltip,
} from 'antd';
import { diff_match_patch } from 'diff-match-patch';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm'

import { AuthDataContext } from '@/components/containers/AuthContext';
import { NCard } from '@/components/NCard';
import {
    useValueValidationQuery, useValueValidationUpdateMutation, VALUE_VALIDATION_COLOR,
} from '@/firestore/api/valueValidation';
import { useViewerDocument } from '@/hooks/useViewerDocument';
import { makeColorTransparent } from '@/utils/makeColorTransparent.ts';
import { getAnnotationBySnapId } from '@/utils/pdfViewer/getAnnotationBySnapId';

import styles from './IcValidationItem.module.scss';
import { IcValidationItemProps } from './IcValidationItem.types';

export const IcValidationItem = (props: IcValidationItemProps) => {
    const { 
        item,
        contentVariations,
        reivewLinkObjectsSnap,
        valueValidation,
        scrollableAnnotationsReady,
        setSelectedExtractedValSnapId,
        page,
        index,
        activeMoneyValues,
        activeMoneyValuseLocalFallback,
    } = props;
    
    const valueValidationQuery = useValueValidationQuery({ filters: ['extractedValueId', '==', item?.extractedValueId as string] }, { enabled: !!item?.extractedValueId })

    const icItem = valueValidationQuery?.data?.[0]
    const icConf = icItem?.manual?.ic || icItem?.auto?.ic
    
    const confColor = VALUE_VALIDATION_COLOR[icConf]

    const {
        pdfInstance, annotationManager, 
    } = useViewerDocument();
    const valueValidationUpdateMutation = useValueValidationUpdateMutation();
    const authData = useContext(AuthDataContext);

    const handleReject = async () => {
        if (!reivewLinkObjectsSnap) return;

        // Find the document containing this annotation
        const docWithAnnotation = reivewLinkObjectsSnap.docs.find(doc =>
            doc.data().linksGroup.some(link =>
                link.extractedValueId === item.extractedValueId,
            ),
        );

        if (!docWithAnnotation) return;

        // Update the linksGroup array, setting rejected=true for the matching item
        // const updatedLinksGroup = docWithAnnotation.data().linksGroup.map(link =>
        //     link.extractedValueId === item.extractedValueId
        //         ? {
        //             ...link,
        //             rejected: true,
        //             rejectedAt: new Date(),
        //         }
        //         : link,
        // );

        // Update the document in Firestore
        // await updateDoc(doc(reviewLinkedObjectsRef, docWithAnnotation.id), { linksGroup: updatedLinksGroup });

        const validationItem = valueValidation?.find(doc => doc.extractedValueId === item.extractedValueId)

        if (validationItem) {
            await valueValidationUpdateMutation.mutateAsync({
                id: validationItem.id,
                data: {
                    manual: {
                        ...(validationItem.manual || {}),
                        ic: 'notApplicable',
                    },
                },
            })
        }
    };

    const itemActive = !!(activeMoneyValues || activeMoneyValuseLocalFallback)?.includes(item.extractedValueId)

    // Actively rejected
    const rejected = item.rejected || icConf === 'notApplicable'

    const removeDoubleSpaces = (text: string): string => {
        return text.replace(/\s+/g, ' ').trim();
    };

    const formatContentWithDiff = (content: string, variations: string[] | null) => {
        if (!variations || variations.length < 1) {
            return content;
        }

        const dmp = new diff_match_patch();
        dmp.Match_Threshold = 0.5;
        dmp.Match_Distance = 1000;

        let resultText = removeDoubleSpaces(content);
        // Фильтруем null/undefined значения и приводим к строке
        const otherVariations = variations
            .filter((v): v is string => v != null && v !== content)
            .map(v => removeDoubleSpaces(String(v)));

        // Получаем все различия между текущим текстом и вариациями
        const allDiffs = otherVariations.flatMap(variation => {
            const diffs = dmp.diff_main(String(content), variation);
            dmp.diff_cleanupSemantic(diffs);
            return diffs;
        }).filter((diff, index, self) =>
            // Удаляем дубликаты, сравнивая тип и текст каждого diff
            index === self.findIndex(d =>
                d[0] === diff[0] && d[1] === diff[1],
            ),
        );

        // Находим уникальные части в основном тексте
        const differentParts = new Set();
        allDiffs.forEach(([type, text]) => {
            if (type === -1 && text.trim().length) {
                differentParts.add(text.trim());
            }
        });

        // Список специальных символов, которые могут вызвать проблемы в начале строки
        const specialChars = /^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>/?`~]/;

        differentParts.forEach(part => {
            const escapedPart = String(part).replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
            const regex = new RegExp(`(${escapedPart})`, 'g');
            resultText = resultText.replace(regex, (match) => {
                // If special char in the end or start of the string
                if (match.charAt(0).match(specialChars) || match.charAt(match.length - 1).match(specialChars) ) {
                    return `\u200B**\u200B${match}\u200B**\u200B`;
                }

                return `**${match}**`;
            })
                // In some reason i have asterisks without content, so it's a hack to fix it
                .replace(/\*\*\*\*/g, '');
        });

        return resultText;
    };

    const getFormattedContent = () => {
        if (!scrollableAnnotationsReady) return '';
        
        const content = item.content || `Value ${index + 1}`;
        
        if (rejected) {
            return `~~${content}~~`;
        }

        return formatContentWithDiff(content, contentVariations);
    };

    return (
        <NCard
            className={`${styles.card} ${styles.hoverableCard}`}
            hoverable
            loading={!scrollableAnnotationsReady}
            styles={{
                body: {
                    backgroundColor: confColor ? makeColorTransparent(confColor, 0.05) : undefined,
                    display: 'flex',
                    gap: '6px',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    position: 'relative',
                    opacity: rejected ? 0.5 : 1,
                },
            }}
            active={itemActive}
            onClick={async () => {
                if (!pdfInstance || !annotationManager) return

                const extractedValAnnot = getAnnotationBySnapId(annotationManager, item.extractedValueId)

                annotationManager?.jumpToAnnotation(extractedValAnnot)
                setSelectedExtractedValSnapId(item.extractedValueId)
            }}
        >
            <Flex className={styles.content}>
                <Markdown
                    // Delete <p> which will be acced by the library
                    components={{
                        p: ({
                            node, children, 
                        }) => <>{children}</>,
                    }}
                    // Strike through the text if rejected
                    remarkPlugins={[[remarkGfm, { singleTilde: false }]]}
                >
                    {getFormattedContent()}
                </Markdown>
            </Flex>
             
            {!rejected && (
                <Flex
                    className={styles.closeButton}
                    style={{
                        zIndex: 10,
                        position: 'absolute',
                        right: 4,
                        background: 'white',
                        borderRadius: 4,
                        border: 'solid 2px white',
                        alignItems: 'center',
                    }}
                >
                    <Tooltip title='Remove from the Group'>
                        <Button
                            size='small'
                            loading={valueValidationUpdateMutation.isPending}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleReject();
                            }}
                            type='text'
                            icon={<CloseOutlined/>}
                        />
                    </Tooltip>
                </Flex>
            )}
        </NCard>
    )
}
