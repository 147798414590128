import * as Sentry from '@sentry/react'
import { message } from 'antd/lib';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'

interface CustomAxiosRequestConfig<T = undefined> extends AxiosRequestConfig<T> {
    meta?: {
        /**
         * Alternative message instead of default backend message
         */
        errorToast?: string
    }
}

export const isAuthError = (error: AxiosError): boolean => {
    return (
        false
    )
}

// TODO: Add custom error message
axios.defaults.xsrfHeaderName = 'X-CSRFToken'
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.withCredentials = true
axios.defaults.baseURL = '/api'
axios.defaults.timeout = 30000 // 30 seconds timeout
axios.interceptors.request.use((config: CustomAxiosRequestConfig) => {
    config.meta = config.meta || {}
    return config
})
axios.interceptors.response.use(
    response => {
        return response
    },
    // TODO: Refactore this error handling. Looks really messy now.

    error => {
        const toastMessage = error.config?.meta?.errorToast
        const errorMessage = error.message?.length ? error.message : 'Request failed'

        const isAuth0Error = errorMessage.includes('Missing Refresh Token')
        const isNetworkError = errorMessage === 'Network Error' && error?.isAxiosError
        
        Sentry.withScope((scope) => {
            scope.setTag('httpServiceError', true)
            scope.setTag('Auth0RefreshTokenProblem', isAuth0Error)
            scope.setContext('errorInfo', {
                toastMessage,
                isAxiosError: JSON.stringify(!!error?.isAxiosError),
                responseConfig: JSON.stringify(error?.response?.config),
                responseData: JSON.stringify(error?.response?.data),
            })
            if (isNetworkError) {
                scope.setTag('isNetworkError', isNetworkError)
            }
        })

        if (isAuth0Error) {
            window.location.reload()
        } else {
            if (toastMessage) {
                message.error(toastMessage)
            }
        }

        throw error
    })

/**
 * Alternative extended interface for axios methods
 */
interface CustomAxiosStatic extends AxiosInstance {
    get: <T = any, R = AxiosResponse<T>, D = any>(url: string, config?: CustomAxiosRequestConfig<D>) => Promise<R>
    delete: <T = any, R = AxiosResponse<T>, D = any>(url: string, config?: CustomAxiosRequestConfig<D>) => Promise<R>
    post: <T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: CustomAxiosRequestConfig<D>) => Promise<R>
    patch: <T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: CustomAxiosRequestConfig<D>) => Promise<R>
}

/**
 * Wrappers with extra parameters
 */
const http: Partial<CustomAxiosStatic> = {
    ...axios,
    get: <T = any, R = AxiosResponse<T>, D = any>(url: string, config?: CustomAxiosRequestConfig<D>): Promise<R> => {
        return axios.get<T, R>(url, config)
    },
    post: <T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: CustomAxiosRequestConfig<D>): Promise<R> => {
        return axios.post<T, R>(url, data, config)
    },
    patch: <T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: CustomAxiosRequestConfig<D>): Promise<R> => {
        return axios.patch<T, R>(url, data, config)
    },
    delete: <T = any, R = AxiosResponse<T>, D = any>(url: string, config?: CustomAxiosRequestConfig<D>): Promise<R> => {
        return axios.delete<T, R>(url, config)
    },
}
export default http
