import { collection } from 'firebase/firestore';

import { getRequestQueries } from '@/api/utils/getRequestQueries';

import { db } from '../config.ts';

export type CompanyFeatures =
    'showRegRep' | // RegRep Tab available
    'showReviewer' | // Reviewer Tab available
    'funds' | // Turn on funds processing
    'tableDebug' // Debug tables extraction
    | 'hideCommertialCompany' // Hide commertial company
    | 'showUnknownEntity' // Allos unknown entity type report
    | 'showNotesLibrary' // Show notes library
    | 'tocDebug' // Show table of content debug
    | 'trialMode' // Adding trial account limitations
    | 'veltIsOn' // Enabel Velt comments
    | 'showNexlyUsers' // Show nexly users in company users

export interface Company {
    name: string
    features?: CompanyFeatures[]
    /**
     * Emails allowed to logiin to the company
     */
    allowedEmailPatterns: string[]
    /**
     * Identifier credentials set for the FB
     */
    fbInstanceId: string
}

export const companyRef = collection(db, 'company')

export const { useFBItemsQuery: useCompanyQuery } = getRequestQueries<Company, {}>({ url: 'company' })
