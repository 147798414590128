import React, {
    useEffect, useState, 
} from 'react'

import * as Sentry from '@sentry/react';
import {
    useVeltClient, VeltComments, 
} from '@veltdev/react';
import {
    Alert, Button, Flex, Result, Spin, 
} from 'antd';
import {
    doc, updateDoc, 
} from 'firebase/firestore';
import { useDocument } from 'react-firebase-hooks/firestore';
import { useHotkeys } from 'react-hotkeys-hook';
import {
    useMatch, useNavigate, useParams, 
} from 'react-router-dom';
import { useQueryParam } from 'use-query-params';

import { QUERY_PARAMS_CONFIG } from '@/config/queryParams.ts';
import { useInputFilesQuery } from '@/firestore/api/inputFiles.ts';
import { LocalErrorBoundary } from '@/widgets/CriticalErrorPlaceholder';
import { MoneyValuesNavigator } from '@/widgets/MoneyValuesNavigator';
import { PdfViewer } from '@/widgets/PdfViewer';
import {
    SUM_SELECT_COMPARE_TO_VALUE_QUERY_CONFIG,
    SUM_SELECT_MULTY_COMPONENT_QUERY_CONFIG,
} from '@/widgets/SumSelect/SumSelect.contants.ts';

import { GuidePanel } from './GuidePanel';
import { ROUTES_CONFIG } from '../../constants/routes.ts';
import {
    ReportReview, ReportReviewAlert, reportReviewRef, 
} from '../../firestore/api/reportReview.ts';
import { MagicButtons } from '../../widgets/MagicButtons';
import { NotesLibrary } from '../../widgets/NotesLibrary';
import { SuggestionsBuilderPage } from '../SuggestionsBuilderPage';

/**
 * @todo Rename to 'GuidedReviewPage'
 */
export const ReviewPage = Sentry.withProfiler(() => {
    const { id: docId } = useParams()
    const [tab] = useQueryParam(QUERY_PARAMS_CONFIG.TAB.key, QUERY_PARAMS_CONFIG.TAB.type);

    const { client: veltClient } = useVeltClient()
    const [, setSumSelectTotalMode] = useQueryParam<boolean>(SUM_SELECT_COMPARE_TO_VALUE_QUERY_CONFIG.name, SUM_SELECT_COMPARE_TO_VALUE_QUERY_CONFIG.type)
    const [, setSumSelectAddMode] = useQueryParam<boolean>(SUM_SELECT_MULTY_COMPONENT_QUERY_CONFIG.name, SUM_SELECT_MULTY_COMPONENT_QUERY_CONFIG.type)

    const [reportStapshot, isLoading ] = useDocument<ReportReview>(doc(reportReviewRef, docId))

    const data = reportStapshot?.data()

    useEffect(() => {
        if(!tab || !veltClient) return
        veltClient.setDocumentId(tab as string)
    }, [veltClient, tab]);

    // FIXME: Doesn't work in iframe
    useHotkeys('ctrl+shift+a', () => setSumSelectAddMode(true));
    useHotkeys('ctrl+shift+e', () => setSumSelectTotalMode(true));

    const isBuilderMode = useMatch(ROUTES_CONFIG.SUGGESTIONS_BUILDER.path)
    
    const navigate = useNavigate()
    
    const inputFilesQuery = useInputFilesQuery({
        filters: [
            'reportId', '==', docId as string,
        ],
    });

    // Need to keep strict match with boolean for migration
    const showWrongTemplateAlert: boolean = Boolean( !data?.closedAlerts?.includes(ReportReviewAlert.invalidTemplate) && data?.templateValid === false)
    const showLimitedFileAlert: boolean = Boolean(!data?.closedAlerts?.includes(ReportReviewAlert.limitedFile) && inputFilesQuery.data?.some(file => file.pagesLimited))

    const activeAlertsCount = [
        showWrongTemplateAlert,
        showLimitedFileAlert,
    ].filter(Boolean).length

    const headerHeight = 63
    const tabsHeight = inputFilesQuery.data?.length ? 40 : 0
    const alertHeight = 40

    const contentMaxHeight = `calc(100vh - ${headerHeight + tabsHeight + alertHeight * activeAlertsCount}px)`

    return (
        <Flex
            style={{
                flexDirection: 'column' ,
                height: '100%', 
            }}
        >
            <VeltComments
                hotkey={true}
                textMode={false}
            />
            
            {showWrongTemplateAlert && (
                <Alert
                    message="It seems the document you've uploaded isn't currently supported by our platform, thus no automated checks were completed. You can still use our toolkit to perform the review."
                    type='warning'
                    showIcon
                    closable={true}
                    onClose={() => {
                        if(isLoading || !reportStapshot) return
                        const closed = [...(data?.closedAlerts || []), ReportReviewAlert.invalidTemplate]
                        updateDoc(reportStapshot.ref, { closedAlerts: closed })
                    }}
                />
            )}
            {showLimitedFileAlert && (
                <Alert
                    message='The report file was limited to 30 pages due to the trial account.'
                    type='info'
                    showIcon
                    closable={true}
                    onClose={() => {
                        if(isLoading || !reportStapshot) return
                        const closed = [...(data?.closedAlerts || []), ReportReviewAlert.limitedFile]
                        updateDoc(reportStapshot.ref, { closedAlerts: closed })
                    }}
                />
            )}    
            <Flex
                style={{
                    flex: 1,
                    maxHeight: contentMaxHeight, 
                }}
            >
                <Flex style={{ flexGrow: 1 }}>
                    <div
                        style={{
                            width: '100%',
                            position: 'relative', 
                        }}
                    >
                        {isBuilderMode ? (
                            <SuggestionsBuilderPage/>
                        ) : (
                            (!reportStapshot?.exists() && !isLoading ) ? (
                                <Result
                                    status='404'
                                    title='404'
                                    subTitle="Unable to find the report, or you don't have the necessary permissions"
                                    extra={(
                                        <Button
                                            type='primary'
                                            onClick={() =>{
                                                navigate(ROUTES_CONFIG.REVIEW.path)
                                            }}
                                        >Back to Reports List</Button>
                                    )}
                                />
                            ) : (
                                <>
                                    {/*<Flex style={{ position: 'absolute', left: 16, top: 44 + 16 }}>*/}
                                    {/*    <VeltPresence flockMode={false} self={false}/>*/}
                                    {/*</Flex>*/}
                                    <MagicButtons/>
                                
                                    <MoneyValuesNavigator/>

                                    <LocalErrorBoundary>
                                        <PdfViewer/>
                                    </LocalErrorBoundary>
                                </>
                            )
                        )}
                    </div>
                </Flex>
                <Flex
                    style={{
                        background: 'white',
                        display: 'flex',
                        width: 440,
                        flexShrink: 0, 
                    }}
                >
                    <LocalErrorBoundary>
                        <GuidePanel
                            reviewItemData={data}
                        />
                    </LocalErrorBoundary>
                </Flex>

                <NotesLibrary/>
            </Flex>
        </Flex>
    )
})
