import {
    Profiler, useEffect, useMemo, useState, 
} from 'react';

import { green } from '@ant-design/colors';
import {
    LeftOutlined, RightOutlined, ThunderboltFilled, 
} from '@ant-design/icons';
import { addDoc } from '@firebase/firestore';
import {
    Alert, Button, Flex, Progress, Spin, Steps, Tooltip, Typography, 
} from 'antd';
import { StepProps } from 'antd/es/steps';
import {
    doc, query, updateDoc, where, 
} from 'firebase/firestore';
import {
    useCollection, useCollectionOnce, useDocument, 
} from 'react-firebase-hooks/firestore';
import { useParams } from 'react-router-dom';
import { useQueryParam } from 'use-query-params';

import { QUERY_PARAMS_CONFIG } from '@/config/queryParams.ts';
import { COLORS } from '@/constants/colors.ts';
import {
    ReportPageClassification, reportPageClassificationRef, 
} from '@/firestore/api/reportPageClassification.ts';
import {
    ReviewStepStatus, reviewStepStatusRef, 
} from '@/firestore/api/reviewStepStatus.ts';
import {
    ReviewStepValidation, reviewStepValidationRef, 
} from '@/firestore/api/reviewStepValidation.ts';
import { StepsFinished } from '@/widgets/StepsFinished';

import { StepContent } from './StepContent';
import { StepsProps } from './Steps.types'
import { ReviewStep } from '../../../../../backend/configs/lib/configs/steps/steps.types';
import { CC_RREPORT_PAGE_LABEL_TITLE } from '../../../../../backend/configs/src/configs/steps/comercialCompanySteps.types.ts';
import {
    ReportReview, reportReviewRef, 
} from '../../../../firestore/api/reportReview.ts';
import {
    getStepsByType, getStepsTreeByType, 
} from '../../../../utils/getStepsByType.ts';
import classes from '../GuidedPanel.module.scss';
import { SuccessReview } from '../SuccessReview';

// Show once for session
let warningShown = false

const stepsConfigToStepsProp = (stepsConfig: ReviewStep[], activeKey: string, handleStepChange: (key: string) => void, reviewStepValidationData: ReviewStepValidation[]): StepProps[] => {
    const steps = stepsConfig.map((step) => {
        const stepAutomationSuccessful = reviewStepValidationData?.find((validation) => validation.step === step.key)?.status === 'success'

        return {
            title: <Typography.Text
                style={{ color: activeKey === step.key ? COLORS.main : undefined }}
                onClick={() => {
                    if(!warningShown ) {
                        // Temporary disabled. Probably unnecessary.
                        // message.warning('The previous step won\'t be marked as finished')
                        warningShown = true
                    }
                    handleStepChange(step.key)
                }}
            >{step.title}</Typography.Text>,
            subTitle: stepAutomationSuccessful
                ? <Tooltip placement='top' title='This step was validated automatically'><ThunderboltFilled style={{ color: step.key === activeKey ? '#6ec3df' : undefined }}/></Tooltip>
                : null,
            key: step.key,
            active: step.key === activeKey,
        }
    })

    return steps
}

export const StepGuide = (props: StepsProps) => {
    const { reviewItemData } = props
    
    const [childSteps, setChildSteps] = useState<StepProps[]>([])
    const [activeStep, setActiveStep] = useState<number>()
    const [activeParentStep, setActiveParentStep] = useState<number>(0)

    const { id: docId } = useParams()
    const [reportSnapshot, isReportSnapshotLoading ] = useDocument<ReportReview>(doc(reportReviewRef, docId))

    const reportData = useMemo(() => reportSnapshot?.data(), [reportSnapshot])
    const reportType = reportData?.reportType

    const [reviewStepValidationSnap, reviewStepValidationLoading] = useCollectionOnce<ReviewStepValidation>(query(reviewStepValidationRef, where('reportOnReviewId', '==', docId)))
    const reviewStepValidationData = reviewStepValidationSnap?.docs.map((doc) => doc.data()) as ReviewStepValidation[]

    const [reviewStepStatusStap, reviewStepStatusLoading] = useCollection<ReviewStepStatus>(query(reviewStepStatusRef, where('reportId', '==', docId)))
    
    const [reportPageClassificationSnap, isReportPageClassificationLoading] = useCollectionOnce<ReportPageClassification>(query(reportPageClassificationRef, where('reportId', '==', docId)))
  
    const stepsConfig = useMemo(() => getStepsByType(reportType), [reportType]) 
    const stepTreeConfig = useMemo(() => getStepsTreeByType(reportType), [reportType])
    const defaultFirstStep = stepsConfig?.find((step) => !!step.parentKey)?.key

    // Save first step if empty
    useEffect(() => {
        if(isReportSnapshotLoading || !defaultFirstStep || reviewStepValidationLoading) return

        if(reportSnapshot && !reportSnapshot?.data()?.currentStep && reportSnapshot?.data()?.reviewStatus !== 'finished' && reportSnapshot?.data()?.reviewStatus !== 'stepsFinished') {
            updateDoc(reportSnapshot.ref, { currentStep: defaultFirstStep })
        }
    }, [defaultFirstStep, reportSnapshot]);
    
    const currentStepKey = reportSnapshot?.data()?.currentStep || defaultFirstStep

    const currentStepConfig = stepsConfig?.find((step) => step.key === currentStepKey)

    useEffect(() => {
        if(!stepsConfig.length || !stepTreeConfig.length || isReportSnapshotLoading || !currentStepConfig) {
            return
        }

        let parentStepIndex = stepTreeConfig.findIndex((step) => step.key === currentStepConfig?.parentKey)
        parentStepIndex = parentStepIndex === -1 ? 0 : parentStepIndex

        let currentStepIndex = stepTreeConfig[parentStepIndex]?.children?.findIndex((step) => step.key === currentStepKey)
        currentStepIndex = currentStepIndex === -1 ? 0 : currentStepIndex
        
        const currenChildStepConfig = stepTreeConfig[parentStepIndex].children[currentStepIndex] as ReviewStep<unknown>
        
        // In case step was invalid
        if(currenChildStepConfig.key !== reportData.currentStep && reportData?.reviewStatus !== 'finished' && reportData?.reviewStatus !== 'stepsFinished') {
            updateDoc(reportSnapshot.ref, { currentStep: currenChildStepConfig.key })
        }
            
        setActiveStep(currentStepIndex)
        setActiveParentStep(parentStepIndex)
        setChildSteps(stepsConfigToStepsProp(stepTreeConfig[parentStepIndex]?.children || [], currentStepKey, handleStepChange, reviewStepValidationData || []))
    }, [reviewItemData, stepTreeConfig, stepsConfig, reviewStepValidationLoading, currentStepConfig]);

    const onlyChildrenItems = stepsConfig.filter((step) => Boolean(step.parentKey))

    const currentStepInd = onlyChildrenItems.findIndex((step) => step.key === currentStepKey)

    const handleNextStepClick = async () => {
        // -1 possible because by for the new report the currentStepKey is not set
        const nextStepInd = (currentStepInd === -1) ? 1 : (currentStepInd + 1)
        const newStepKey = onlyChildrenItems[nextStepInd]?.key

        handleStepChange(newStepKey)
        
        const currentStepStatusRef = reviewStepStatusStap?.docs.find((doc) => doc.data().step === currentStepKey)?.ref
        if(!currentStepStatusRef) {
            await addDoc(reviewStepStatusRef, {
                companyId: reportData?.companyId,
                reportId: reportSnapshot?.id,
                step: currentStepKey,
                status: 'accepted',
            })
        } else {
            await updateDoc(currentStepStatusRef, { status: 'accepted' })
        }
    }
    
    const handleSkipStep = async () => {
        // -1 possible because by for the new report the currentStepKey is not set
        const nextStepInd = (currentStepInd === -1) ? 1 : (currentStepInd + 1)
        const newStepKey = onlyChildrenItems[nextStepInd]?.key
        
        handleStepChange(newStepKey)
        
        const currentStepStatusRef = reviewStepStatusStap?.docs.find((doc) => doc.data().step === currentStepKey)?.ref
        if(!currentStepStatusRef) {
            await addDoc(reviewStepStatusRef, {
                companyId: reportData?.companyId,
                reportId: reportSnapshot?.id,
                step: currentStepKey,
                status: 'notApplicable',
            })
        } else {
            await updateDoc(currentStepStatusRef, { status: 'notApplicable' })
        }
    }
    
    const handleStepChange = (newStepKey: string) => {
        if(reportSnapshot?.ref) {
            if(newStepKey) {
                updateDoc(reportSnapshot?.ref, {
                    currentStep: newStepKey,
                    progress: currentStepInd / onlyChildrenItems.length * 100,
                })
            } else {
                updateDoc(reportSnapshot?.ref, {
                    reviewStatus: 'stepsFinished',
                    progress: 100,
                })
            }
        }
    }

    const onStepContentRender = (...args) => {
        //
    }

    const pageLabels = reportPageClassificationSnap?.docs[0]?.data().labels || []

    const parentStepConfig = stepTreeConfig[activeParentStep ]

    // For now we have only one label for cover page
    const alertClosed = reportData?.closedAlerts?.includes(parentStepConfig?.relatedPageLabels?.[0]) || !parentStepConfig?.relatedPageLabels?.length
    
    // If alteas one of labels didn't find in pageLabels
    const hasPagesForCurrentStep =
        !parentStepConfig?.relatedPageLabels?.some((label) => !pageLabels.find((pageLabel) => pageLabel.pageLabel === label))

    const childrenSteps = stepsConfig.filter(el => !!el.parentKey)
    const unsubmitedSteps = childrenSteps?.filter((step) => !reviewStepStatusStap?.docs.find((doc) => doc.data().step === step.key))

    const progress = (childrenSteps.length - unsubmitedSteps.length) / childrenSteps.length * 100;
    
    return (
        <Flex
            vertical
            style={{
                padding: 12,
                paddingTop: 0 ,
                overflowX: 'auto',
                width: '100%', 
            }}
        >
            <Progress
                percent={Math.ceil(progress)}
                strokeLinecap='butt'
                strokeColor={green[6]}
                style={{ paddingTop: 12 }}
            />
            
            {(reviewItemData?.reviewStatus === 'stepsFinished') ? (
                <StepsFinished reviewSnap={reportSnapshot}/>
            ) : (reviewItemData?.reviewStatus === 'finished' ) ? (
                <SuccessReview/>
            ) : (
                <>
                    {Boolean(childSteps.length) && (
                        <Flex
                            gap={4}
                            style={{
                                color: '#d9d9d9',     
                                position: 'sticky',
                                top: 0,
                                zIndex: 10,
                                padding: '12px 0',
                                background: 'white', 
                                borderBottom: '1px solid rgb(240, 240, 240)',
                            }}
                        >
                            <Button
                                disabled={!activeParentStep || !stepTreeConfig[activeParentStep - 1]}
                                type='text'
                                color='#d9d9d9'
                                icon={<LeftOutlined/>}
                                onClick={() => {
                                    setActiveParentStep(activeParentStep - 1)
                                    setChildSteps(stepsConfigToStepsProp(stepTreeConfig[activeParentStep - 1]?.children || [], currentStepKey, handleStepChange))
                                    handleStepChange(stepTreeConfig[activeParentStep - 1]?.children[0]?.key)
                                }}
                            />
                            <Flex gap={8} align='baseline' flex={1}>
                                <Flex
                                    align='center'
                                    justify='center'
                                    style={{
                                        height: 32,
                                        width: 32 ,
                                        background: '#0253ff',
                                        borderRadius: '50%',
                                        flexShrink: 0, 
                                    }}
                                >
                                    <Typography.Text style={{ color: 'white' }}>{activeParentStep + 1}</Typography.Text>
                                </Flex>
                                <Typography.Title level={5} style={{ marginTop: 0 }}>{stepTreeConfig[activeParentStep]?.title}</Typography.Title>
                            </Flex>
                            
                            <Button
                                type='text'
                                color='#d9d9d9'
                                icon={<RightOutlined/>}
                                onClick={() => {
                                    setActiveParentStep(activeParentStep + 1)
                                    setChildSteps(stepsConfigToStepsProp(stepTreeConfig[activeParentStep + 1]?.children || [], currentStepKey, handleStepChange))
                                    handleStepChange(stepTreeConfig[activeParentStep + 1]?.children[0]?.key)
                                }}
                            />
                        </Flex>
                    )}

                    {(hasPagesForCurrentStep || alertClosed) ? null : (
                        <Alert
                            style={{ paddingTop: 12 }}
                            message={`Unable to automatically recognize the ${CC_RREPORT_PAGE_LABEL_TITLE[parentStepConfig?.relatedPageLabels?.[0]]} page`}
                            type='warning'
                            showIcon
                            closable={true}
                            onClose={() => {
                                const closed = [...(reportData?.closedAlerts || []), parentStepConfig?.relatedPageLabels?.[0]]
                                updateDoc(reportSnapshot?.ref, { closedAlerts: closed })
                            }}
                        />
                    )}

                    {childSteps.length ? (
                        <Steps
                            progressDot={true}
                            style={{
                                marginLeft: 8,
                                paddingTop: 12, 
                            }}
                            current={activeStep}
                            direction='vertical'
                            size='small'
                            className={classes.steps}
                            items={
                                childSteps.map((el, i) => {
                                    const stepStatus = reviewStepStatusStap?.docs.find((doc) => doc.data().step === el.key)?.data().status
                                    const finished = stepStatus === 'accepted'
                                    const notApplicable = stepStatus === 'notApplicable'

                                    // Also have 'process' status available
                                    const status = finished ? 'finish' : notApplicable ? 'error' : 'wait'
                                    
                                    return {
                                        ...el, 
                                        description: (
                                            <Profiler id='StepContent' onRender={onStepContentRender}>
                                                <StepContent
                                                    onSkipStep={handleSkipStep} 
                                                    onNextStep={handleNextStepClick} 
                                                    stepConfig={stepsConfig.find((step) => step.key === el.key)}
                                                />
                                            </Profiler>),
                                        className: classes.childStep ,
                                        status,
                                    }
                                })
                            }
                        />
                    ) : <Flex style={{ height: '100%' }} align='center' justify='center'><Spin size='default'/></Flex>}
                </>
            )}
        </Flex>
    )
}
