import { CommentAnnotation } from '@veltdev/types/app/models/data/comment-annotation.data.model';
import { collection } from 'firebase/firestore';

import { getRequestQueries } from '@/api/utils/getRequestQueries.ts';
import { FbModelBaseReviewer } from '@/types/modelBase.ts';

import { db } from '../config.ts';

export interface AnnotationReply extends FbModelBaseReviewer {
    /**
     * Associated annotation id
     */
    parentAnnotationId?: string
    /**
     * Associated snap id
     */
    parentSnapId?: string

    replyText: string
    
    veltComment: CommentAnnotation['comments'][0]
}

export const ANNOTATION_REPLY_COLLECTION = 'annotationReply'

export const annotationReplyRef = collection(db, ANNOTATION_REPLY_COLLECTION)

export const {
    useFBItemsQuery: useAnnotationReplyQuery,
    useFBItemByIdQuery: useAnnotationReplyByIdQuery,
    useFBItemUpdateMutation: useAnnotationReplyUpdateMutation,
    useFBItemCreateMutation: useAnnotationReplyCreateMutation,
} = getRequestQueries<AnnotationReply, {}>({
    url: ANNOTATION_REPLY_COLLECTION,
})
