import {
    collection, Timestamp,
} from 'firebase/firestore';

import { getRequestQueries } from '@/api/utils/getRequestQueries';
import { FbModelBaseCommon } from '@/types/modelBase.ts';

import { db } from '../config.ts';

export type UserRole =
    'ADMIN' | // All permissions
    'PREPARER' | // Less permissions
    'REVIEWER' // More permissions

export const USER_COLLECTION = 'user'

export interface User extends FbModelBaseCommon {
    companyIds: string[]
    email: string
    displayName: string
    avatarUrl: string
    uid: string
    role: UserRole
    lastAuthorizedAt: Timestamp
    createdAt: Timestamp
    updatedAt: Timestamp
    emailVerified: boolean
}

export const userRef = collection(db,USER_COLLECTION )

export const { useFBItemsQuery: useUserQuery } = getRequestQueries<User, {}>({ url: USER_COLLECTION })
