export interface RoutesConfigValue {
    /**
     * Data for title in page HTML
     */
    title: string
    /**
     * Path template for 'react-router'
     * Must be absolute
     *
     * Use it for generating path through 'generatePath' util
     */
    path: string
}

export const ROUTES_CONFIG = {
    REVIEW: {
        title: 'Review Dashboard',
        path: '/review',
    },
    SUGGESTIONS_BUILDER: {
        staff: true,
        title: 'Suggestions Builder',
        path: '/review/:id/builder',
    },
    REVIEW_EDIT: {
        title: 'Edit Review',
        path: '/review/:id/edit',
    },
    REVEIW_NEW: {
        title: 'New Review',
        path: '/review/new',
    },
    REVIEW_REPORT: {
        title: 'Review Report',
        path: '/review/:id',
    },
    SUPPLEMENTARY_FILES: {
        title: 'Supplementary Files',
        path: '/supplementary-files',
    },
}

export const ROUTES_CONFIG_REG_REP = {
    REG_REP: {
        title: 'Regulatory Reporting',
        path: '/reg-rep',
    },
    REG_REP_NEW: {
        title: 'Uploading New Report',
        path: '/reg-rep/new',
    },
}
