import React, {
    useContext, useEffect, useState, 
} from 'react';

import {
    addDoc, DocumentSnapshot, setDoc, 
} from '@firebase/firestore';
import {
    DatePicker, Form, FormItemProps, Input, Modal, Select, Spin, Typography,
    Checkbox, 
} from 'antd';
import { RcFile } from 'antd/es/upload/interface';
import { message } from 'antd/lib';
import { format } from 'date-fns/format';
import dayjs from 'dayjs';
import {
    doc, getDoc, query, updateDoc, where, 
} from 'firebase/firestore';
import { ref as storageRef } from 'firebase/storage';
import { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';
import {
    useCollection, useCollectionDataOnce, 
} from 'react-firebase-hooks/firestore';
import { useUploadFile } from 'react-firebase-hooks/storage';
import {
    useNavigate, useParams,
} from 'react-router-dom';

import DocumentData = firebase.firestore.DocumentData;
import { FileDragger } from '../../../components/base/FileDragger';
import {
    AuthData, AuthDataContext,
} from '../../../components/containers/AuthContext';
import { CUSTOM_MODAL_WIDTH } from '../../../constants/modalWidth.ts';
import { ROUTES_CONFIG } from '../../../constants/routes.ts';
import { clientCompanyRef } from '../../../firestore/api/clientCompany.ts';
import { companyRef } from '../../../firestore/api/company.ts';
import { companyEntityRef } from '../../../firestore/api/companyEntity.ts';
import {
    EntityType,
    ReportReview,
    reportReviewFInFrameworkTitles, reportReviewRef, REPORTS_REVIEW_COLLECTION,
    reportTypeTitles,
} from '../../../firestore/api/reportReview.ts';
import {
    db, storage, 
} from '../../../firestore/config.ts';
import {
    getReviewFilepath, ReportType, 
} from '../../../firestore/utils/getReviewFilepath.ts';

import { DATE_FNS_SERVER_DATE } from '@/constants/dates.ts';
import { normalizeShortBackDate } from '@/utils/date/normalizeShortBackDate.ts';
import {
    inputFilesRef, InputFileType, InputFile, 
} from '@/firestore/api/inputFiles.ts';
import { useFeatureOn } from '@/utils/isFeatureOn.ts';
import http from '@/api/utils/http.ts';

import { QueryClient } from '@tanstack/react-query';

import { ReportDropArea } from '@/components/ReportDropArea';

import { PlusOutlined } from '@ant-design/icons';

import { CONFIG } from '@/config.ts';

type NewReportFormKeys =
    'reportFile' |
    'name' |
    'reportType' |
    'reportDate' |
    'period' |
    'clientCompanyId' |
    'entityId' |
    'reviewer' |
    'preparer' |
    'draftNumber' |
    'suppFileRcs' |
    'suppFileMapping' |
    'suppFilePriorYearFinStatement' |
    'suppFileTrialBalance' |
    'finFramework' |
    'sector' |
    'skipProcessing'

/**
 * @todo Save uploaded files in the folder of clientCompany/entity.
 *  Neet step by step filling fields for make it work
 */
export const FORM_ITEMS: Record<NewReportFormKeys, FormItemProps> = {
    sector: {
        name: 'sector',
        label: 'Regulated Sector',
    },
    reportFile: {
        name: ReportType.reportFile,
        label: 'Upload Report',
    },
    uploadType: {
        name: 'uploadType',
        label: 'Upload Type',
    },
    finFramework: {
        name: 'finFramework',
        label: 'Financial Reporting Framework',
    },
    name: {
        name: 'name',
        label: 'Report Name',
        rules: [{
            required: true,
            message: 'Please provide a name for display in the table',
        }],
    },
    reportType: {
        name: 'reportType',
        label: 'Entity Type',
        rules: [{ required: true }],
    },
    reportDate: {
        name: 'reportDate',
        label: 'Period End Date',
        rules: [{
            required: true,
            message: 'Please provide a date as specified in the report',
        }],
    },
    period: {
        name: 'period',
        label: 'Period',
        rules: [{ required: true }],
    },
    clientCompanyId: {
        name: 'clientCompanyId',
        label: 'Client',
        rules: [{
            message: 'Please  select the company associated with this report',
            required: true,
        }],
    },
    entityId: {
        name: 'entityId',
        label: 'Entity',
        rules: [{
            required: true,
            message: 'Please select the entity name associated with this report',
        }],
    },
    reviewer: {
        name: 'reviewer',
        label: 'Reviewer',
    },
    preparer: {
        name: 'preparer',
        label: 'Preparer',
    },
    draftNumber: {
        name: 'draftNumber',
        label: 'Draft Number',
        tooltip: 'If this is the first iteration, please leave it as 1. Otherwise, specify the draft number.',
        rules: [{ required: true }],
    },
    suppFileRcs: {
        label: 'Company Registration File: RCS file',
        name: 'suppFileRcs',
        // rules: [{
        //     required: true,
        // }],
    },
    suppFileMapping: {
        label: 'Account Mapping File',
        name: ReportType.suppFileMapping,
    },
    suppFilePriorYearFinStatement: {
        label: 'Prior Year Financial Statements',
        name: ReportType.suppFilePriorYearFinStatement,
        // rules: [{
        //     required: true,
        // }], 
    },
    suppFileTrialBalance: {
        label: 'Trial Balance',
        name: ReportType.suppFileTrialBalance,
    },
    skipProcessing: {
        name: 'skipProcessing',
        label: 'Don\'t trigger pipeline (Debug DEV)',
        tooltip: 'When checked, the report will be saved but processing pipeline will be skipped',
    },
} as const

const fieldsAvailableInEditMode: NewReportFormKeys[] = [
    'name',
    'reportDate',
    'period',
    'clientCompanyId',
    'entityId',
    'reviewer',
    'preparer',
    'draftNumber',
    'draftNumber',
    'reportType',
    'finFramework',
]

export type Period = 'annual' | 'semiAnnual' | 'quarterly' | 'monthly'

export const PERIOD_SELECT_OPTIONS: Array<{value: Period, label: string}> = [
    {
        value: 'annual',
        label: 'Annual', 
    },
    {
        value: 'semiAnnual',
        label: 'Semi-Annual', 
    },
    {
        value: 'quarterly',
        label: 'Quarterly', 
    },
    {
        value: 'monthly',
        label: 'Monthly', 
    },
]

// Add this interface near the top of the file with other types
interface ExtractedReportData {
    entityType: 'commercialCompany' | 'regulatedFund' | 'other' | null;
    entityName: string | null;
    date: string | null;
    financialReportingFramework: 'US_GAAP' | 'IFRS' | 'UK_GAAP' | 'LUX_GAAP' | 'OTHER' | null;
    reportingPeriod: 'annual' | 'semiAnnual' | 'quarterly' | 'monthly' | null;
    reportingDate: string | null;
}

// Add this near other constants at the top of the file
const NEW_ENTITY_PREFIX = 'new-entity-' as const;

// Add this type near other types
type FormStepData = {
    processingReportFile?: any;
    form?: any;
    suppFiles?: any;
};

/**
 * @todo Reset on close/error
 * @todo Toast on error
 */
export const AddNewReportModal = () => {
    const { id: docId } = useParams()
    const isItEditMode = Boolean(docId)
    const navigate = useNavigate()

    const authData = useContext<AuthData>(AuthDataContext)

    const [documentForEdit, setDocumentForEdit] = useState<DocumentSnapshot<ReportReview>>()
    const [periodValue, setPeriodValue] = useState<Period>()
    const [isEditFormDataReady, setIsEditFormDataReady] = useState(false)

    const [initialReportFile, setInitialReportFile] = useState<File>()

    const isModalOpen = initialReportFile || isItEditMode

    useEffect(() => {
        if(docId) {
            const document = doc(db, REPORTS_REVIEW_COLLECTION, docId)
            getDoc(document).then((doc: DocumentSnapshot<DocumentData>) => {
                if(doc.exists()) {
                    setDocumentForEdit(doc)

                    const data = doc.data()

                    const formData = fieldsAvailableInEditMode.reduce((acc, key) => {
                        if(key === 'reportDate' ) {
                            acc[key] = dayjs(normalizeShortBackDate(data[key]))

                            if(!acc.reportDate.isValid()) {
                                acc.reportDate = dayjs()
                                message.error('Report date is invalid. Please select a valid date')
                            }
                        } else {
                            acc[key] = data[key]
                        }

                        return acc
                    }, {})

                    setActiveCompanyId(data.clientCompanyId)
                    form.setFieldsValue(formData)
                    
                    setIsEditFormDataReady(true)
                }
            })
        }
    }, [docId]);

    const [uploadFile, uploading] = useUploadFile();
    const [isUploadInProgress, setIsUploadInProgress] = useState(false)

    const [companyEntityItems, companyEntityLoading] = useCollection(query(companyEntityRef, where('companyId', '==' , authData.company.id)));
    const [clientCompanyItems, clientCompanyLoading] = useCollection(query(clientCompanyRef, where('companyId', '==' , authData.company.id)));
    const [company, companyLoading] = useCollectionDataOnce(companyRef)

    const [acitveCompanyId, setActiveCompanyId] = useState<string>()

    const [form] = Form.useForm();

    form.getFieldValue(FORM_ITEMS.reportFile.name)

    const queryClient = new QueryClient()

    // Add new state for extracted data
    const [extractedReportData, setExtractedReportData] = useState<ExtractedReportData | null>(null);

    // Add this near other state declarations
    const [entityInputValue, setEntityInputValue] = useState('');
    const [newEntityName, setNewEntityName] = useState<string | null>(null);

    // Add this function near other handlers
    const handleEntitySearch = (value: string) => {
        setEntityInputValue(value);
    };

    // Add new state for storing form data across steps
    const [formStepData, setFormStepData] = useState<FormStepData>({});

    const handleFormSubmit = async (data: Partial<ReportReview>) => {
        try {
            setIsUploadInProgress(true)

            // If we have a new entity to create
            if (newEntityName && data.entityId?.startsWith(NEW_ENTITY_PREFIX)) {
                // Create the new entity
                const newEntityRef = doc(companyEntityRef);
                const newEntityData = {
                    name: newEntityName,
                    companyId: authData.company.id,
                    clientCompanyId: data.clientCompanyId,
                    // Add any other required entity fields
                };
                
                await setDoc(newEntityRef, newEntityData);
                
                // Update the form data with the real entity ID
                data.entityId = newEntityRef.id;
            }

            const result: Partial<ReportReview> = Object.keys(data)
                .filter(key => !isItEditMode || fieldsAvailableInEditMode.includes(key))
                .reduce<Partial<ReportType>>((acc, key) => {
                if(data[key]?.toDate) { // It's a date
                    debugger
                    acc[key] = data[key].toDate()
                } else if(data[key]?.file) { // It's a file
                    acc[key] = data[key].file.response.metadata.fullPath
                } else if (typeof data[key] === 'string' || typeof data[key] === 'number') {
                    acc[key] = data[key]
                }
                return acc
            }, {})

            result.reportDate = format(result.reportDate, DATE_FNS_SERVER_DATE)
            // TODO: Devault should be 'unknown' or 'other', not 'commercialCompany' after migration to tabs
            result.reportType = result.reportType || 'commercialCompany' // Use default if not set

            let reportDocRef;

            // TODO: Support files upload in edit mode
            if(isItEditMode) {
                const editPayload: Partial<ReportReview> = { ...result }
                await updateDoc(documentForEdit.ref, editPayload)
                reportDocRef = documentForEdit.ref
            } else {
                // Create input file entries for each uploaded file
                // FIXME: Use iterator here, not hardcoded items list
                const fileFields = [
                    {
                        key: ReportType.reportFile,
                        type: 'currentYearFinancialStatement' as InputFileType, 
                        file: initialReportFile,
                    },
                    {
                        key: ReportType.suppFileRcs,
                        type: 'registrationDocument' as InputFileType, 
                    },
                    {
                        key: ReportType.suppFilePriorYearFinStatement,
                        type: 'previousYearFinancialStatement' as InputFileType,
                    },
                ]
                
                const newDocPayload: ReportReview = {
                    ...result,
                    status: data.skipProcessing ? 'SKIP_PROCESSING' : 'initial',
                    progress: 0,
                    userId: authData.user.uid,
                    companyId: authData.company.id,
                    reviewStatus: 'inProgress',
                    uploadedAt: new Date(),
                }
                
                reportDocRef = doc(reportReviewRef)
                
                const uploadPromises = fileFields.map(async ({
                    key, type, file,
                }) => {
                    // File from form or config
                    const fileData = data[key]?.file || file
                    if (!fileData) return

                    // Если файл из формы - у него уже есть response
                    // Если файл начальный - нужно получить metadata из результата загрузки
                    const metadata = fileData.response?.metadata || {
                        contentType: fileData.type,
                        timeCreated: new Date().toISOString(),
                        fullPath: getReviewFilepath({
                            uuid: fileData.uid,
                            companyId: authData.company.id,
                            reportType: key,
                            fileType: fileData.type,
                        }),
                    }
                
                    const inputFile: InputFile = {
                        companyId: authData.company.id,
                        fileName: fileData.name,
                        fileType: type,
                        reportId: reportDocRef.id,
                        extension: metadata.contentType?.split('/').pop() || 'pdf', // Extract from content type
                        uploaded_at: new Date(metadata.timeCreated), // Use Firebase storage timestamp
                        uploadedBy: authData.user.uid,
                        storagePath: metadata.fullPath,
                        entityId: data.entityId,
                        originalStoragePath: metadata.fullPath,
                        azureRawDocumentExtractPath: null,
                        azurePageClassificationExtractPath: null,
                        template: null,
                        ocrApplied: false,
                    }
                
                    return addDoc(inputFilesRef, inputFile)
                })
                
                // Tabs first
                await Promise.all(uploadPromises)
                // Than report
                await setDoc(reportDocRef, newDocPayload)
                queryClient.invalidateQueries({ queryKey: [REPORTS_REVIEW_COLLECTION] })
            }
        } finally {
            setIsUploadInProgress(false)
            setNewEntityName(null)
        }
    }

    const handleOk = () => {
        form
            .validateFields()
            .then(async (values) => {
                // Store current step data
                setFormStepData(prevData => ({
                    ...prevData,
                    [reportUploadingStep]: values,
                }));

                if (reportUploadingStep === 'form') {
                    setReportUploadingStep('suppFiles');
                } else if (reportUploadingStep === 'suppFiles' || isItEditMode) {
                    // Combine all step data for final submission
                    const combinedFormData = {
                        ...formStepData.form,
                        ...formStepData.suppFiles,
                        ...values,
                    };

                    await handleFormSubmit(combinedFormData);
                    form.resetFields();
                    setInitialReportFile(undefined);
                    setReportUploadingStep('processingReportFile');
                    setFormStepData({}); // Reset step data
                    navigate(ROUTES_CONFIG.REVIEW.path);
                }
            })
            .catch((info) => {
                console.error('Form validation failed: ', info);
                message.error('Please fill in all required fields before submitting');
                setIsUploadInProgress(false);
            });
    };

    const handleCancel = () => {
        form.resetFields();
        navigate(ROUTES_CONFIG.REVIEW.path);
        setInitialReportFile(undefined);
        setReportUploadingStep('processingReportFile');
        setFormStepData({}); // Reset step data
    };

    const tryToExtractTheReportWithTheAI = async (file: File) => {
        try {
            const formData = new FormData();
            formData.append('pdf', file);
            formData.append('companyId', authData.company.id);

            const response = await http.post<ExtractedReportData>('/config/extract-report-data', 
                formData, { headers: { 'Content-Type': 'multipart/form-data' } });

            // Store the extracted data
            setExtractedReportData(response.data);

            // Auto-fill form fields with extracted data
            form.setFieldsValue({
                [FORM_ITEMS.reportType.name]: response.data.entityType,
                [FORM_ITEMS.reportDate.name]: response.data.reportingDate ? dayjs(response.data.reportingDate) : undefined,
                [FORM_ITEMS.finFramework.name]: response.data.financialReportingFramework,
                [FORM_ITEMS.period.name]: response.data.reportingPeriod,
            });

            if(response.data.entityType) {
                setSelectedReportType(response.data.entityType)
            }

            // Move to the next step after successful extraction
            setReportUploadingStep('form');
        } catch (error) {
            // ENV: Log error
            console.error('Error extracting report data:', error);
            message.warning('Failed to extract report data automatically. Please fill in the fields manually.');
            
            // Still move to form step even if extraction fails
            setReportUploadingStep('form');
        }
    };
    
    const draggerCustomRequest = async (formItemProps: FormItemProps, data: RcCustomRequestOptions) => {
        try {
            data.onProgress({ percent: 0 });

            const file = data.file as RcFile;
            
            let extractionPromise = Promise.resolve()

            // Apply AI to the main rep file
            if(formItemProps.name == ReportType.reportFile) {
                setInitialReportFile(file)
                extractionPromise = tryToExtractTheReportWithTheAI(file)
            }

            // Save filename in form if main report file
            if (!form.getFieldValue(FORM_ITEMS.name.name) && formItemProps.name === ReportType.reportFile) {
                form.setFieldsValue({ [FORM_ITEMS.name.name]: file.name.replace('.pdf', '') })
            }
        
            // Continue with existing storage upload logic to star
            const filePath = getReviewFilepath({
                uuid: file.uid,
                companyId: authData.company.id,
                reportType: formItemProps.name as ReportType,
                fileType: file.type,
            });

            const res = await uploadFile(storageRef(storage, filePath), file);

            if (!res) {
                throw new Error('File upload failed');
            }

            // Optional step
            try {
                // If not finished yes, then wait
                await extractionPromise
            } catch (e) {
                console.error('Report data extraction failed', e)
            }

            data.onProgress({ percent: 100 });
            data.onSuccess(res);

            if(formItemProps.name == ReportType.reportFile) {
                setReportUploadingStep('form')
            }
        } catch (error) {
            // ENV: Log error
            console.error('Error in draggerCustomRequest:', error);
            message.error('Failed to process file upload');
            data.onError(error);
        }
    }

    const hideCC = useFeatureOn('hideCommertialCompany')
    const showUnknownEntity = useFeatureOn('showUnknownEntity')
    const showFunds = useFeatureOn('funds')

    const [selectedReportType, setSelectedReportType] = useState<EntityType>()

    const hasUserWithoutUid = authData.usersInCompany.some((item) => !item.uid)

    useEffect(() => {
        if(hasUserWithoutUid) {
            console.error('Users without uid detected! Should be fixed manually.')
        }
    }, [hasUserWithoutUid]);

    // Add these effects after other useEffects
    useEffect(() => {
        if (
            clientCompanyItems?.docs.length === 1 && 
            !form.getFieldValue(FORM_ITEMS.clientCompanyId.name) &&
            !isItEditMode
        ) {
            const firstClientId = clientCompanyItems.docs[0].id
            form.setFieldValue(FORM_ITEMS.clientCompanyId.name, firstClientId)
            setActiveCompanyId(firstClientId)
        }
    }, [clientCompanyItems, form, isItEditMode])

    useEffect(() => {
        if(isItEditMode) return

        // Reset entity when company changes
        form.setFieldValue(FORM_ITEMS.entityId.name, undefined)
        
        if (
            companyEntityItems?.docs &&
            acitveCompanyId &&
            !form.getFieldValue(FORM_ITEMS.entityId.name) &&
            !isItEditMode
        ) {
            const filteredEntities = companyEntityItems.docs
                .filter((item) => item.data().clientCompanyId === acitveCompanyId)
            
            if (filteredEntities.length === 1) {
                form.setFieldValue(FORM_ITEMS.entityId.name, filteredEntities[0].id)
            }
        }
    }, [companyEntityItems, acitveCompanyId, form, isItEditMode, isItEditMode])

    type ReportUploadingStep = 'processingReportFile' | 'form' | 'suppFiles'
    const [reportUploadingStep, setReportUploadingStep ] = useState<ReportUploadingStep>('processingReportFile')

    // Autoselect entity if AI extracted it
    useEffect(() => {
        if (extractedReportData && reportUploadingStep === 'form') {
            // Check if we have an entity name from AI
            if (extractedReportData.entityName) {
                // Try to find matching entity
                const matchingEntity = companyEntityItems?.docs.find(
                    doc => doc.data().name.toLowerCase() === extractedReportData.entityName.toLowerCase(),
                );

                if (matchingEntity) {
                    // If entity exists, select it and set its client
                    form.setFieldsValue({
                        [FORM_ITEMS.entityId.name]: matchingEntity.id,
                        [FORM_ITEMS.clientCompanyId.name]: matchingEntity.data().clientCompanyId,
                    });
                    setActiveCompanyId(matchingEntity.data().clientCompanyId)
                } else {
                    // If entity doesn't exist, create new one
                    setNewEntityName(extractedReportData.entityName);
                    form.setFieldValue(
                        FORM_ITEMS.entityId.name, 
                        `${NEW_ENTITY_PREFIX}${extractedReportData.entityName}`,
                    );
                }
            }
        }
    }, [extractedReportData, reportUploadingStep, companyEntityItems?.docs, form]);

    // When moving to a new step, pre-fill form with previous data
    useEffect(() => {
        if (reportUploadingStep === 'suppFiles' && formStepData.form) {
            form.setFieldsValue(formStepData.form);
        }
    }, [reportUploadingStep]);

    const title = reportUploadingStep == 'processingReportFile'
        ? 'Uploading New Report' : reportUploadingStep == 'form'
            ? 'Specifying Report Details' : reportUploadingStep == 'suppFiles'
                ? 'Upload Supplementary Files' : 'Uploading New Report'

    return (
        <>
            <ReportDropArea
                disable={!!initialReportFile}
                customRequest={(options: RcCustomRequestOptions) => draggerCustomRequest(FORM_ITEMS.reportFile, options)}
            />
            <Modal
                open={isModalOpen}
                confirmLoading={uploading || isUploadInProgress}
                title={isItEditMode ? 'Edit' : title}
                centered
                onOk={handleOk}
                onCancel={handleCancel}
                width={CUSTOM_MODAL_WIDTH.medium}
                okText='Confirm'
                footer={(reportUploadingStep === 'processingReportFile' && !isItEditMode) ? null : undefined}
                styles={{
                    body: { overflowY: 'auto' },
                    content: {
                        overflowY: 'hidden',
                        maxHeight: '90vh',
                        display: 'flex',
                        flexDirection: 'column',
                    },
                }}
            >
                <>
                    <>
                        {(!isItEditMode && reportUploadingStep === 'processingReportFile') && (
                            <div
                                style={{ 
                                    display: 'flex', 
                                    justifyContent: 'center', 
                                    alignItems: 'center',
                                    padding: '2rem',
                                    gap: 20,
                                    flexDirection: 'column',
                                }}
                            >
                                <Spin size='large'/>
                                <Typography.Title level={5}>Uploading and Analyzing File</Typography.Title>
                            </div>
                        )}
                    </>
                    <Form
                        form={form}
                        wrapperCol={{ span: 24 }}
                        labelCol={{ span: 24 }}
                        onFinish={handleFormSubmit}
                        autoComplete='off'
                        scrollToFirstError={true}
                        layout='vertical'
                        disabled={isItEditMode && !isEditFormDataReady}
                    >
                        {(reportUploadingStep === 'reportFile' || isItEditMode) && (
                            <>
                                <Form.Item
                                    {...FORM_ITEMS.reportFile}
                                    hidden={isItEditMode && !fieldsAvailableInEditMode.includes(FORM_ITEMS.reportFile.name)}
                                    rules={[{
                                        required: !isItEditMode,
                                        message: 'Please upload report for review',
                                    }]}
                                >
                                    <FileDragger
                                        customRequest={(options: RcCustomRequestOptions) => draggerCustomRequest(FORM_ITEMS.reportFile, options)}
                                    />
                                </Form.Item>
                            </>
                        )}

                        {(reportUploadingStep === 'form' || isItEditMode) && (
                            <>
                                {/*// FIXME: add this*/}
                                {/*{!isItEditMode && <Alert message='Some Required Fields are Missing' type='error' icon={}/>}*/}

                                <Form.Item
                                    {...FORM_ITEMS.name}
                                    hidden={isItEditMode && !fieldsAvailableInEditMode.includes(FORM_ITEMS.name.name)}
                                >
                                    <Input placeholder={FORM_ITEMS.name.label as string}/>
                                </Form.Item>

                                <Form.Item
                                    {...FORM_ITEMS.reportType}
                                    hidden={
                                        (isItEditMode && !fieldsAvailableInEditMode.includes(FORM_ITEMS.reportType.name))
                                    }
                                    rules={[{ required: true }]}
                                    help={selectedReportType === 'other' ? 'The Step Guide will not be displayed for unknown entity types.' : undefined}
                                >
                                    <Select
                                        disabled={isItEditMode}
                                        placeholder={FORM_ITEMS.reportType.label}
                                        onSelect={(value) => {
                                            setSelectedReportType(value)
                                        }}
                                    >
                                        {Object.entries(reportTypeTitles)
                                        // Exclude CC if disabled
                                            .filter(([key]) => hideCC ? key !== 'commercialCompany' : true)
                                        // Exclude Unknown if disabled
                                            .filter(([key]) => !showUnknownEntity ? key !== 'other' : true)
                                            .filter(([key]) => !showFunds ? key !== 'regulatedFund' : true)
                                            .map(([value, label]) => (
                                                <Select.Option key={value} value={value}>{label}</Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    {...FORM_ITEMS.finFramework}
                                    hidden={
                                        (isItEditMode && !fieldsAvailableInEditMode.includes(FORM_ITEMS.finFramework.name))
                                    }
                                    rules={[{ required: true }]}
                                >
                                    <Select
                                        disabled={isItEditMode}
                                        placeholder={FORM_ITEMS.finFramework.label}
                                    >
                                        {Object.entries(reportReviewFInFrameworkTitles)
                                            .map(([value, label]) => (
                                                <Select.Option key={value} value={value}>{label}</Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    {...FORM_ITEMS.reportDate}
                                    hidden={isItEditMode && !fieldsAvailableInEditMode.includes(FORM_ITEMS.reportDate.name)}
                                >
                                    <DatePicker/>
                                </Form.Item>

                                <Form.Item
                                    {...FORM_ITEMS.period}
                                    hidden={isItEditMode && !fieldsAvailableInEditMode.includes(FORM_ITEMS.period.name)}
                                >
                                    <Select
                                        placeholder={FORM_ITEMS.period.label}
                                        onSelect={(value) => {
                                            setPeriodValue(value)
                                        }}
                                    >
                                        {
                                            PERIOD_SELECT_OPTIONS.map((item) => (
                                                <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    {...FORM_ITEMS.clientCompanyId}
                                    hidden={isItEditMode && !fieldsAvailableInEditMode.includes(FORM_ITEMS.clientCompanyId.name)}
                                >
                                    <Select
                                        defaultActiveFirstOption
                                        placeholder={FORM_ITEMS.clientCompanyId.label}
                                        loading={clientCompanyLoading}
                                        onSelect={(id) => {
                                            setActiveCompanyId(id)
                                        }}
                                    >
                                        {clientCompanyItems?.docs.map((item) => (
                                            <Select.Option key={item.id} value={item.id}>{item.data().name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    {...FORM_ITEMS.entityId}
                                    hidden={isItEditMode && !fieldsAvailableInEditMode.includes(FORM_ITEMS.entityId.name)}
                                >
                                    <Select
                                        showSearch
                                        defaultActiveFirstOption
                                        placeholder={FORM_ITEMS.entityId.label}
                                        loading={companyEntityLoading}
                                        onSearch={handleEntitySearch}
                                        filterOption={(input, option) =>
                                            (option?.children as string).toLowerCase().includes(input.toLowerCase())
                                        }
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                {entityInputValue && !companyEntityItems?.docs.some(
                                                    // FIXME Filtered options to constant
                                                    doc => doc.data().name.toLowerCase() === entityInputValue.toLowerCase(),
                                                ) && (
                                                    <div
                                                        style={{
                                                            padding: '8px',
                                                            cursor: 'pointer',
                                                            borderTop: '1px solid #e8e8e8',
                                                        }}
                                                        onMouseDown={(e) => e.preventDefault()}
                                                        onClick={() => {
                                                            setNewEntityName(entityInputValue);
                                                            form.setFieldValue(FORM_ITEMS.entityId.name, `${NEW_ENTITY_PREFIX}${entityInputValue}`);
                                                            // Close the select dropdown
                                                            (document.activeElement as HTMLElement)?.blur();
                                                        }}
                                                    >
                                                        <PlusOutlined style={{ marginRight: 8 }}/>
                                                        Create new Entity: "{entityInputValue}"
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    >
                                        {companyEntityItems?.docs
                                            .filter((item) => item.data().clientCompanyId === acitveCompanyId)
                                            .map((item) => (
                                                // FIXME: Can be used .ref.path instead of id
                                                <Select.Option key={item.id} value={item.id}>{item.data().name}</Select.Option>
                                            ))}
                                        {/* Add virtual option for new entity */}
                                        {newEntityName && (
                                            <Select.Option key={`${NEW_ENTITY_PREFIX}${newEntityName}`} value={`${NEW_ENTITY_PREFIX}${newEntityName}`}>
                                                {newEntityName}
                                            </Select.Option>
                                        )}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    {...FORM_ITEMS.draftNumber}
                                    hidden={isItEditMode && !fieldsAvailableInEditMode.includes(FORM_ITEMS.draftNumber.name)}
                                    initialValue={!isItEditMode ? 1 : undefined}
                                >
                                    <Input placeholder={FORM_ITEMS.draftNumber.label as string}/>
                                </Form.Item>
                                <Form.Item
                                    {...FORM_ITEMS.reviewer}
                                    hidden={isItEditMode && !fieldsAvailableInEditMode.includes(FORM_ITEMS.reviewer.name)}
                                >
                                    <Select
                                        defaultActiveFirstOption
                                        placeholder={FORM_ITEMS.reviewer.label}
                                    >
                                        {authData.usersInCompany.map((item) => (
                                            <Select.Option key={item.uid} value={item.uid}>{item.displayName}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    {...FORM_ITEMS.preparer}
                                    hidden={isItEditMode && !fieldsAvailableInEditMode.includes(FORM_ITEMS.preparer.name)}
                                >
                                    <Select
                                        defaultActiveFirstOption
                                        placeholder={FORM_ITEMS.preparer.label}
                                    >
                                        {authData
                                            .usersInCompany
                                            .filter((user) => Boolean(user.uid))
                                            .map((item) => (
                                                <Select.Option key={item.uid} value={item.uid}>{item.displayName}</Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                
                                {/* TODO: Will be processed lated */}
                                {/*<Form.Item*/}
                                {/*    {...FORM_ITEMS.suppFileMapping}*/}
                                {/*    hidden={isItEditMode && !fieldsAvailableInEditMode.includes(FORM_ITEMS.suppFileMapping.name)}*/}
                                {/*>*/}
                                {/*    <FileDragger*/}
                                {/*        customRequest={(options: RcCustomRequestOptions) => draggerCustomRequest(FORM_ITEMS.suppFileMapping, options)}*/}
                                {/*        formats={['xlsx']}*/}
                                {/*    />*/}
                                {/*</Form.Item>*/}
                                {/*<Form.Item*/}
                                {/*    {...FORM_ITEMS.suppFileTrialBalance}*/}
                                {/*    hidden={isItEditMode && !fieldsAvailableInEditMode.includes(FORM_ITEMS.suppFileTrialBalance.name)}*/}
                                {/*>*/}
                                {/*    <FileDragger*/}
                                {/*        customRequest={(options: RcCustomRequestOptions) => draggerCustomRequest(FORM_ITEMS.suppFileTrialBalance, options)}*/}
                                {/*        formats={['xlsx']}*/}
                                {/*    />*/}
                                {/*</Form.Item>*/}

                                {(CONFIG.ENV === 'development' || CONFIG.ENV === 'local') && (
                                    <Form.Item
                                        {...FORM_ITEMS.skipProcessing}
                                        valuePropName='checked'
                                    >
                                        <Checkbox>
                                            {FORM_ITEMS.skipProcessing.label}
                                        </Checkbox>
                                    </Form.Item>
                                )}
                            </>
                        )}

                        {(!isItEditMode && reportUploadingStep === 'suppFiles' ) && (
                            <>
                                <Form.Item
                                    {...FORM_ITEMS.suppFileRcs}
                                    hidden={(isItEditMode && !fieldsAvailableInEditMode.includes(FORM_ITEMS.suppFileRcs.name)) || selectedReportType !== 'commercialCompany'}
                                >
                                    <FileDragger
                                        customRequest={(options: RcCustomRequestOptions) => draggerCustomRequest(FORM_ITEMS.suppFileRcs, options)}
                                    />
                                </Form.Item>
                                <Form.Item
                                    {...FORM_ITEMS.suppFilePriorYearFinStatement}
                                    hidden={isItEditMode && !fieldsAvailableInEditMode.includes(FORM_ITEMS.suppFilePriorYearFinStatement.name)}
                                    // rules={[{
                                    //     required: !isItEditMode,
                                    // }]}
                                >
                                    <FileDragger
                                        customRequest={(options: RcCustomRequestOptions) => draggerCustomRequest(FORM_ITEMS.suppFilePriorYearFinStatement, options)}
                                    />
                                </Form.Item>
                            </>
                        )}
                    </Form>
                </>
            </Modal>
        </>
    )
}
