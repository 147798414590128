import React, { useRef, useState } from 'react'

import { BlockOutlined } from '@ant-design/icons';
import { DocumentSnapshot } from '@firebase/firestore';
import { Core } from '@pdftron/webviewer';
import * as Sentry from '@sentry/react';
import { Flex, Spin, Tooltip, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { BooleanParam, useQueryParam, withDefault } from 'use-query-params';

import {
    ReportExtractedValues, reportExtractedVlueTypesToFetch,
    useReportExtractedValuesQuery,
} from '@/firestore/api/reportExtractedValues.ts';
import { useViewerDocument } from '@/hooks/useViewerDocument.ts';
import {
    ExtractedBlockItem,
} from '@/pages/ReviewPage/GuidePanel/Steps/StepContent/ExtractedValuesBlock/ExtractedBlockItem';
import { LocalErrorBoundary } from '@/widgets/CriticalErrorPlaceholder';

import { ExtractedValuesBlockProps } from './ExtractedValuesBlock.types'

/**
 *  TODO: Delete it after full migration to ExtractedValue
 */
export const ExtractedValuesBlock = Sentry.withProfiler((props: ExtractedValuesBlockProps) => {
    const { messages, stepConfig } = props
    const { annotationManager, pdfInstance, documentViewer } = useViewerDocument()
    const [blocksRelations, setBlocksRelations] = useState<Record<string, Core.Annotations.Annotation>>({})
    const annotCreatedByBlockId = useRef([] as string[])
    const [annotationsReady, setAnnotationsReady] = useState(false)
    const prevIntervalId = useRef<number | null>(null)

    const { id: docId } = useParams()

    const reportExtractedValuesQuery = useReportExtractedValuesQuery({
        filters: [
            'and',
            ['reportId', '==', docId as string],
            ['type', 'in', reportExtractedVlueTypesToFetch],
        ],
    })

    const extractedValuesForStep =
        (reportExtractedValuesQuery?.data?.filter((el) => el.step === stepConfig.key || Boolean(el.steps?.includes(stepConfig.key))) || [])
            .sort((a, b) => (b.createdAt?.toDate?.().getTime() || 0) - (a.createdAt?.toDate?.().getTime() || 0))

    if(reportExtractedValuesQuery.isLoading) {
        return (
            <Flex align='center' justify='center'>
                <Spin/>
            </Flex>
        )
    }

    return (
        <>
            {extractedValuesForStep.length > 0 && (
                <>
                    <Flex gap={4} justify='baseline'>
                        <Tooltip placement='left' title='Items generated automatically for this step'><BlockOutlined style={{ color: '#13C2C2' }}/></Tooltip>
                        <Typography.Text strong>Validated Elements</Typography.Text>
                    </Flex>
                    <Flex gap={4} vertical>
                        {extractedValuesForStep.map((block: ReportExtractedValues) => (
                            <LocalErrorBoundary noContent key={block.id}>
                                <ExtractedBlockItem
                                    block={block}
                                    // Match by extracted value id
                                    messages={messages?.filter(mesg => mesg?.data().extractedValueId === block.id)}
                                />
                            </LocalErrorBoundary>
                        ))}
                    </Flex>
                </>
            )}
        </>
    )
})
