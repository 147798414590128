import React from 'react'

import {
    CommentOutlined,
    CopyOutlined, DeleteOutlined,
} from '@ant-design/icons';
import {
    Badge,
    Button, Flex, Spin, Tag, Typography,
} from 'antd';
import { message } from 'antd/lib';

import { useAnnotationReplyQuery } from '@/firestore/api/annotationReply.ts';
import {
    REPORT_EXTRACTED_VALUE_LABLEL_BY_TYPE,
    useReportExtractedValuesByIdQuery,
    useReportExtractedValuesUpdateMutation,
} from '@/firestore/api/reportExtractedValues.ts';
import {
    TAG_COLOR_BY_CONF_COLOR, useValueValidationQuery, 
} from '@/firestore/api/valueValidation.ts';
import { useFocusedValueId } from '@/hooks/useFocusedValueId.ts';
import { useViewerDocument } from '@/hooks/useViewerDocument.ts';
import styles from '@/pages/ReviewPage/GuidePanel/AnnotationContext/AnnotationContext.module.scss';
import { getValidationValueConfColor } from '@/pages/ReviewPage/GuidePanel/Steps/StepContent/ExtractedValuesBlock/ExtractedBlockItem';
import { makeColorTransparent } from '@/utils/makeColorTransparent';
import { deleteAnnotationByRelatedSnapId } from '@/utils/pdfViewer/deleteAnnotationByRelatedSnapId.ts';

import { AnnotationContextHeaderProps } from './AnnotationContextHeader.types'

export const AnnotationContextHeader = (props: AnnotationContextHeaderProps) => {
    const { pdfInstance } = useViewerDocument()
    
    const {
        selectedExtractedValSnapId, setSelectedExtractedValSnapId, 
    } = useFocusedValueId()

    const ananotationReplyOnSnapQuery = useAnnotationReplyQuery({
        filters: [
            'and',
            ['parentSnapId', '==', selectedExtractedValSnapId as string],
        ],
    }, { enabled: Boolean(selectedExtractedValSnapId) })

    const messagesCount = ananotationReplyOnSnapQuery?.data?.length || 0

    const valuesValidationQuery = useValueValidationQuery({ filters: ['extractedValueId', '==', selectedExtractedValSnapId as string] }, { enabled: !!selectedExtractedValSnapId })

    const reportExtractedValuesByIdQuery = useReportExtractedValuesByIdQuery(selectedExtractedValSnapId, { enabled: !!selectedExtractedValSnapId })

    const { annotationManager } = useViewerDocument()

    const reportExtractedValuesUpdateMutation = useReportExtractedValuesUpdateMutation()

    const isLoading = valuesValidationQuery.isLoading || reportExtractedValuesByIdQuery.isLoading
    const isExists = Boolean(selectedExtractedValSnapId && ((!reportExtractedValuesByIdQuery.isLoading && reportExtractedValuesByIdQuery.data) || reportExtractedValuesByIdQuery.isLoading) )

    if(!isExists) return null

    const confColor = valuesValidationQuery?.data?.[0] ? getValidationValueConfColor(valuesValidationQuery.data[0]).color : undefined
    const value = reportExtractedValuesByIdQuery.data?.originalValue
    // @ts-expect-error
    const labelText = REPORT_EXTRACTED_VALUE_LABLEL_BY_TYPE[reportExtractedValuesByIdQuery.data?.type]

    return (
        <Flex
            vertical
            align='center'
            justify='center'
            style={{
                // Height hardcoded for the loading state
                minHeight: 138,
                width: '100%',
                borderBottom: 'solid 1px #f0f0f0',
                paddingBottom: 20,
                background: confColor ? makeColorTransparent(confColor, 0.05) : '#fafeff',
                position: 'relative',
            }}
        >
            {isLoading ? (<Spin/>) : (
                <>
                    <span
                        style={{
                            textAlign: 'center',
                            padding: '0 16px', 
                        }}
                    >
                        <Typography.Title
                            style={{
                                borderBottom: `solid 3px ${confColor}`,
                                WebkitLineClamp: 2,
                                display: '-webkit-box',
                            }}
                            className={styles.valueText}
                        >
                            {value ? value : '—'}
                        </Typography.Title>
                    </span>
                    <Flex gap={8} align='center' justify='center'>
                        {Boolean(labelText) && (
                            <Tag 
                                style={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                }}
                                color={valuesValidationQuery?.data?.[0] ? TAG_COLOR_BY_CONF_COLOR[getValidationValueConfColor(valuesValidationQuery.data[0])?.conf] : undefined}
                            >{labelText}</Tag>
                        )}
                        <Badge count={messagesCount} size='small'>
                            <Button
                                icon={<CommentOutlined/>}
                                onClick={async () => {
                                    // pdfInstance?.UI.openElements(['notesPanel']);
                                    pdfInstance?.UI.toggleElementVisibility('notesPanel');
                                }}
                                size='small'
                            />
                        </Badge>
                        <Button
                            icon={<CopyOutlined/>}
                            onClick={async () => {
                                const val = reportExtractedValuesByIdQuery.data?.originalValue
                                if (!val) return
                                navigator.clipboard.writeText(val)
                                message.success('Copied to clipboard')
                            }}
                            size='small'
                        />
                        <Button
                            icon={<DeleteOutlined/>}
                            onClick={async () => {
                                if (!selectedExtractedValSnapId) return

                                const extractedValuesSnap = reportExtractedValuesByIdQuery.data

                                const type = extractedValuesSnap?.type

                                if (type === 'money') {
                                    await reportExtractedValuesUpdateMutation.mutateAsync({
                                        id: selectedExtractedValSnapId,
                                        data: { type: 'number' },
                                    })
                                } else {
                                    await reportExtractedValuesUpdateMutation.mutateAsync({
                                        id: selectedExtractedValSnapId,
                                        data: { rejected: true },
                                    })
                                }

                                setSelectedExtractedValSnapId(undefined)

                                deleteAnnotationByRelatedSnapId(annotationManager, selectedExtractedValSnapId)
                            }}
                            size='small'
                        />
                        {/*<Button*/}
                        {/*    icon={<EyeOutlined/>}*/}
                        {/*    onClick={async () => {*/}
                        {/*        annotationManager?.jumpToAnnotation(focusedAnnotationObj)*/}
                        {/*    }}*/}
                        {/*    size='small'*/}
                        {/*/>*/}
                    </Flex> 
                </>
            )}
        </Flex>
    )
}
