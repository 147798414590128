import React from 'react'

import {
    ClockCircleOutlined,
    DownloadOutlined,
} from '@ant-design/icons';
import {
    Button, Checkbox, Flex, Result, Typography, Alert, Card,
} from 'antd';
import {
    doc, updateDoc, 
} from 'firebase/firestore';
import { useDocument } from 'react-firebase-hooks/firestore';
import { useParams } from 'react-router-dom';
import { useQueryParam } from 'use-query-params';

import { QUERY_PARAMS_CONFIG } from '@/config/queryParams.ts';
import { COLORS } from '@/constants/colors.ts';
import {
    ReportReview, reportReviewRef, REVIEW_STATUS_ACTION_TEXT,
} from '@/firestore/api/reportReview.ts';
import { useDownloadReportPdf } from '@/hooks/useDownloadReportPdf.ts';
import { useLocalStorage } from '@/hooks/useLocalStorage.ts';
import styles from '@/pages/FilesPage/FilesPage.module.css';

import { SuccessReviewProps } from './SuccessReview.types'

// Constants for localStorage keys and default values
export const EXPORT_STORAGE_KEYS = {
    INCLUDE_GREEN_TICKS: 'includeGreenTicks',
    INCLUDE_RED_TICKS: 'includeRedTicks',
};

export const EXPORT_DEFAULT_VALUES = {
    INCLUDE_GREEN_TICKS: false,
    INCLUDE_RED_TICKS: true,
};

export const SuccessReview = (props: SuccessReviewProps) => {
    const { id: docId } = useParams()
    const [reportSnapshot, isReportDataLoading ] = useDocument<ReportReview>(doc(reportReviewRef, docId))

    const {
        downloadPdf , downloadingPdf,
    } = useDownloadReportPdf()

    const [summaryModeIsOn] = useQueryParam(QUERY_PARAMS_CONFIG.ANNOTATIONS_SUMMARY_PDF_MODE.key, QUERY_PARAMS_CONFIG.ANNOTATIONS_SUMMARY_PDF_MODE.type)
    
    // Use localStorage to persist checkbox states
    const [includeGreenTicks, setIncludeGreenTicks] = useLocalStorage(
        EXPORT_STORAGE_KEYS.INCLUDE_GREEN_TICKS,
        EXPORT_DEFAULT_VALUES.INCLUDE_GREEN_TICKS,
    );
    const [includeRedTicks, setIncludeRedTicks] = useLocalStorage(
        EXPORT_STORAGE_KEYS.INCLUDE_RED_TICKS,
        EXPORT_DEFAULT_VALUES.INCLUDE_RED_TICKS,
    );

    // Handle checkbox changes with dependencies
    const handleGreenTicksChange = (checked: boolean) => {
        setIncludeGreenTicks(checked);
        // If green ticks are enabled, also enable red ticks
        if (checked && !includeRedTicks) {
            setIncludeRedTicks(true);
        }
    };

    const handleRedTicksChange = (checked: boolean) => {
        setIncludeRedTicks(checked);
        // If red ticks are disabled, also disable green ticks
        if (!checked) {
            setIncludeGreenTicks(false);
        }
    };

    return (
        <Flex
            align='center' 
            vertical
            gap={8}
        >
            {/*{downloaded && (*/}
            {/*    <Alert*/}
            {/*        message='Green and yellow Tick Marks along with Links are excluded from the final report'*/}
            {/*        type='warning'*/}
            {/*        showIcon*/}
            {/*        closable*/}
            {/*    />*/}
            {/*)}*/}
            <Result
                status='success'
                title='Review successfully completed!'
                subTitle='Review complete! Download the report with comments and marked errors.'
            />
            <Flex vertical gap={40} style={{ width: '100%' }}>
                {/*<Flex gap={8} style={{ position: 'relative' }}>*/}
                {/*    {isTrialModeOn && (*/}
                {/*        <Tooltip*/}
                {/*            title='Summary report export is unavailable for trial users'*/}
                {/*        >*/}
                {/*            <QuestionCircleOutlined*/}
                {/*                style={{*/}
                {/*                    color: COLORS.orange,*/}
                {/*                    position: 'absolute',*/}
                {/*                    left: '-25px',*/}
                {/*                    top: '50%',*/}
                {/*                    transform: 'translateY(-50%)',*/}
                {/*                    height: 30,*/}
                {/*                    width: 30,*/}
                {/*                }}*/}
                {/*                size={30}*/}
                {/*            />*/}
                {/*        </Tooltip>*/}
                {/*    )}*/}
                {/*    <Button*/}
                {/*        disabled={isTrialModeOn}*/}
                {/*        style={{ flexGrow: 1 }}*/}
                {/*        type='primary'*/}
                {/*        loading={isReportDataLoading}*/}
                {/*        href={reportData?.reportType === 'regulatedFund' ? '/summary_report_funds.xlsx' : '/summary_report_cc.xlsx'}*/}
                {/*        icon={<DownloadOutlined/>}*/}
                {/*    >*/}
                {/*    Download Summary Report*/}
                {/*    </Button>*/}
                {/*</Flex>*/}
                <Card
                    className={styles.card}
                    size='small'
                    styles={{ 
                        body: {
                            padding: 16,
                            // display: 'flex',
                            // gap: 16,
                            // flexWrap: 'wrap',
                            // alignItems: 'center',
                        },
                    
                    }}
                >
                    <Flex gap={8} style={{ position: 'relative' }} vertical={true}>                    
                                 
                        <Button
                            type='primary'
                            icon={<DownloadOutlined/>}
                            loading={downloadingPdf || summaryModeIsOn}
                            onClick={downloadPdf}
                            style={{ flexGrow: 1 }}
                        >
                        Download Report with Annotations
                        </Button>
                        {(includeGreenTicks || includeRedTicks) ? (
                            <Alert 
                                message='A legend page explaining the tick marks will be included' 
                                type='info' 
                                showIcon={true}
                                style={{
                                    marginBottom: 8,
                                    fontSize: 12, 
                                }}
                            />
                        ) : (
                            <Alert 
                                message='All comments and annotations added manually will be included in the PDF.'
                                type='info' 
                                showIcon={true}
                                style={{
                                    marginBottom: 8,
                                    fontSize: 12, 
                                }}
                            />
                        )}
                        <Flex gap={20} justify='center'>
                            {/*<Checkbox */}
                            {/*    disabled={Boolean(downloadingPdf || summaryModeIsOn)}*/}
                            {/*    checked={includeGreenTicks} */}
                            {/*    onChange={(e) => handleGreenTicksChange(e.target.checked)}*/}
                            {/*>*/}
                            {/*Include <Typography.Text strong={true} style={{ color: COLORS.green }}>Green</Typography.Text> Ticks*/}
                            {/*</Checkbox>*/}
                            <Checkbox 
                                disabled={Boolean(downloadingPdf || summaryModeIsOn)}
                                checked={includeRedTicks} 
                                onChange={(e) => handleRedTicksChange(e.target.checked)}
                            >
                            Include <Typography.Text strong={true} style={{ color: COLORS.red }}>Red</Typography.Text> Ticks
                            </Checkbox>
                        </Flex>
                    </Flex>
                </Card>
                <Button
                    loading={downloadingPdf || summaryModeIsOn}
                    icon={<ClockCircleOutlined/>}
                    onClick={async () => {
                        if(reportSnapshot?.ref) {
                            await updateDoc(reportSnapshot.ref, {
                                currentStep: '',
                                reviewStatus: 'inProgress', 
                            })
                        }
                    }}
                >
                    {REVIEW_STATUS_ACTION_TEXT.inProgress}
                </Button>
            </Flex>
        </Flex>
    )
}
