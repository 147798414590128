import React, {
    useContext, useEffect, useState,
} from 'react'

import { CloseOutlined } from '@ant-design/icons';
import {
    Flex,
    Table, Typography, Button, Select,
    Switch, Card, Input,
} from 'antd';
import {
    doc, getDoc, query, where,
} from 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';
import {
    Outlet, useNavigate,
} from 'react-router-dom';

import { useInputFilesQuery } from '@/firestore/api/inputFiles.ts';
import {
    AddNewReportModal,
    Period, PERIOD_SELECT_OPTIONS,
} from '@/pages/FilesPage/AddNewReportModal';

import { columns } from './FilesPage.constants.tsx';
import styles from './FilesPage.module.css';
import { ReportReviewTableItem } from './FilesPage.types.ts';
import {
    AuthData, AuthDataContext,
} from '../../components/containers/AuthContext';
import { ROUTES_CONFIG } from '../../constants/routes.ts';
import { clientCompanyRef } from '../../firestore/api/clientCompany.ts';
import { companyEntityRef } from '../../firestore/api/companyEntity.ts';
import {
    EntityType, ReportReview, reportReviewRef,
    ReportReviewStatus,
    reportReviewStatusName,
    reportTypeTitles, useReportReviewDeleteMutation, useReportReviewUpdateMutation,
} from '../../firestore/api/reportReview.ts';

/**
 * TODO: rename to ReportsOnReviewPage
 */
export const FilesPage = () => {
    const navigate = useNavigate()
    const authData = useContext<AuthData>(AuthDataContext)
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false)

    const [reportsTableDataSource, setReportsTableDataSource] = useState<ReportReviewTableItem[]>([])

    // Keep is as a listener to listen statuses udpates
    const [reportReviewSnapshot, reportReviewLoading] = useCollection<ReportReview>(
        query( reportReviewRef,
            where('companyId', '==', authData.company.id),
        ),
    )
    
    const inputFilesQuery = useInputFilesQuery({
        filters: [
            'companyId', '==', authData.company.id,
        ],
    })

    const allReportReviewItems = reportReviewSnapshot?.docs.map(el => ({
        ...el.data(),
        id: el.ref.id,
    })) || []

    // Add new filter states
    const [nameFilter, setNameFilter] = useState<string | null>('')
    const [clientFilter, setClientFilter] = useState<string | null>(null)
    const [entityFilter, setEntityFilter] = useState<string | null>(null)
    const [reviewStatusFilter, setReviewStatusFilter] = useState<ReportReviewStatus | null>(null)
    const [entityTypeFilter, setEntityTypeFilter] = useState<EntityType | null>(null)
    const [periodFilter, setPeriodFilter] = useState<Period | null>(null)
    const [reviewerFilter, setReviewerFilter] = useState<string | null>(null)
    const [preparerFilter, setPreparerFilter] = useState<string | null>(null)
    const [showArchived, setShowArchived] = useState(false);
    const [showAssignedToMe, setShowAssignedToMe] = useState(false);
    
    const [clientCompanySnap] = useCollection(query(clientCompanyRef, where('companyId', '==',authData.company.id)) )

    const [companyEntityItems, companyEntityLoading] = useCollection(
        query(companyEntityRef, where('companyId', '==', authData.company.id)),
    );
    
    const reportReviewDeleteMutation = useReportReviewDeleteMutation()
    const reportReviewUpdateMutation = useReportReviewUpdateMutation()

    useEffect(() => {
        if(!allReportReviewItems.length) return;

        const modifiedItems: ReportReviewTableItem[] = allReportReviewItems
            .sort((a, b) => {
                return (b.uploadedAt?.toDate?.().getTime() || 0) - (a.uploadedAt?.toDate?.().getTime() || 0)
            })
    
           ;(async () => {
            await Promise.all(
                modifiedItems.map(async (report) => {
                    const [
                        entity, 
                        company,
                    ] = await Promise.all([getDoc(doc(companyEntityRef, report.entityId)), getDoc(doc(clientCompanyRef, report.clientCompanyId))])

                    report.entity = entity.data()
                    report.company = company.data()
                    report.navigate = navigate
                    report.preparerData = authData.usersInCompany.find(user => user.uid === report.preparer) 
                    report.reviewerData = authData.usersInCompany.find(user => user.uid === report.reviewer) 
                }),
            )

            // TODO: Move this logic to firebase level and filter archieved items out by default
            const filteredReports = modifiedItems.filter((item) => {
                if (!showArchived && item.archived) return false;
                
                if (showAssignedToMe && item.preparer !== authData.user.uid && item.reviewer !== authData.user.uid) return false;

                if(nameFilter?.length && !item.name.toLowerCase().includes(nameFilter.toLowerCase())) return false;
                if (clientFilter && item.clientCompanyId !== clientFilter) return false;
                if (entityFilter && item.entityId !== entityFilter) return false;
                if (reviewStatusFilter) {
                    // In progress has two components which will be joined in filters
                    if(reviewStatusFilter === 'inProgress' && (item.reviewStatus !== 'inProgress' && item.reviewStatus !== 'stepsFinished')) return false
                    if( item.reviewStatus !== reviewStatusFilter) return false
                }
                if (entityTypeFilter && item.reportType !== entityTypeFilter) return false;
                if (periodFilter && item.period !== periodFilter) return false;
                if (reviewerFilter && item.reviewer !== reviewerFilter) return false;
                if (preparerFilter && item.preparer !== preparerFilter) return false;
                return true;
            });

            setReportsTableDataSource(filteredReports)
        })()
    }, [reportReviewSnapshot, clientFilter, entityFilter, reviewStatusFilter,
        entityTypeFilter, periodFilter, reviewerFilter, preparerFilter, showArchived, showAssignedToMe, nameFilter])

    const entityTypeOptions = Object.entries(reportTypeTitles)
        .filter(([type]) =>
            allReportReviewItems.some(report => report.reportType === type),
        )
        .map(([value, label]) => ({
            label,
            value,
        }))
    
    return (
        <>
            <Flex
                vertical
                gap={20}
                style={{
                    padding: 16,
                    height: '100%',
                }}
            >
                <AddNewReportModal/>
                <Flex
                    align='baseline'
                    justify='space-between'
                    gap={16}
                >
                    <Typography.Title level={4}>{ROUTES_CONFIG.REVIEW.title}</Typography.Title>
                </Flex>
                <Card
                    className={styles.card}
                    size='small'
                    styles={{ 
                        body: {
                            padding: 16,
                            display: 'flex',
                            gap: 16,
                            flexWrap: 'wrap', 
                            alignItems: 'center',
                        },
                    
                    }}
                >
                    <Input
                        style={{ width: 300 }}
                        value={nameFilter || ''}
                        onChange={(event) => setNameFilter(event.target.value)}
                        allowClear
                        placeholder='Report Name'
                    />
                    <Select
                        value={clientFilter}
                        allowClear={true}
                        onClear={() => setClientFilter(null)}
                        style={{ width: 200 }}
                        placeholder='Client'
                        options={clientCompanySnap?.docs?.map(doc => ({
                            label: doc.data().name,
                            value: doc.id,
                        })) || []}
                        onSelect={(value) => {
                            setClientFilter(value)
                            setEntityFilter(null) // Reset entity when client changes
                        }}
                    />
                    <Select
                        value={entityFilter}
                        allowClear={true}
                        onClear={() => setEntityFilter(null)}
                        style={{ width: 200 }}
                        placeholder='Entity'
                        options={companyEntityItems?.docs
                            .filter(entityDoc => 
                                allReportReviewItems.some(report => report.entityId === entityDoc.id) &&
                                (clientFilter ? entityDoc.data().clientCompanyId === clientFilter : true),
                            )
                            .map((doc) => ({
                                label: doc.data().name,
                                value: doc.id,
                            })) || []}
                        onSelect={(value) => setEntityFilter(value)}
                    />
                    <Select
                        value={reviewStatusFilter}
                        allowClear={true}
                        onClear={() => setReviewStatusFilter(null)}
                        style={{ width: 200 }}
                        placeholder='Review Status'
                        options={Object.entries(reportReviewStatusName).filter(([value]) => value !== 'stepsFinished').map(([value, label]) => ({
                            label,
                            value,
                        }))}
                        onSelect={(value) => setReviewStatusFilter(value)}
                    />
                    {entityTypeOptions.length > 1 && (
                        <Select
                            value={entityTypeFilter}
                            allowClear={true}
                            onClear={() => setEntityTypeFilter(null)}
                            style={{ width: 200 }}
                            placeholder='Entity Type'
                            options={entityTypeOptions}
                            onSelect={(value) => setEntityTypeFilter(value)}
                        />
                    )}
                    <Select
                        value={periodFilter}
                        allowClear={true}
                        onClear={() => setPeriodFilter(null)}
                        style={{ width: 200 }}
                        placeholder='Period'
                        options={PERIOD_SELECT_OPTIONS}
                        onSelect={(value) => setPeriodFilter(value)}
                    />
                    <Select
                        value={reviewerFilter}
                        allowClear={true}
                        onClear={() => setReviewerFilter(null)}
                        style={{ width: 200 }}
                        placeholder='Reviewer'
                        options={authData.usersInCompany.map((user) => ({
                            label: user.displayName,
                            value: user.uid,
                        }))}
                        onSelect={(value) => setReviewerFilter(value)}
                    />
                    <Select
                        value={preparerFilter}
                        allowClear={true}
                        onClear={() => setPreparerFilter(null)}
                        style={{ width: 200 }}
                        placeholder='Preparer'
                        options={authData.usersInCompany.map((user) => ({
                            label: user.displayName,
                            value: user.uid,
                        }))}
                        onSelect={(value) => setPreparerFilter(value)}
                    />
                    <Flex align='center' gap={8}>
                        <Switch
                            checked={showArchived}
                            onChange={(checked) => setShowArchived(checked)}
                        />
                        <Typography.Text>Show Archived</Typography.Text>
                    </Flex>
                    <Flex align='center' gap={8}>
                        <Switch
                            checked={showAssignedToMe}
                            onChange={(checked) => setShowAssignedToMe(checked)}
                        />
                        <Typography.Text>Assigned to Me</Typography.Text>
                    </Flex>
                    <Button
                        icon={<CloseOutlined/>}
                        onClick={() => {
                            setClientFilter(null)
                            setNameFilter('')
                            setEntityFilter(null)
                            setReviewStatusFilter(null)
                            setEntityTypeFilter(null)
                            setPeriodFilter(null)
                            setReviewerFilter(null)
                            setPreparerFilter(null)
                            setShowArchived(false)
                            setShowAssignedToMe(false)
                        }}
                    >
                        Reset
                    </Button>
                </Card>
                <Flex style={{ flexGrow: 1 }}>
                    <Table
                        loading={reportReviewLoading}
                        columns={columns(
                            navigate,
                            inputFilesQuery.data || [],
                            deleteConfirmationOpen,
                            setDeleteConfirmationOpen,
                            reportReviewDeleteMutation,
                            reportReviewUpdateMutation,
                        )}
                        dataSource={reportsTableDataSource}
                        bordered
                        scroll={{
                            scrollToFirstRowOnChange: true,
                            y: '100%',
                        }}
                    />
                </Flex>
            </Flex>
            <Outlet/>
        </>
    )
}
