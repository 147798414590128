import { WebViewerInstance } from '@pdftron/webviewer';

export interface PdfViewerProps {
}

export enum AnnotationVariant {
    moneyValue = 'moneyValue',
    // TODO: To sum component
    moneyValueActive = 'moneyValueActive',
    valueConfidence = 'valueConfidence',
    tableBorder = 'tableBorder',
    tocBorder = 'tocBorder',
    totalRow = 'totalRow',
    IcNavigatorActive = 'icNavigatorActive',
}

export interface AnnotationConfig {
    StrokeColor?: unknown
    FillColor?: unknown
    Opacity?: number
    StrokeThickness?: number
    BorderStyle?: 'dash' | 'solid'
}

export const getAnnotationConfigByVariant = (pdfInstance: WebViewerInstance): Record<AnnotationVariant, AnnotationConfig> => ({
    /**
     * Default extracted val style
     */
    [AnnotationVariant.moneyValue]: {
        StrokeColor: new pdfInstance.Core.Annotations.Color(2, 83, 155),
        Opacity: 0.4,
        StrokeThickness: 0.8,
        BorderStyle: 'dash',
        // Just to keep it clickable
        FillColor: new pdfInstance.Core.Annotations.Color(22, 117, 147, 0.05),
    },
    /**
     * Focused by snapID
     */
    [AnnotationVariant.moneyValueActive]: {
        StrokeColor: new pdfInstance.Core.Annotations.Color(2, 83, 155),
        Opacity: 0.7,
        StrokeThickness: 2,
        BorderStyle: 'solid',
    },
    /**
     * Style for IC navigator
     */
    [AnnotationVariant.IcNavigatorActive]: {
        StrokeColor: new pdfInstance.Core.Annotations.Color(2, 83, 155),
        Opacity: 0.7,
        StrokeThickness: 2,
        BorderStyle: 'solid',
    },
    [AnnotationVariant.tocBorder]: {
        StrokeColor: new pdfInstance.Core.Annotations.Color(3, 252, 119),
        Opacity: 0.8,
        StrokeThickness: 1,
        BorderStyle: 'solid',
    },
    [AnnotationVariant.valueConfidence]: {
        Opacity: 1,
        StrokeThickness: 0,
    },
    [AnnotationVariant.tableBorder]: {
        StrokeColor: new pdfInstance.Core.Annotations.Color(235, 94, 52),
        Opacity: 0.3,
        StrokeThickness: 2,
        BorderStyle: 'solid',
    },
    [AnnotationVariant.totalRow]: {
        StrokeColor: new pdfInstance.Core.Annotations.Color(51, 204, 255),
        FillColor: new pdfInstance.Core.Annotations.Color(51, 204, 255),
        Opacity: 0.2,
        StrokeThickness: 2,
        BorderStyle: 'solid',
    },
})
