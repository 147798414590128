import { PresetColorType } from 'antd/es/_util/colors';
import { collection } from 'firebase/firestore';

import { getRequestQueries } from '@/api/utils/getRequestQueries';
import { COLORS } from '@/constants/colors.ts';
import {
    REPORT_EXTRACTED_VALUE_LABLEL_BY_TYPE, ReportExtractedValues,
    ReportExtractedValueType,
} from '@/firestore/api/reportExtractedValues.ts';

import { db } from '../config.ts';

export type ValueValidationConfidence =
    'valid' |
    'invalid' |
    /**
     * Verification of automated check without confidence set
     */
    'verificationRequired' |
    'notApplicable'

export const valueValidationType = ['py' , 'ic', 'ma', 'ec' ,'other']
type ValueValidationType = typeof valueValidationType[number];

/**
 * Items which will be looking fine in the context of toolitp
 */
const labelTypesCompatibleWithHint: ReportExtractedValueType[] = [
    'directorName',
    'address',
    'companyName',
    'TOC',
]

const notApplicableMessage = 'If not applicable, press the "X" button.'

export const HINT_MESSAGE_BY_VALUE_VALIDATION_TYPE: Partial<Record<ValueValidationType, (type?: ReportExtractedValueType) => string>> = {
    py: (type?: ReportExtractedValueType, confidence?: ValueValidationConfidence, auto: boolean) => {
        const label = REPORT_EXTRACTED_VALUE_LABLEL_BY_TYPE[type];

        if(!label || !labelTypesCompatibleWithHint.includes(type as ReportExtractedValueType)) return `Prior Year Consistency — Ensures this element is consistent with the prior year. ${notApplicableMessage}`

        return `Prior Year Consistency — Ensures that the ${label.toLowerCase()} is consistent with the prior year. ${notApplicableMessage}`
    },
    ic: (type?: ReportExtractedValueType) => {
        const label = REPORT_EXTRACTED_VALUE_LABLEL_BY_TYPE[type];

        if(!label || !labelTypesCompatibleWithHint.includes(type as ReportExtractedValueType)) return `Internal Consistency — Ensures this element is internally consistent. ${notApplicableMessage}`

        return `Internal Consistency — Ensures that the ${label.toLowerCase()} is internally consistent. ${notApplicableMessage}`
    },
    ma: () => `Mathematical Accuracy — Ensures this value is mathematically accurate. ${notApplicableMessage}`,
    ec: (type?: ReportExtractedValueType) => {
        const label = REPORT_EXTRACTED_VALUE_LABLEL_BY_TYPE[type];

        if(!label || !labelTypesCompatibleWithHint.includes(type as ReportExtractedValueType)) return `External Consistency — Ensures this element is externally consistent. ${notApplicableMessage}`

        return `External Consistency — Ensures that the ${label.toLowerCase()} is externally consistent. ${notApplicableMessage}`
    },
}

export const getAIValidationHint = ({
    conf,
    validationType,
    valueType,
    extractedValue,
}: {
    conf: ValueValidationConfidence
    validationType: ValueValidationType,
    valueType: ReportExtractedValueType
    extractedValue: ReportExtractedValues
}) => {

    const genericSuccessMessage = `${validationType.toUpperCase()} successfully verified. `
    
    if(conf === 'valid' && valueType === 'TOC' && validationType === 'ic' && extractedValue?.originalValue) {
        return genericSuccessMessage + `The title "${extractedValue.originalValue}" was found on the specified page.`
    }

    if(conf === 'valid' && valueType === 'money' && validationType === 'ma' && extractedValue?.normalizedValue) {
        return genericSuccessMessage + `The sum of identified components is equal to ${extractedValue.originalValue}.`
    }

    if(conf === 'invalid' && valueType === 'money' && validationType === 'ma' && extractedValue?.normalizedValue) {
        return genericSuccessMessage + `The sum of identified components is not equal to ${extractedValue.originalValue}.`
    }

    if(conf === 'valid' && valueType === 'money' && validationType === 'py' && extractedValue?.normalizedValue) {
        return genericSuccessMessage + `The value "${extractedValue.originalValue}" matches the corresponding value in the prior year report.`
    }

    if(conf === 'invalid' && valueType === 'money' && validationType === 'py' && extractedValue?.normalizedValue) {
        return genericSuccessMessage + `The value "${extractedValue.originalValue}" does not match the corresponding value in the prior year report.`
    }
    
    if(conf === 'verificationRequired' && valueType === 'money' && validationType === 'ic' && extractedValue?.originalValue) {
        return 'A set of potentially related numbers has been detected, requiring manual approval.'
    }
    
    if(conf === 'invalid' && validationType === 'ic' && extractedValue?.originalValue) {
        return `The value "${extractedValue.originalValue}" does not align with the other related items.`
    }

    if(conf === 'valid') {
        return genericSuccessMessage
    } else if (conf === 'invalid') {
        return `${validationType.toUpperCase()} verification identified errors. Please check the details in the suggested comments.`
    } else if (conf === 'verificationRequired') {
        return 'A set of potentially related element has been detected, requiring manual approval.'
    }

    return null
}

export const VALUE_VALIDATION_COLOR: Record<ValueValidationConfidence, string> = {
    valid: COLORS.green,
    invalid: COLORS.red,
    verificationRequired: COLORS.orange,
    notApplicable: '#a8a8a8',
}

export const TAG_COLOR_BY_CONF_COLOR: Record<ValueValidationConfidence, PresetColorType> = {
    valid: 'green',
    invalid: 'red',
    verificationRequired: 'orange',
    notApplicable: undefined,
}

export const valueValidationTypes: ValueValidationType[] = ['ic', 'ma','py', 'ec', 'other']

export const VALIDATION_BY_VALUE_TYPE: Partial<Record<ReportExtractedValueType, ValueValidationType[]>> = {
    text: valueValidationTypes.filter(el => el !== 'ma'),
    TOC: valueValidationTypes.filter(el => el === 'ic'),
    directorName: valueValidationTypes.filter(el => el !== 'ma'),
    address: valueValidationTypes.filter(el => el !== 'ma'),
    companyName: valueValidationTypes.filter(el => el !== 'ma'),
    pageNumber: valueValidationTypes.filter(el => el === 'ic'),
}

export type ValueValidationObj = Partial<Record<ValueValidationType, ValueValidationConfidence>>

export interface ValueValidation {
    id: string
    companyId: string;
    reportId: string;
    entityId: string;

    /**
     * Related to "reportExtractedValues" collection
     */
    extractedValueId: string;

    manual?: ValueValidationObj
    auto?: ValueValidationObj
}

export const VALUE_VALIDATION_COLLECTION = 'valueValidation'

export const valueValidationRef = collection(db, VALUE_VALIDATION_COLLECTION)

export const {
    useFBItemsQuery: useValueValidationQuery,
    useFBItemByIdQuery: useValueValidationByIdQuery,
    useFBItemCreateMutation: useValueValidationCreateMutation,
    useFBItemUpdateMutation: useValueValidationUpdateMutation,
} = getRequestQueries<ValueValidation, {}>({ url: VALUE_VALIDATION_COLLECTION })

/**
 * Gets combined confidence for a specific validation type considering both manual and auto
 * Manual validation takes precedence for the same type
 */
const getCombinedTypeConfidence = (
    manual?: ValueValidationConfidence,
    auto?: ValueValidationConfidence
): ValueValidationConfidence | undefined => {
    if (manual) return manual;
    if (auto) return auto;
    return undefined;
}

/**
 * Gets the overall confidence status for a ValueValidation entity
 * Combines manual and auto validations for each type
 */
export const getOverallValidationConfidence = (
    validation?: ValueValidation
): ValueValidationConfidence | undefined => {
    if (!validation) return undefined;
    
    const { manual = {}, auto = {} } = validation;
    
    // Combine confidences for each validation type
    const combinedConfidences = valueValidationTypes.reduce<Record<ValueValidationType, ValueValidationConfidence | undefined>>((acc, type) => {
        acc[type] = getCombinedTypeConfidence(manual[type], auto[type]);
        return acc;
    }, {} as Record<ValueValidationType, ValueValidationConfidence | undefined>);
    
    const confidences = Object.values(combinedConfidences).filter(Boolean);
    if (confidences.length === 0) return undefined;
    
    // Apply validation rules
    if (confidences.includes('invalid')) return 'invalid';
    if (confidences.includes('verificationRequired')) return 'verificationRequired';
    if (confidences.includes('valid')) return 'valid';
    
    return undefined;
}
