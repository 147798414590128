import React from 'react'

import {
    CheckOutlined, ClockCircleOutlined, DownOutlined,
} from '@ant-design/icons';
import {
    Button, ConfigProvider,
    Dropdown, Flex, Tag,
} from 'antd';
import { message } from 'antd/lib';
import { useParams } from 'react-router-dom';

import { COLORS } from '@/constants/colors.ts';
import {
    ReportReviewStatus,
    reportReviewStatusColor,
    reportReviewStatusFullName,
    reportReviewStatusName, REVIEW_STATUS_ACTION_TEXT,
    useReportReviewByIdQuery,
    useReportReviewUpdateMutation,
} from '@/firestore/api/reportReview.ts';
import { useNavItemsState } from '@/pages/ReviewPage/GuidePanel/GuidePanel.hooks.ts';

import { ReviewStatusTagProps } from './ReviewStatusTag.types'

export const ReviewStatusTag = (props: ReviewStatusTagProps) => {
    const {
        data,
        butonMode = false, 
    } = props
    
    const { id: docId } = useParams()

    const reportReviewByIdQuery = useReportReviewByIdQuery(docId, { enabled: !!docId && !data })
    const reportReviewUpdateMutation = useReportReviewUpdateMutation()

    const reportData = data || reportReviewByIdQuery.data
    
    const [, setSideBarState] = useNavItemsState()

    const handleClick = async (value: ReportReviewStatus) => {
        if(!value || !reportData?.id) {
            console.error('Can\'t change the status of the report')
            return
        }
        
        const key = value.key as ReportReviewStatus
        
        await reportReviewUpdateMutation.mutateAsync({
            id: reportData.id,
            data: { reviewStatus: key },
        })

        // If report open - move to step guide's success message
        if(key === 'finished' && docId ) {
            setSideBarState('guidedReview')
        }
        
        message.success('Status Updated')
    }

    if(!reportData) {
        return null
    }

    const isFinished = reportData.reviewStatus === 'finished'

    return (
        <Dropdown
            menu={{
                items: [ {
                    label: REVIEW_STATUS_ACTION_TEXT.inProgress,
                    key: 'inProgress',
                },
                {
                    label: REVIEW_STATUS_ACTION_TEXT.finished,
                    key: 'finished',
                }].filter(el => el.key !== reportData.reviewStatus),
                onClick: handleClick,
            }}
            placement='bottomRight'
            trigger={['click']}
        >
            {butonMode ? (
                <Flex >
                    <ConfigProvider
                        theme={{ token: { colorPrimary: COLORS.green } }}
                    >
                        <Button 
                            size='middle'
                            color={!isFinished ? 'default' : 'success'}
                            type={!isFinished ? 'default' : 'primary'}
                        >
                            {butonMode ? reportReviewStatusFullName[reportData.reviewStatus] : reportReviewStatusName[reportData.reviewStatus]}
                            <DownOutlined/>
                        </Button>
                    </ConfigProvider>
                </Flex>
            ) : (
                <Tag color={reportReviewStatusColor[reportData.reviewStatus]} icon={!isFinished ? <ClockCircleOutlined/> : <CheckOutlined/>} style={{ cursor: 'pointer' }}>
                    {butonMode ? reportReviewStatusFullName[reportData.reviewStatus] : reportReviewStatusName[reportData.reviewStatus]}
                </Tag>
            )}
        </Dropdown>
    )
}
