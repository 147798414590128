import React, {
    useContext, useState, 
} from 'react'

import { addDoc } from '@firebase/firestore';
import {
    DatePicker, Form, FormItemProps, Modal, Radio, Segmented, Select,
} from 'antd';
import { RcFile } from 'antd/es/upload/interface';
import { message } from 'antd/lib';
import {
    query, Timestamp, where,
} from 'firebase/firestore';
import { ref as storageRef } from 'firebase/storage';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { useCollection } from 'react-firebase-hooks/firestore';
import { useUploadFile } from 'react-firebase-hooks/storage';
import { useNavigate } from 'react-router-dom';

import { FileDragger } from '@/components/base/FileDragger/FileDragger';
import {
    AuthData, AuthDataContext, 
} from '@/components/containers/AuthContext';
import { clientCompanyRef } from '@/firestore/api/clientCompany.ts';
import { companyEntityRef } from '@/firestore/api/companyEntity.ts';
import { storage } from '@/firestore/config.ts';
import {
    FORM_ITEMS, Period, 
} from '@/pages/FilesPage/AddNewReportModal';

import { RegRepNewModalProps } from './RegRepNewModal.types'
import {
    S213Format, s213FormatOptions, 
} from './S213Format';
import { CUSTOM_MODAL_WIDTH } from '../../../constants/modalWidth.ts';
import { ROUTES_CONFIG_REG_REP } from '../../../constants/routes.ts';
import {
    REG_REP_FORM_ITEMS,
    RegRepProcess,
    regRepProcessRef,
    RegRepProcessStatus,
    regRepSectorOptions,
    RegRepSectors,
    UploadType,
} from '../../../firestore/api/regRepProcess.ts';

const PERIOD_SELECT_OPTIONS: Array<{value: Period, label: string}> = [
    {
        value: 'quarterly',
        label: 'Quarterly', 
    },
    {
        value: 'monthly',
        label: 'Monthly', 
    },
]

export const RegRepNewModal = (props: RegRepNewModalProps) => {
    const [form] = Form.useForm();
    const authData = useContext<AuthData>(AuthDataContext)
    const [uploadFile, uploading] = useUploadFile();
    const navigate = useNavigate()
    
    const [s213Type, setS213Type] = useState<S213Format>(S213Format.generic)
    
    const [companyEntityItems, companyEntityLoading] = useCollection(query(companyEntityRef, where('companyId', '==' , authData.company.id)));
    const [clientCompanyItems, clientCompanyLoading] = useCollection(query(clientCompanyRef, where('companyId', '==' , authData.company.id)));

    const [acitveCompanyId, setActiveCompanyId] = useState<string>()

    // Add new state to track file uploads
    const [uploadingFiles, setUploadingFiles] = useState<Record<string, boolean>>({});
    
    // Check if any file is currently uploading
    const isAnyFileUploading = Object.values(uploadingFiles).some(Boolean);

    const handleFormSubmit = async (data: Partial<RegRepProcess>) => {
        const sector = data.sector || 'investmentFunds'

        let status: RegRepProcessStatus = 'initial'

        // Legacy code
        if(sector === 'investmentFunds' || sector === 'financialCompanies') {
            if(s213Type === 'bob50' ) {
                status = 'preparingS213'
            } else {
                status = 'processing'
            }
        } else {
            // New code
            status = 'initial'
        }

        try {
            // Create initial object with required fields
            const newDocPayload: Partial<RegRepProcess> = {
                clientCompanyId: data.clientCompanyId,
                entityId: data.entityId,

                // period: data.period,
                s213format: s213Type,

                status: status,
                validationStatus: 'processing',
                userId: authData.user.uid,
                createdBy: authData.user.uid,
                companyId: authData.company.id,

                uploadedAt: Timestamp.now(),
                createdAt: Timestamp.now(),
                updatedAt: Timestamp.now(),
                
                retriggeredAt: Timestamp.now(),
                
                sector,
            }

            if(data.uploadType) {
                newDocPayload.uploadType = data.uploadType as UploadType
            }
            
            // Add report month if selected
            if (data.reportMonth) {
                newDocPayload.reportMonth = Timestamp.fromDate(data.reportMonth.toDate());
            }
            
            // Handle file paths from form data
            const processFileField = (fieldName: string) => {
                if (data[fieldName]?.file?.response?.metadata?.fullPath) {
                    (newDocPayload as any)[fieldName] = data[fieldName].file.response.metadata.fullPath;
                }
            };

            // Process standard file fields
            processFileField('fileTPTOBS');
            processFileField('fileS213bob50');
            processFileField('fileS213Bob50Mapping');
            processFileField('fileU11');
            processFileField('fileS213');
            processFileField('fileS214');
            processFileField('fileTPTTBS');
            processFileField('fileS216');
            processFileField('fileTPTIBS');

            // Process dynamic file fields for additional formats
            if (s213Type !== 'generic' && s213Type !== 'bob50') {
                const dynamicFieldName = `fileS213${s213Type}`;
                processFileField(dynamicFieldName);
            }

            await addDoc(regRepProcessRef, newDocPayload as RegRepProcess)
        } catch (error) {
            console.error(error)
            message.error('Failed to create new report')
        }
    }

    const handleOk = () => {
        form
            .validateFields()
            .then(async (values) => {
                try {
                    await handleFormSubmit(values)
                    form.resetFields();
                    navigate(ROUTES_CONFIG_REG_REP.REG_REP.path)
                } catch (error) {
                    // Error already handled in handleFormSubmit
                    console.error('Form submission failed:', error);
                }
            })
            .catch((info) => {
                console.error('Form validation error:', info)
                message.error('Please fill in all required fields before submitting')
            })
    }
    
    const draggerCustomRequest = async (formItemProps: FormItemProps, data: UploadRequestOption) => {
        // Set uploading state for this specific file
        setUploadingFiles(prev => ({
            ...prev,
            [formItemProps.name as string]: true,
        }));

        data.onProgress?.({ percent: 0 })

        try {
            const file = data.file as RcFile
            const filePath = `reg-rep/companies/${authData.company.id}/${formItemProps.name}/${file.uid}`
            const res = await uploadFile(storageRef(storage, filePath), file)

            if(!res) {
                throw new Error('File upload failed')
            }

            if (data.onProgress) {
                data.onProgress({ percent: 100 })
            }
            if (data.onSuccess) {
                data.onSuccess(res);
            }
        } catch (error) {
            console.error('File upload error:', error);
            message.error(`File upload failed: ${(error as Error).message || 'Unknown error'}`);
            
            if (data.onError) {
                data.onError(error as Error);
            }
        } finally {
            // Reset uploading state for this file
            setUploadingFiles(prev => ({
                ...prev,
                [formItemProps.name as string]: false,
            }));
        }
    }
    
    const handleCancel = () => {
        form.resetFields();
        navigate(ROUTES_CONFIG_REG_REP.REG_REP.path)
    }
    
    const [sector, setSector] = useState<RegRepSectors>('investmentFunds');
    
    return (
        <Modal
            open
            confirmLoading={isAnyFileUploading}
            title={ROUTES_CONFIG_REG_REP.REG_REP_NEW.title}
            centered
            onOk={handleOk}
            onCancel={handleCancel}
            width={CUSTOM_MODAL_WIDTH.medium}
            okText='Upload'
            styles={{
                body: { overflowY: 'auto' },
                content: {
                    overflowY: 'hidden',
                    maxHeight: '90vh',
                    display: 'flex',
                    flexDirection: 'column',
                },
            }}
        >
            <Form
                form={form}
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
                onFinish={handleFormSubmit}
                autoComplete='off'
                scrollToFirstError={true}
                layout='vertical'
            >                
                <Form.Item
                    {...FORM_ITEMS.sector}
                >
                    <Segmented<RegRepSectors>
                        block
                        onChange={setSector}
                        options={regRepSectorOptions}
                        defaultValue={sector}
                    />
                </Form.Item>
                
                <Form.Item
                    label='Reporting Month'
                    name='reportMonth'
                    rules={[{
                        required: true,
                        message: 'Please select reporting month', 
                    }]}
                >
                    <DatePicker 
                        picker='month' 
                        style={{ width: '100%' }} 
                        placeholder='Select reporting month'
                    />
                </Form.Item>
                
                <Form.Item
                    {...FORM_ITEMS.clientCompanyId}
                >
                    <Select
                        defaultActiveFirstOption
                        placeholder={FORM_ITEMS.clientCompanyId.label}
                        loading={clientCompanyLoading}
                        onSelect={(id) => {
                            setActiveCompanyId(id)
                        }}
                    >
                        {clientCompanyItems?.docs.map((item) => (
                            <Select.Option key={item.id} value={item.id}>{item.data().name}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    {...FORM_ITEMS.entityId}
                >
                    <Select
                        defaultActiveFirstOption
                        placeholder={FORM_ITEMS.entityId.label}
                        loading={companyEntityLoading}
                    >
                        {companyEntityItems?.docs
                            .filter((item) => item.data().clientCompanyId === acitveCompanyId)
                            .map((item) => (
                            // FEXME: Can be used .ref.path instead of id
                                <Select.Option key={item.id} value={item.id}>{item.data().name}</Select.Option>
                            ))}
                    </Select>
                </Form.Item>   
                {/*<Form.Item*/}
                {/*    {...FORM_ITEMS.uploadType}*/}
                {/*>*/}
                {/*    <Select*/}
                {/*        defaultActiveFirstOption*/}
                {/*        placeholder={FORM_ITEMS.uploadType.label}*/}
                {/*    >*/}
                {/*        {Object.keys(UploadType)?.map((item) => (*/}
                {/*            <Select.Option key={item} value={item}>{firstLetterToUpperCase(item)}</Select.Option>*/}
                {/*        ))}*/}
                {/*    </Select>*/}
                {/*</Form.Item>*/}
                
                {sector === 'investmentFunds' && (
                    <>
                        <Form.Item
                            {...REG_REP_FORM_ITEMS.fileU11}
                        >
                            <FileDragger
                                customRequest={(options: UploadRequestOption) => draggerCustomRequest(REG_REP_FORM_ITEMS.fileU11, options)}
                                formats={['xlsx']}
                            />
                        </Form.Item>
                        <Form.Item
                            {...REG_REP_FORM_ITEMS.fileTPTOBS}
                        >
                            <FileDragger
                                customRequest={(options: UploadRequestOption) => draggerCustomRequest(REG_REP_FORM_ITEMS.fileTPTOBS, options)}
                                formats={['xlsx']}
                            />
                        </Form.Item>
                        <Form.Item
                            {...REG_REP_FORM_ITEMS.s213format}
                        >
                            <Radio.Group 
                                value={s213Type} 
                                onChange={(e) => setS213Type(e.target.value)} 
                                style={{ width: '100%' }} 
                                defaultValue={S213Format.generic}
                            >
                                {s213FormatOptions.map(option => (
                                    <Radio.Button key={option.value} value={option.value}>{option.label}</Radio.Button>
                                ))}
                            </Radio.Group>
                        </Form.Item>
                        {s213Type === S213Format.generic ? (
                            <Form.Item
                                {...REG_REP_FORM_ITEMS.fileS213}
                            >
                                <FileDragger
                                    customRequest={(options: UploadRequestOption) => draggerCustomRequest(REG_REP_FORM_ITEMS.fileS213, options)}
                                    formats={['xlsx']}
                                />
                            </Form.Item>
                        ) : s213Type === S213Format.bob50 ? (
                            <>
                                <Form.Item
                                    {...REG_REP_FORM_ITEMS.fileS213bob50}
                                >
                                    <FileDragger
                                        customRequest={(options: UploadRequestOption) => draggerCustomRequest(REG_REP_FORM_ITEMS.fileS213bob50, options)}
                                        formats={['xlsx']}
                                    />
                                </Form.Item>    
                                <Form.Item
                                    {...REG_REP_FORM_ITEMS.fileS213Bob50Mapping}
                                >
                                    <FileDragger
                                        customRequest={(options: UploadRequestOption) => draggerCustomRequest(REG_REP_FORM_ITEMS.fileS213Bob50Mapping, options)}
                                        formats={['xlsx']}
                                    />
                                </Form.Item>
                            </>
                        ) : (
                            // For other formats, show a generic uploader
                            <Form.Item
                                label={`File for ${s213Type} format`}
                                name={`fileS213${s213Type}`}
                            >
                                <FileDragger
                                    customRequest={(options: UploadRequestOption) => 
                                        draggerCustomRequest({ name: `fileS213${s213Type}` }, options)}
                                    formats={['xlsx']}
                                />
                            </Form.Item>
                        )}
                    </>
                )}
                {sector === 'securitisationVehicles' && (
                    <>
                        <Form.Item
                            {...REG_REP_FORM_ITEMS.fileS214}
                        >
                            <FileDragger
                                customRequest={(options: UploadRequestOption) => draggerCustomRequest(REG_REP_FORM_ITEMS.fileS214, options)}
                                formats={['xlsx']}
                            />
                        </Form.Item>
                        <Form.Item
                            {...REG_REP_FORM_ITEMS.fileTPTTBS}
                        >
                            <FileDragger
                                customRequest={(options: UploadRequestOption) => draggerCustomRequest(REG_REP_FORM_ITEMS.fileTPTTBS, options)}
                                formats={['xlsx']}
                            />
                        </Form.Item>
                    </>
                )}
                {sector === 'financialCompanies' && (
                    <>
                        <Form.Item
                            {...REG_REP_FORM_ITEMS.fileS216}
                        >
                            <FileDragger
                                customRequest={(options: UploadRequestOption) => draggerCustomRequest(REG_REP_FORM_ITEMS.fileS216, options)}
                                formats={['xlsx']}
                            />
                        </Form.Item>
                        <Form.Item
                            {...REG_REP_FORM_ITEMS.fileTPTIBS}
                        >
                            <FileDragger
                                customRequest={(options: UploadRequestOption) => draggerCustomRequest(REG_REP_FORM_ITEMS.fileTPTIBS, options)}
                                formats={['xlsx']}
                            />
                        </Form.Item>
                    </>
                )}
            </Form>
        </Modal>
    )
}
