import { useContext } from 'react';

import {
    Core, WebViewerInstance,
} from '@pdftron/webviewer';
import { useQueryParam } from 'use-query-params';

import { PdfViewerContext2 } from '@/App.tsx';
import { AuthData } from '@/components/containers/AuthContext';
import { QUERY_PARAMS_CONFIG } from '@/config/queryParams.ts';
import { CustomDataKey } from '@/constants/pdfViewer/customDataKey.ts';
import { ReportExtractedValues } from '@/firestore/api/reportExtractedValues.ts';
import { ReviewIdentifiedBlockConfidence } from '@/firestore/api/reviewIdentifiedBlock.ts';
import {
    VALUE_VALIDATION_COLOR,
    ValueValidation,
    ValueValidationConfidence,
    valueValidationTypes,
} from '@/firestore/api/valueValidation.ts';
import { useLocalStorage } from '@/hooks/useLocalStorage.ts';
import { useViewerDocument } from '@/hooks/useViewerDocument.ts';
import { identifiedBlockColorByConfidence } from '@/pages/ReviewPage';
import { EXPORT_STORAGE_KEYS } from '@/pages/ReviewPage/GuidePanel/SuccessReview/SuccessReview.tsx';
import { addCommentForAnnotation } from '@/utils/pdfViewer/addAnnotationWithComment.ts';
import {
    CreateAnnotations, useCreateAnnotations,
} from '@/utils/pdfViewer/createAnnotations.ts';
import {
    CustomToolNames, getConf,
} from '@/widgets/PdfViewer/PdfViewer.tsx';
import {
    AnnotationConfig,
    AnnotationVariant,
    getAnnotationConfigByVariant,
} from '@/widgets/PdfViewer/PdfViewer.types.ts';
import { getQueryParam } from '@/widgets/PdfViewer/useAnnotationsListener.ts';
import { stylesByCutsomTool } from '@/widgets/PdfViewer/useToolsListener.ts';

import Annotation = Core.Annotations.Annotation;

type AnnotationType = 'Rectangle' | 'FreeText'

export const createAnnotation = async ({
    pdfInstance,
    createAnnotations,
    type,
    pageIndex,
    coordinates,
    toolName,
    annotationManager,
    customData,
    relatedSnapshotId,
    showInNotesPanel = true,
    readOnly = false,
    reply,
    annotationVariant,
    padding = 0,
    annotationsList,
    annotationType = 'Rectangle',
    autosize = false,
    fontSize,
    textContent,
    annotationBase,
    richTextStyle,
    snapshotRerenderKey,
    excludeFromSummary,
    commentTitle,
    doNotCreate = false,
    skipEventHandlers = false,
    hidden = false,
    author,
    includeInExport = false,
    allowRemoveEmpty = false,
}: {
    author?: string
    createAnnotations: CreateAnnotations
    pdfInstance: WebViewerInstance,
    type: ReviewIdentifiedBlockConfidence,
    pageIndex: number,
    coordinates: [number, number, number, number],
    toolName: CustomToolNames,
    /**
     * Can be used instead of toolName for styling (when there is not manual toold for that
     */
    annotationVariant?: AnnotationVariant,
    annotationManager: Core.AnnotationManager
    customData?: Record<Partial<CustomDataKey>, string>
    relatedSnapshotId?: string
    showInNotesPanel?: boolean
    readOnly?: boolean
    autosize?: boolean
    annotationType?: AnnotationType,
    reply?: string
    fontSize?: string,
    hidden?: boolean,
    /**
     * Optional optimization
     */
    annotationsList?: Annotations.Annotation[]
    padding?: number
    textContent?: string,
    /**
     * If on this coordinates already applied annotation with same style - skip it
     * In case if there is no snaphot or any other id
     *
     * TODO: Implement
     */
    // skipAppliedByCoordinates?: boolean
    annotationBase?: Core.Annotations.Annotation,
    /**
     * if updated and same snapshotId annotations should be rerendered
     */
    snapshotRerenderKey?: string,
    commentTitle?: string,
    excludeFromSummary: boolean
    /**
     * Text style by index
     */
    richTextStyle: Record<number, {
        'font-style'?: 'normal' | 'italic';
        'font-weight'?: 'normal' | 'bold';
        /**
         * text-decoration: 'word' is equivalent to text-decoration: 'underline'. Values of text-decorations can also be joined by a space. For example, text-decoration: 'word line-through'
         */
        'text-decoration'?: 'word' | 'line-through' | 'underline' | string;
        'font-family'?: string;
        'font-size'?: string;
        /**
         * color in RGB hex format
         */
        color?: string;
    }>
    /**
     * Skip creation, only return obj
     */
    doNotCreate?: boolean
    skipEventHandlers?: boolean
    /**
     * Annotation will be added to the exported pdf
     */
    includeInExport?: boolean

    allowRemoveEmpty?: boolean
}): Promise<Core.Annotations.Annotation | void> => {
    if (!doNotCreate && !createAnnotations) return

    if (reply && doNotCreate) {
        console.error('Reply is not comatible with doNotCreate flag. Comment:', reply)
    }

    try {
        const allannotations = annotationsList || annotationManager.getAnnotationsList()
        // We need to create manu links for the same block
        const snapshotId = relatedSnapshotId

        const prev = snapshotId?.length ? allannotations.find((annotation) => {
            const customDataSnapShotId = annotation.getCustomData('relatedSnapshotId')
            return customDataSnapShotId === snapshotId
        }) : undefined

        const prevNeedRerender = prev?.getCustomData(CustomDataKey.temporary) === 'true'

        const isThisSnapshotAlreadyAnnotated = Boolean(prev);

        // FIXME: Make ib based on relatedSnapshotId + annotationIndex (less custom staff)
        const relatedLinkSnapshotId = customData?.relatedLinkSnapshotId
        const linkIndex = customData?.linkIndex

        // FIXME: Migrate to 'snapshotRerenderKey' field
        // Different logic for links
        const previouslyCreatedLink = (relatedLinkSnapshotId?.length && linkIndex?.length) ? allannotations.find((annotation) => {
            const customDataSnapShotId = annotation.getCustomData('relatedLinkSnapshotId')
            const customDataLinkIndex = annotation.getCustomData('linkIndex')
            return customDataSnapShotId === relatedLinkSnapshotId && customDataLinkIndex === linkIndex
        }) : false

        // If key changed, but stap id the same
        const rerenderRequired =
            isThisSnapshotAlreadyAnnotated &&
            (
                // Rerender based on rerender key
                (
                    snapshotRerenderKey?.length &&
                    prev?.getCustomData(CustomDataKey.snapshotRerenderKey) !== snapshotRerenderKey
                )
                // Rerender requested in state
                || prevNeedRerender
            )

        if (
            isThisSnapshotAlreadyAnnotated &&
            !rerenderRequired
        ) {
            console.log('SKIP: already annotated')
            return
        }

        const annotClass: Record<AnnotationType, typeof pdfInstance.Core.Annotations.RectangleAnnotation | typeof pdfInstance.Core.Annotations.FreeTextAnnotation> = {
            'Rectangle': pdfInstance.Core.Annotations.RectangleAnnotation,
            'FreeText': pdfInstance.Core.Annotations.FreeTextAnnotation,
        }

        const newAnnotationObj = rerenderRequired ? prev : (annotationBase || new annotClass[annotationType]())

        if (!newAnnotationObj) {
            console.error('SKIP: Annotation type not supported', annotationType)
            return
        }

        // Delete empty if exists
        // if(allowRemoveEmpty && textContent === '' && prev) {
        //     annotationManager.deleteAnnotation(prev)
        //     return
        // }

        if (textContent?.length || textContent === '') {
            newAnnotationObj.setContents(textContent);

            if (richTextStyle) {
                newAnnotationObj.setRichTextStyle(richTextStyle)
            }
        }

        const toolStyles = stylesByCutsomTool(pdfInstance)[toolName]
        const annotationVariantStyles: AnnotationConfig = annotationVariant ? getAnnotationConfigByVariant(pdfInstance)[annotationVariant] : {}

        const colorByConf = identifiedBlockColorByConfidence(pdfInstance)[type]
        const colorByAnnotVariant = annotationVariantStyles?.FillColor
        const fillColor = colorByConf || colorByAnnotVariant

        const borderStyle = annotationVariantStyles?.BorderStyle
        const strokeThinckness = annotationVariantStyles?.StrokeThickness ?? toolStyles?.StrokeThickness
        const opacity = annotationVariantStyles?.Opacity ?? toolStyles?.Opacity
        const strokeColor = annotationVariantStyles?.StrokeColor || colorByConf;
        const title = author || toolName || commentTitle

        newAnnotationObj.PageNumber = pageIndex + 1;
        newAnnotationObj.X = coordinates[0] - padding;
        newAnnotationObj.Y = coordinates[1] - padding;

        // newAnnotationObj.setPadding(new pdfInstance.Core.PDFNet.Rect(padding, padding, padding, padding));

        if (coordinates[2]) {
            newAnnotationObj.Width = coordinates[2] + padding * 2;
        }
        if (coordinates[3]) {
            newAnnotationObj.Height = coordinates[3] + padding * 2;
        }
        if (strokeColor) {
            newAnnotationObj.StrokeColor = strokeColor
        }
        if (fillColor) {
            newAnnotationObj.FillColor = fillColor;
        }
        if (opacity) {
            newAnnotationObj.Opacity = opacity;
        }
        if (strokeThinckness === 0 || strokeThinckness) {
            newAnnotationObj.StrokeThickness = strokeThinckness;
        }
        newAnnotationObj.IsHoverable = true;
        if (toolName) {
            newAnnotationObj.ToolName = toolName;
        }
        newAnnotationObj.NoDelete = false; //
        newAnnotationObj.NoMove = true;
        newAnnotationObj.NoResize = true;
        if (title) {
            newAnnotationObj.Author = title
        }
        newAnnotationObj.Listable = showInNotesPanel
        newAnnotationObj.ReadOnly = readOnly;

        if (fontSize) {
            newAnnotationObj.FontSize = fontSize;
        }
        if (autosize) {
            //
            newAnnotationObj.setAutoSizeType(pdfInstance.Core.Annotations.FreeTextAnnotation.AutoSizeTypes.AUTO)
            // newAnnotationObj.setAutoSizeType('auto')
        }

        if (excludeFromSummary) {
            newAnnotationObj.setCustomData(CustomDataKey.excludeFromSummary, 'true')
        } else {
            newAnnotationObj.setCustomData(CustomDataKey.excludeFromSummary, 'false')
        }

        (borderStyle === 'dash') && (newAnnotationObj.setBorderStyle('dash'));
        (borderStyle === 'solid') && (newAnnotationObj.setBorderStyle('solid'));

        const allCustomData = customData ? { ...customData } : {}

        ;(includeInExport) && (allCustomData[CustomDataKey.includeInExport] = includeInExport)

        if (relatedSnapshotId) {
            allCustomData[CustomDataKey.relatedSnapshotId] = relatedSnapshotId
        }
        if (skipEventHandlers) {
            allCustomData[CustomDataKey.skipEventHandlers] = 'true'
        }
        if (annotationVariant) {
            allCustomData[CustomDataKey.annotationVariant] = annotationVariant
        }

        if (snapshotRerenderKey) {
            allCustomData[CustomDataKey.snapshotRerenderKey] = snapshotRerenderKey
        }

        if (prevNeedRerender) {
            newAnnotationObj.setCustomData(CustomDataKey.temporary, 'false')
        }

        allCustomData && Object.entries(allCustomData).forEach(([key, value]) => {
            if (value === undefined || value === null) return

            newAnnotationObj.setCustomData(key, value.toString());
        })

        // Create and redray
        if (!doNotCreate) {
            createAnnotations([newAnnotationObj], { redraw: true })
        }

        // Just redraw
        if (rerenderRequired) {
            annotationManager.redrawAnnotation(newAnnotationObj);
        }

        // On first load event won't be created automatically if PDF still loading (annotations will be available with annotationsLIst anyway)
        annotationManager.trigger('annotationChanged', [[newAnnotationObj], 'add', {
            imported: false,
            force: true,
        }]);

        if (previouslyCreatedLink) {
            // Delete previous link annotation before crating a new ony
            // Hack for fix the problem with invisible annotations diring first loading
            annotationManager.deleteAnnotation(previouslyCreatedLink)
        }

        if (reply && !hidden && !isThisSnapshotAlreadyAnnotated) {
            await addCommentForAnnotation({
                annotation: newAnnotationObj,
                commentText: reply,
                annotationManager,
                instance: pdfInstance,
                silentAdd: true,
            })
        }

        if (hidden) {
            newAnnotationObj.NoView = true
            newAnnotationObj.Hidden = true
        } else {
            newAnnotationObj.NoView = false
            newAnnotationObj.Hidden = false
        }

        return rerenderRequired ? null : newAnnotationObj
    } catch (e) {
        console.error('Error creating annotation', type, e)
    }
}

export const getReviewedValueTitle = (originalValue: number | string): string => {
    const text = originalValue?.toString()?.trim() || null
    return 'Reviewed Value: ' + (text ? text : 'Empty')
}

export const createExtractedValueAnnotation = ({
    doNotCreate = false,
    pdfInstance,
    moneyValue,
    annotationsList,
    annotationManager,
    createAnnotations,
    inProgress = true,
    authData,
    overallConf,
    includeGreenTicks,
    includeRedTicks,
    hasReply = false,
    generatingPdf = false,
}: {
    pdfInstance: WebViewerInstance,
    annotationManager: Core.AnnotationManager,
    moneyValue: ReportExtractedValues,
    annotationsList: Core.Annotations.Annotation[]
    doNotCreate?: boolean
    createAnnotations: CreateAnnotations,
    inProgress?: boolean
    authData: AuthData
    overallConf?: ValueValidationConfidence
    includeGreenTicks: boolean
    includeRedTicks: boolean
    hasReply?: boolean
    generatingPdf?: boolean
}) => {
    const {
        coordinates, coords, page: annotPage, originalValue, normalizedValue, createdBy,
    } = moneyValue

    if (!coordinates && !coords) {
        console.error('No coords for moneyValue', moneyValue)
        return
    }

    const coordinatesArr = coords || [coordinates.x0, coordinates.y0, coordinates.width, coordinates.height]

    const focusedSnapIdValue = getQueryParam(QUERY_PARAMS_CONFIG.FOCUSED_EXTRACTED_VALUE_SNAP_ID_QUERY_PARAM.key)

    const isActive = focusedSnapIdValue === moneyValue.id

    let annotationVariant = AnnotationVariant.moneyValue
    if (isActive) {
        annotationVariant = AnnotationVariant.moneyValueActive
    }
    
    const includeInSummary =
        hasReply
    // const includeInSummary =
    //     hasReply || (overallConf === 'invalid' && includeRedTicks)

    const shouldExcludeFromSummary = !includeInSummary

    // Include excludeFromSummary state in the rerender key to force redraw when tick settings change
    const snapshotRerenderKey =
        (isActive ? 'a' : 'na') +
        (inProgress ? '_inPr' : '_nInPr') +
        (shouldExcludeFromSummary ? '_excl' : '_incl') +
        (hasReply ? '_rep' : '') +
        (generatingPdf ? '_gen_pdf' : '') +
        overallConf || ''

    const annotation = createAnnotation({
        createAnnotations,
        doNotCreate,
        annotationsList,
        annotationManager,
        pdfInstance: pdfInstance,
        pageIndex: annotPage,
        padding: 2,
        author: createdBy ? (authData.usersInCompany.find(user => user.uid === createdBy)?.displayName || 'Unknown') : 'Nexly AI',
        textContent: getReviewedValueTitle(originalValue),
        coordinates: coordinatesArr,
        showInNotesPanel: true,
        // excludeFromSummary: shouldExcludeFromSummary,
        annotationVariant,
        relatedSnapshotId: moneyValue.id,
        snapshotRerenderKey,
        readOnly: true,
        hidden: shouldExcludeFromSummary && generatingPdf, // FIXME: Remove line
        customData: {
            relatedSnapshotId: moneyValue.id,
            skipEventHandlers: 'true',
            normalizedValue: normalizedValue,
            originalValue: originalValue,
            annotationVariant: annotationVariant,
            hasInvalid: overallConf === 'invalid' ? 'true' : 'false',
        },
    })

    return annotation
}

export const useUpdateAnnotationTicks = () => {
    const { annotationManager } = useViewerDocument()
    const { pdfInstance } = useContext(PdfViewerContext2)
    const createAnnotations = useCreateAnnotations()

    // Get tick preferences from localStorage
    const [includeGreenTicks] = useLocalStorage(EXPORT_STORAGE_KEYS.INCLUDE_GREEN_TICKS, false);
    const [includeRedTicks] = useLocalStorage(EXPORT_STORAGE_KEYS.INCLUDE_RED_TICKS, true);
    const [generatingPdf, setGeneratingPdf] = useQueryParam(QUERY_PARAMS_CONFIG.GENERATING_SUMMARY_PDF.key, QUERY_PARAMS_CONFIG.GENERATING_SUMMARY_PDF.type)

    return {
        updateAnnotationTicks: (
            valueValidationS: ValueValidation,
            moneyValue: ReportExtractedValues,
            annotationsList: Annotation[],
            overallConf: ValueValidationConfidence,
        ): Promise<Annotation | void> => {
            const valueValidation: ValueValidation = valueValidationS

            let indexStart = 0
            const updateAnnotationTicks: ({
                text: string,
                color: string,
                confidence: ValueValidationConfidence,
            } | null)[] = valueValidationTypes.map((type, index) => {
                const conf = getConf(valueValidation, type)

                const displayConfList: ValueValidationConfidence[] = ['valid', 'invalid', 'verificationRequired']

                const displayConf = displayConfList.includes(conf)

                if (!conf || !displayConf) return null

                // When generating PDF, only include valid (green) and invalid (red) ticks based on settings
                if (generatingPdf) {
                    // Skip if not valid or invalid
                    if (conf !== 'valid' && conf !== 'invalid') return null;

                    // Skip green ticks if they are not included
                    if (conf === 'valid' && !includeGreenTicks) return null;

                    // Skip red ticks if they are not included
                    if (conf === 'invalid' && !includeRedTicks) return null;
                }

                const color = VALUE_VALIDATION_COLOR[conf]

                const text = type.toUpperCase()

                const res = {
                    indexStart,
                    color,
                    text,
                }

                // 1 - space
                indexStart += text.length + 1

                return res
            }).filter(Boolean).filter(el => el?.text?.length)

            const textContent = updateAnnotationTicks.map(el => el.text).join(' ').trim()

            const { coordinates } = moneyValue;

            // FIXME: тут нужно проверять не overall а есть ли хотя бы один красный
            // FIXME: сейчас это работает но для зеленого сломается
            // Determine if this annotation should be excluded from summary based on tick settings
            const shouldExcludeFromSummary = overallConf !== 'invalid' && overallConf !== 'valid' && !textContent?.trim().length

            // Include excludeFromSummary state in the uniqueId to force redraw when tick settings change
            const uniqueId = valueValidationS.id + '_' +
                textContent +
                updateAnnotationTicks.map(el => el?.color).join(' ') +
                (shouldExcludeFromSummary ? '_excl' : '_incl');

            const annotation = createAnnotation({
                createAnnotations,
                doNotCreate: true,
                annotationsList,
                annotationManager,
                pdfInstance: pdfInstance,
                annotationType: 'FreeText',
                allowRemoveEmpty: true,
                pageIndex: moneyValue.page,
                // Need only two because of autosize
                // 32=8*4 (4 - ticks count), 4 - height
                coordinates: [coordinates?.x0, coordinates?.y0 + coordinates?.height + 1, 32, 4],
                showInNotesPanel: false,
                annotationVariant: AnnotationVariant.valueConfidence,
                fontSize: '5pt',
                bold: true,
                relatedSnapshotId: valueValidationS.id,
                snapshotRerenderKey: uniqueId,
                textContent: textContent,
                readOnly: true,
                excludeFromSummary: shouldExcludeFromSummary,
                richTextStyle: updateAnnotationTicks.reduce((acc, el) => {
                    acc[el.indexStart] = {
                        'color': el.color,
                        'font-weight': 'bold',
                    }
                    return acc
                }, {}),
                customData: {
                    skipEventHandlers: 'true',
                    annotationVariant: AnnotationVariant.valueConfidence,
                },
            })

            return annotation
        },
    }
}
