import React, {useContext, useState,} from 'react'

import {CloseOutlined, CopyOutlined, DownloadOutlined, PlusOutlined,} from '@ant-design/icons';
import * as Sentry from '@sentry/react';
import {useAsyncEffect} from 'ahooks';
import {Button, Card, DatePicker, Dropdown, Flex, Input, MenuProps, Select, Table, Tag, Typography,} from 'antd';
import {ColumnsType} from 'antd/es/table';
import {message} from 'antd/lib';
import dayjs from 'dayjs';
import format from 'date-fns/format';
import {doc, getDoc, query, where,} from 'firebase/firestore';
import {useCollection} from 'react-firebase-hooks/firestore';
import {Outlet, useNavigate,} from 'react-router-dom';

import {firebaseDownloadUrl} from '@/api/utils/firebase/firebaseDownloadUrl.ts';
import {DATE_FORMAT_MINUTE} from '@/constants/dates.ts';
import {clientCompanyRef} from '@/firestore/api/clientCompany.ts';
import {companyEntityRef} from '@/firestore/api/companyEntity.ts';
import {User} from '@/firestore/api/user.ts';
import {downloadFile} from '@/firestore/utils/downloadFile.ts';
import {PERIOD_SELECT_OPTIONS} from '@/pages/FilesPage/AddNewReportModal';
import {firstLetterToUpperCase} from '@/widgets/NotesLibrary';

import {AuthData, AuthDataContext,} from '../../components/containers/AuthContext';
import {ROUTES_CONFIG_REG_REP} from '../../constants/routes.ts';
import {
    RegRepProcess,
    RegRepProcessFile,
    regRepProcessRef,
    regRepResultFilesKeys,
    regRepSectorOptions,
    RegRepSectors,
    UploadType,
} from '../../firestore/api/regRepProcess.ts';

// Create an interface for the enhanced process item with UI and data dependencies
interface EnhancedRegRepProcess extends RegRepProcess {
    docRef?: any;
    id: string;
    entity?: any;
    company?: any;
    user?: User;
    navigate?: any;
}

const statusColorByName = {
    initial: 'default',
    preparingS213: 'processing',
    processing: 'processing',
    error: 'error',
    done: 'success',
}

const statusTitleByName = {
    initial: 'Initial',
    preparingS213: 'Extracting mappings',
    processing: 'Processing',
    error: 'Extraction Failed',
    done: 'Success',
}

const validationStatusColorByName: Record<RegRepProcess['validationStatus'], string> = {
    processing: 'processing',
    warning: 'warning',
    failed: 'error',
    noErrors: 'success',
}

const validationStatusTitleByName: Record<RegRepProcess['validationStatus'], string> = {
    processing: 'Validating',
    warning: 'Errors Found',
    noErrors: 'No Errors Found',
    failed: 'Failed',
}

const tableColumns = (username: string): ColumnsType<EnhancedRegRepProcess> => ([
    {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
        width: 50,
        render: (value) => (
            <CopyOutlined
                style={{cursor: 'pointer'}}
                onClick={(e) => {
                    e.stopPropagation();
                    navigator.clipboard.writeText(value)
                        .then(() => message.success('ID copied to clipboard'))
                        .catch(() => message.error('Failed to copy ID'));
                }}
            />

        ),
    },
    {
        width: 150,
        title: 'Validation Status',
        dataIndex: 'validationStatus',
        key: 'validationStatus',
        render: (value, item) => {
            // First - show processing progress
            if (item.status === 'processing' || item.status === 'error') {
                return (
                    <Tag color={statusColorByName[item.status]}>
                        {statusTitleByName[item.status]}
                    </Tag>
                )
            }

            return (
                <Tag color={validationStatusColorByName[item.validationStatus]}>
                    {validationStatusTitleByName[item.validationStatus]}
                </Tag>
            )
        },
    },
    {
        title: 'Uploaded At',
        dataIndex: 'uploadedAt',
        key: 'uploadedAt',
        width: 170,
        render: (value) => (
            format(value.toDate(), DATE_FORMAT_MINUTE)
        ),
    },
    {
        title: 'Reporting Month',
        dataIndex: 'reportMonth',
        key: 'reportMonth',
        width: 150,
        render: (value) => (
            value ? format(value.toDate(), 'MMMM yyyy') : '—'
        ),
    },
    {
        title: 'Client',
        width: 150,
        dataIndex: 'company',
        key: 'company',
        render: (value) => {
            return value?.name
        },
    },
    {
        title: 'Entity',
        width: 150,
        dataIndex: 'entity',
        key: 'entity',
        render: (value) => {
            return value?.name ?? '—'
        },
    },
    {
        title: 'Sector',
        width: 150,
        dataIndex: 'sector',
        key: 'sector',
        render: (value) => {
            const option = regRepSectorOptions.find(opt => opt.value === value);
            return option ? option.label : firstLetterToUpperCase(value);
        },
    },
    // {
    //     title: 'Type',
    //     width: 70,
    //     dataIndex: 'uploadType',
    //     key: 'uploadType',
    //     render: (value) => {
    //         return firstLetterToUpperCase(value)
    //     },
    // },
    {
        width: 150,
        title: 'Uploaded by',
        dataIndex: 'user',
        key: 'user',
        render: (value: User) => value?.displayName ?? '—',
    },
    {
        width: 150,
        title: 'Files Uploaded',
        render: (value, item: EnhancedRegRepProcess) => {
            const uploadedFiles: string[] = []

            if (item.s213format === 'bob50') {
                if (item.fileS213bob50) {
                    uploadedFiles.push('Trial balance (BOB50)')
                }
                if (item.fileS213Bob50Mapping) {
                    uploadedFiles.push('Mapping file')
                }
            } else if (item.s213format === 'generic') {
                if (item.fileS213) {
                    uploadedFiles.push('S213')
                }
            }

            if (item.fileTPTOBS) {
                uploadedFiles.push('TPTOBS')
            }
            if (item.fileU11) {
                uploadedFiles.push('U11')
            }
            if (item.fileS214) {
                uploadedFiles.push('S214')
            }
            if (item.fileTPTTBS) {
                uploadedFiles.push('TPTTBS')
            }
            if (item.fileS216) {
                uploadedFiles.push('S216')
            }
            if (item.fileTPTIBS) {
                uploadedFiles.push('TPTIBS')
            }

            return (
                <Flex gap={4} wrap='wrap'>
                    {uploadedFiles.map((file) => (
                        <Tag key={file} style={{margin: 0}}>{file}</Tag>
                    ))}
                </Flex>
            )
        },
    },
    {
        title: 'Actions',
        key: 'actions',
        fixed: 'right',
        width: 230,
        render: (_, item) => {
            const items: MenuProps['items'] =
                regRepResultFilesKeys
                    .filter((key) => item[key])
                    .map((key) => {
                        const fileItem = item[key] as RegRepProcessFile;
                        if (!fileItem) return null;

                        return {
                            key: key,
                            label: (
                                <Typography.Link
                                    onClick={() => {
                                        firebaseDownloadUrl(fileItem.file).then((url) => {
                                            downloadFile(url, fileItem.name)
                                        }).catch(() => {
                                            message.error('Can\'t download the file')
                                        })
                                    }}
                                >
                                    {fileItem.name}
                                </Typography.Link>
                            ),
                        }
                    }).filter(Boolean);

            return (
                <Dropdown.Button
                    disabled={item.status !== 'done'}
                    menu={{items}}
                    icon={<DownloadOutlined/>}
                    type='primary'
                >
                    Download Results
                </Dropdown.Button>
            )
        },
    },
])

export const RegRepPage = Sentry.withProfiler(() => {
    const authData = useContext<AuthData>(AuthDataContext)
    const navigate = useNavigate()
    const userName = authData.user.displayName

    const [clientCompanySnap] = useCollection(query(clientCompanyRef, where('companyId', '==', authData.company.id)))
    const [entitySnap] = useCollection(query(companyEntityRef, where('companyId', '==', authData.company.id)))

    const [nameFilter, setNameFilter] = useState<string>('')
    const [clientFilter, setClientFilter] = useState<string | null>(null)
    const [entityFilter, setEntityFilter] = useState<string | null>(null)
    const [periodFilter, setPeriodFilter] = useState<string | null>(null)
    const [typeFilter, setTypeFilter] = useState<string | null>(null)
    const [uploadedByFilter, setUploadedByFilter] = useState<string | null>(null)
    const [sectorFilter, setSectorFilter] = useState<RegRepSectors | null>(null)
    const [reportMonthFilter, setReportMonthFilter] = useState<dayjs.Dayjs | null>(null)

    const [regRepProcessSnapshot, regRepProcessLoading] = useCollection(query(
        regRepProcessRef,
        where('companyId', '==', authData.company.id),
    ))

    const [tableData, setTableData] = useState<EnhancedRegRepProcess[]>([])

    if (!authData.company.data()?.features?.includes('showRegRep')) {
        return (
            <div style={{
                padding: 16,
                gap: 20,
                justifyContent: 'space-between',
                alignItems: 'center',
                maxHeight: 'fit-content'
            }}>
                <Typography.Title level={4}>Regulatory Reporting</Typography.Title>
                <Typography.Text>
                    You don't have access to this feature
                </Typography.Text>
            </div>
        )
    }

    // Inject data into
    useAsyncEffect(async () => {
        if (!regRepProcessSnapshot?.size) return;

        const modifiedItems = regRepProcessSnapshot.docs
            .map(el => ({
                ...el.data(),
                docRef: el.ref,
                id: el.ref.id,
            })) as EnhancedRegRepProcess[]

        try {
            await Promise.all(
                modifiedItems.map(async (report) => {
                    const promises = [];

                    if (report.entityId) {
                        promises.push(getDoc(doc(companyEntityRef, report.entityId)));
                    } else {
                        promises.push(Promise.resolve(null));
                    }

                    if (report.clientCompanyId) {
                        promises.push(getDoc(doc(clientCompanyRef, report.clientCompanyId)));
                    } else {
                        promises.push(Promise.resolve(null));
                    }

                    const [entity, company] = await Promise.all(promises);

                    report.entity = entity?.data?.();
                    report.company = company?.data?.();
                    report.user = authData.usersInCompany.find(user => user.uid === report.userId)
                    report.navigate = navigate
                }),
            )
        } catch (e) {
            console.error('Error while fetching data for reg rep table', e)
        }

        const res = modifiedItems.filter((item) => {
            // Filter by name (if not empty)
            if (nameFilter && item.id && !item.id.toLowerCase().includes(nameFilter.toLowerCase())) {
                return false;
            }

            // Filter by client company
            if (clientFilter && item.clientCompanyId && item.clientCompanyId !== clientFilter) return false

            // Filter by entity
            if (entityFilter && item.entityId && item.entityId !== entityFilter) return false

            // // Filter by period
            // if (periodFilter && item.period !== periodFilter) return false

            // // Filter by type
            // if (typeFilter && item.uploadType !== typeFilter) return false

            // Filter by uploader
            if (uploadedByFilter && item.userId !== uploadedByFilter) return false

            // Filter by sector
            if (sectorFilter && item.sector !== sectorFilter) return false

            // Filter by report month
            if (reportMonthFilter && item.reportMonth) {
                const reportMonthDate = item.reportMonth.toDate();
                const filterDate = reportMonthFilter.toDate();

                if (reportMonthDate.getMonth() !== filterDate.getMonth() ||
                    reportMonthDate.getFullYear() !== filterDate.getFullYear()) {
                    return false;
                }
            }

            return true
        })

        // Sort by uploadedAt in descending order (newest first)
        const sortedData = [...res].sort((a, b) => {
            const dateA = a.uploadedAt?.toDate?.() || new Date(0)
            const dateB = b.uploadedAt?.toDate?.() || new Date(0)
            return dateB.getTime() - dateA.getTime()
        })

        setTableData(sortedData)
    }, [regRepProcessSnapshot, nameFilter, clientFilter, entityFilter, periodFilter, typeFilter, uploadedByFilter, sectorFilter, reportMonthFilter])

    const resetFilters = () => {
        setNameFilter('');
        setClientFilter(null);
        setEntityFilter(null);
        setPeriodFilter(null);
        setTypeFilter(null);
        setUploadedByFilter(null);
        setSectorFilter(null);
        setReportMonthFilter(null);
    };

    return (
        <Flex
            vertical
            gap={20}
            style={{
                padding: 16,
                height: '100%',
            }}
        >
            <Flex
                align='baseline'
                justify='space-between'
                gap={16}
                flex={1}
            >
                <Typography.Title level={4}>Regulatory Reporting</Typography.Title>
                <Button
                    icon={<PlusOutlined/>}
                    type='primary'
                    onClick={() => {
                        navigate(ROUTES_CONFIG_REG_REP.REG_REP_NEW.path)
                    }}
                    style={{width: 'unset'}}
                >
                    {ROUTES_CONFIG_REG_REP.REG_REP_NEW.title}
                </Button>
            </Flex>

            <Card
                size='small'
                styles={{
                    body: {
                        padding: 16,
                        display: 'flex',
                        gap: 16,
                        flexWrap: 'wrap',
                        alignItems: 'center',
                    },
                }}
            >
                {/* Debug input */}
                {import.meta.env.VITE_ENVIRONMENT === 'local' && (
                    <Input
                        style={{width: 300}}
                        value={nameFilter}
                        onChange={(event) => setNameFilter(event.target.value)}
                        allowClear
                        placeholder='Search by ID'
                    />
                )}

                <Select
                    value={clientFilter}
                    allowClear={true}
                    onClear={() => {
                        setClientFilter(null)
                    }}
                    style={{width: 200}}
                    placeholder='Client'
                    options={clientCompanySnap?.docs?.map((doc) => ({
                        label: doc.data().name,
                        value: doc.id,
                    }))}
                    onSelect={(value) => {
                        setClientFilter(value)
                        setEntityFilter(null)
                    }}
                />

                <Select
                    value={entityFilter}
                    allowClear={true}
                    onClear={() => {
                        setEntityFilter(null)
                    }}
                    style={{width: 200}}
                    placeholder='Entity'
                    // Filter by company if selected
                    options={entitySnap?.docs?.filter(el => clientFilter ? (el.data().clientCompanyId === clientFilter) : true).map((doc) => ({
                        label: doc.data().name,
                        value: doc.id,
                    }))}
                    onSelect={(value) => {
                        setEntityFilter(value)
                    }}
                />

                <DatePicker
                    value={reportMonthFilter}
                    onChange={setReportMonthFilter}
                    picker="month"
                    style={{width: 200}}
                    placeholder='Reporting Month'
                    allowClear
                />

                <Select
                    value={periodFilter}
                    allowClear={true}
                    placeholder='Period'
                    style={{width: 200}}
                    onSelect={(value) => {
                        setPeriodFilter(value)
                    }}
                    onClear={() => {
                        setPeriodFilter(null)
                    }}
                    options={PERIOD_SELECT_OPTIONS}
                />

                <Select
                    value={sectorFilter}
                    allowClear={true}
                    placeholder='Sector'
                    style={{width: 200}}
                    onSelect={(value: RegRepSectors) => {
                        setSectorFilter(value)
                    }}
                    onClear={() => {
                        setSectorFilter(null)
                    }}
                    options={regRepSectorOptions}
                />

                <Select
                    value={typeFilter}
                    allowClear={true}
                    placeholder='Type'
                    style={{width: 200}}
                    onSelect={(value) => {
                        setTypeFilter(value)
                    }}
                    onClear={() => {
                        setTypeFilter(null)
                    }}
                    options={Object.keys(UploadType).map((type) => ({
                        label: firstLetterToUpperCase(type),
                        value: type,
                    }))}
                />

                <Select
                    value={uploadedByFilter}
                    placeholder='Uploaded By'
                    style={{width: 200}}
                    onSelect={(value) => {
                        setUploadedByFilter(value)
                    }}
                    allowClear={true}
                    onClear={() => {
                        setUploadedByFilter(null)
                    }}
                    options={authData.usersInCompany.map((user) => ({
                        label: user.displayName,
                        value: user.uid,
                    }))}
                />

                <Button
                    icon={<CloseOutlined/>}
                    onClick={resetFilters}
                >
                    Reset
                </Button>
            </Card>

            <Table
                loading={regRepProcessLoading}
                columns={tableColumns(userName)}
                dataSource={tableData}
                bordered
                scroll={{
                    scrollToFirstRowOnChange: true,
                    y: 500,
                }}
            />
            <Outlet/>
        </Flex>
    )
})
