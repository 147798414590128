import React from 'react'

import { FileDragger } from '@/components/base/FileDragger';
import { FileDropZone } from '@/components/FileDropZone';

import { ReportDropAreaProps } from './ReportDropArea.types'

export const ReportDropArea = ({ customRequest, disable }: ReportDropAreaProps) => {
    return (
        <>
            <FileDropZone
                customRequest={customRequest}
                disable={disable}
            />
            <FileDragger
                customRequest={customRequest}
                style={{ 
                    flex: 1,
                    background: 'rgb(255 255 255 / 66%)',
                    maxHeight: 300,
                    padding: 16,
                }}
                getComment={({
                    sizeMb, formats,
                }) => (
                    [`Drag and Drop a Report (${formats.map(el=> el.toUpperCase()).join(', ')}, up to ${sizeMb}MB)`, 'or']
                )}
                button={true}
                disabled={disable}
            />
        </>
    )
}
