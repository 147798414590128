import {
    useState, useEffect, 
} from 'react';

import { FileAddOutlined } from '@ant-design/icons';

import { FileDragger } from '@/components/base/FileDragger';
import { COLORS } from '@/constants/colors.ts';

import styles from './FileDropZone.module.scss';
import { FileDropZoneProps } from './FileDropZone.types';

export const FileDropZone: React.FC<FileDropZoneProps> = ({ customRequest, disable }) => {
    // env: Track if user is currently dragging over the viewport
    const [isDragging, setIsDragging] = useState(false);
  
    useEffect(() => {
        // env: Handle mousemove to reset stuck drag state
        const handleMouseMove = (e: MouseEvent) => {
            if (!e.buttons && isDragging) { // если кнопки мыши не нажаты, но состояние драга активно
                setIsDragging(false);
            }
        };

        // env: Handle dragenter event on window
        const handleDragEnter = (e: DragEvent) => {
            if (disable) return;
            e.preventDefault();
            e.stopPropagation();
            setIsDragging(true);
        };

        // env: Handle dragleave event on window
        const handleDragLeave = (e: DragEvent) => {
            if (disable) return;
            e.preventDefault();
            e.stopPropagation();
      
            // env: Check if we're leaving the viewport
            if (
                e.clientX <= 0 ||
        e.clientY <= 0 ||
        e.clientX >= window.innerWidth ||
        e.clientY >= window.innerHeight
            ) {
                setIsDragging(false);
            }
        };

        // env: Handle drop event
        const handleDrop = (e: DragEvent) => {
            if (disable) return;
            e.preventDefault();
            e.stopPropagation();
            setTimeout(() => {
                setIsDragging(false);
                // Just if case to not break file uploading process
            }, 300)
        };

        // env: Handle dragover event
        const handleDragOver = (e: DragEvent) => {
            if (disable) return;
            e.preventDefault();
            e.stopPropagation();
        };

        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('dragenter', handleDragEnter);
        window.addEventListener('dragleave', handleDragLeave);
        window.addEventListener('drop', handleDrop);
        window.addEventListener('dragover', handleDragOver);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('dragenter', handleDragEnter);
            window.removeEventListener('dragleave', handleDragLeave);
            window.removeEventListener('drop', handleDrop);
            window.removeEventListener('dragover', handleDragOver);
        };
    }, [disable, isDragging]);

    if (disable) return null;

    return (
        <>
            {isDragging && (
                <FileDragger
                    disabled={disable}
                    className={styles.fadeDropZone}
                    style={{
                        width: 'calc(100vw - 40px)',
                        maxHeight: 'calc(100vh - 40px)',
                        position: 'fixed',
                        background: 'rgba(255,255,255,0.9)',
                        borderWidth: 3,
                        top: 20,
                        left: 20,
                        zIndex: 999,
                    }}
                    title='Drop File Here'
                    titleSize={2}
                    icon={(
                        <FileAddOutlined
                            style={{
                                fontSize: 70,
                                color: COLORS.main,
                            }}
                        />
                    )}
                    customRequest={customRequest}
                />
            )}
        </>
    );
};
