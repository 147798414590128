import { PresetStatusColorType } from 'antd/es/_util/colors';
import {
    collection, Timestamp,
} from 'firebase/firestore';
import { ItemType } from 'rc-collapse/es/interface';

import { getRequestQueries } from '@/api/utils/getRequestQueries';

import { ComercialCompanyStepKeys } from '../../../backend/configs/src/configs/steps/comercialCompanySteps.types.ts';
import { FundStepKeys } from '../../../backend/configs/src/configs/steps/fundSteps.types.ts';
import { Period } from '../../pages/FilesPage/AddNewReportModal';
import { db } from '../config.ts';

type ReportProcessingStatus =
    /**
     * Initial
     */
    'initial' |
    /**
     * Processing in progress
     */
    'processing' |
    /**
     * Processing is failed
     */
    'error' |
    /**
     * OCR in progress
     */
    'ocr' |
    /**
     * Review finished
     */
    'done'

export type ReportReviewStatus =
    'inProgress' |
    /**
     * Steps finished but review is not finalized
     */
    'stepsFinished' | // FIXME: Remove this status
    'finished'

export const reportReviewStatusName: Record<ReportReviewStatus, string> = {
    inProgress: 'In Progress',
    stepsFinished: 'In Progress',
    finished: 'Finished',
}

export const reportReviewStatusFullName: Record<ReportReviewStatus, string> = {
    inProgress: 'Review In Progress',
    stepsFinished: 'Review In Progress',
    finished: 'Report Review Finalized',
}

export const REVIEW_STATUS_OPTIONS: Array<ItemType> = [
    {
        label: reportReviewStatusName['inProgress'],
        key: 'inProgress',
    },
    {
        label: reportReviewStatusName['finished'],
        key: 'finished',
    },
]

export const reportProcessingStatusName: Record<ReportProcessingStatus, string> = {
    initial: 'Processing',
    processing: 'Processing',
    ocr: 'Text Recognition',
    error: 'Warning', // Error means small issues with extraction now, we not consider it as error in UI
    done: 'Done',
}

export const reportProcessingStatusColor: Record<ReportProcessingStatus, PresetStatusColorType> = {
    initial: 'processing',
    processing: 'processing',
    error: 'warning',
    done: 'success',
    ocr: 'processing',
}

export const reportReviewStatusColor: Record<ReportReviewStatus, PresetStatusColorType> = {
    inProgress: 'processing',
    stepsFinished: 'processing',
    finished: 'success',
}

export type EntityType = 'commercialCompany' | 'regulatedFund' | 'other'

export const reportTypeTitles: Record<EntityType, string> = {
    commercialCompany: 'Commercial Company',
    regulatedFund: 'Funds',
    other: 'Other',
}

export enum ReportReviewAlert {
    invalidTemplate = 'invalidTemplate',
    limitedFile = 'limitedFile',
}

export type ReportReviewFinFramework = 'US_GAAP' | 'IFRS' | 'UK_GAAP' | 'LUX_GAAP' | 'OTHER'

export const reportReviewFInFrameworkTitles: Record<ReportReviewFinFramework, string> = {
    US_GAAP: 'US GAAP',
    IFRS: 'IFRS',
    UK_GAAP: 'UK GAAP',
    LUX_GAAP: 'LUX GAAP',
    OTHER: 'Other',
}

export const REVIEW_STATUS_ACTION_TEXT: Partial<Record<ReportReviewStatus, string>> = {
    inProgress: 'Move to In Progress',
    finished: 'Mark as Reviewed',
}

/**
 * TODO: Rename to ReportReview
 */
export interface ReportReview {
    id?: string
    name: string
    entityId: string
    clientCompanyId: string
    // Format: '2022-01-01'
    reportDate: string
    /**
     * Type of the Entity
     *
     * 'Commercial Company' or 'Regulated Funds'
     */
    reportType: EntityType

    year: string
    /**
     * Progress in steps
     */
    currentStep?: FundStepKeys | ComercialCompanyStepKeys
    /**
     * Progress in percents
     */
    progress: number
    /**
     * Processing Status
     */
    status: ReportProcessingStatus
    /**
     * Review Status
     */
    reviewStatus: ReportReviewStatus
    /**
     * Will be true if the pdf was parsed on our server
     */
    draftVersion: number
    /**
     * High level explanation of the errors happened during the processing
     */
    errorMessages: string[]
    /**
     * True - if the report was removed
     */
    archived?: boolean

    userId: string
    /**
     * Global copmany
     */
    companyId: string
    /**
     * Report period
     */
    period: Period
    /**
     * Id
     */
    reviewer: string
    /**
     * Id
     */
    preparer: string

    uploadedAt: Timestamp

    /**
     * Ids of the alerts that were closed by the user
     */
    closedAlerts: ReportReviewAlert[]

    /**
     * Report + annotations
     */
    mergedPdfGenerated?: boolean

    /**
     * Path to merged pdf
     */
    reportWithAnnotations?: string

    /**
     * Need this for the button to be disabled for 10 minutes
     */
    lastMergedPdfGeneration?: Timestamp

    templateValid?: boolean

    /**
     * Financial reporting framework
     */
    finFramework?: ReportReviewFinFramework
    /**
     * Debug/dev
     */
    skipProcessing?: boolean
}

export const REPORTS_REVIEW_COLLECTION = 'reportReviews'

export const reportReviewRef = collection(db, REPORTS_REVIEW_COLLECTION)

export const {
    useFBItemsQuery: useReportReviewQuery,
    useFBItemByIdQuery: useReportReviewByIdQuery,
    useFBItemUpdateMutation: useReportReviewUpdateMutation,
    useFBItemDeleteMutation: useReportReviewDeleteMutation,
} = getRequestQueries<ReportReview, {}>({ url: REPORTS_REVIEW_COLLECTION })
