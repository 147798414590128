import React from 'react'

import {
    Button,
    Flex, Typography, Upload,
} from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import { RcFile } from 'antd/es/upload/interface';
import { message } from 'antd/lib';
import { UploadRequestOption } from 'rc-upload/lib/interface';

import {
    FileDraggerFormat, FileDraggerProps, 
} from './FileDragger.types'

const fileTypes: Record<FileDraggerFormat, string> = {
    pdf: 'application/pdf',
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    csv: 'text/csv',
}

export const FileDragger = (props: FileDraggerProps) => {
    const {
        formats = ['pdf'], sizeMb = 30, icon,
        title,
        titleSize,
        getComment = () => null ,
        button = false,
        ...restProps 
    } = props

    const handleBeforeUpload = (file: RcFile) => {
        const isFormatValid = formats.some(format => {
            const fileType = fileTypes[format]
            return fileType === file.type
        })

        if (!isFormatValid) {
            message.error(`${file.name} is not a ${formats.join(',')} file`);
        }

        if(file.size > sizeMb * 1024 * 1024) {
            message.error(`Please upload a file less than ${sizeMb} MB`);
        }

        return isFormatValid || Upload.LIST_IGNORE;
    }

    const draggerCustomRequest = async (data: UploadRequestOption) => {
        if(!data.file || !data.onError || !data.onSuccess || !data.onProgress) {
            message.error('File upload failed')
            return
        }

        try {
            await props.customRequest(data)
        } catch (error) {
            message.error('File upload failed')
        }
    }
    
    return (
        <Dragger
            multiple={false}
            maxCount={1}
            style={{ flexDirection: 'row' }}
            beforeUpload={handleBeforeUpload}
            customRequest={draggerCustomRequest}
            {...restProps}
        >
            <Flex align='center' vertical justify='center' gap={8}>
                {icon}
                {title && (
                    <Typography.Title level={titleSize || 5} >
                        {title}
                    </Typography.Title>
                )}
                <Flex
                    align='center'
                    justify='center'
                    gap={4}
                    style={{
                        flexDirection: 'column',
                        color: 'gray',
                    }}
                >
                    {(getComment({
                        sizeMb,
                        formats,
                    }) || [` Add file in ${formats.join(',')} format, less than ${sizeMb} MB`])
                        .map(str => (
                            <Flex className='ant-upload-hint'>
                                {str}
                            </Flex>
                        ))}
                </Flex>
                {button && (
                    <Button type='primary' >
                        Select Report
                    </Button>
                )}
            </Flex>
        </Dragger>
    )
}
