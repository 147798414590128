import React, {
    useContext, useEffect, useState,
} from 'react'

import { PlusOutlined } from '@ant-design/icons';
import { useAsyncEffect } from 'ahooks';
import {
    Flex, Typography, Spin, Button, ConfigProvider, message,
} from 'antd';
import {
    and, query, where,
} from 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';
import { useParams } from 'react-router-dom';
import { useQueryParam } from 'use-query-params';

import {
    AuthData, AuthDataContext, 
} from '@/components/containers/AuthContext';
import { COLORS } from '@/constants/colors.ts';
import {
    reportExtractedVlueTypesToFetch, useReportExtractedValuesQuery,
} from '@/firestore/api/reportExtractedValues.ts';
import { reportPageClassificationRef } from '@/firestore/api/reportPageClassification.ts';
import {
    ReviewLinkedObjects,
    reviewLinkedObjectsRef,
    useReviewLinkedObjectsCreateMutation,
    useReviewLinkedObjectsUpdateMutation,
} from '@/firestore/api/reviewLinkedObjects.ts';
import {
    useValueValidationUpdateMutation, useValueValidationQuery, ValueValidationConfidence,
} from '@/firestore/api/valueValidation.ts';
import { useFocusedValueId } from '@/hooks/useFocusedValueId.ts';
import { useViewerDocument } from '@/hooks/useViewerDocument.ts';
import { IcValidationItem } from '@/pages/ReviewPage';
import { useCreateAnnotations } from '@/utils/pdfViewer/createAnnotations.ts';
import { getAnnotationBySnapId } from '@/utils/pdfViewer/getAnnotationBySnapId';
import { ACTIVE_IC_VALUE_QUERY_PARAM } from '@/widgets/MoneyValuesNavigator/MoneyValuesNavigator.constants.ts';
import { firstLetterToUpperCase } from '@/widgets/NotesLibrary';
import { createExtractedValueAnnotation } from '@/widgets/PdfViewer/PdfViewer.utils.ts';

import {
    IcValidatioinProps, LinkedItemsByPage, 
} from './IcValidatioin.types'

export const IcValidatioin = (props: IcValidatioinProps) => {
    // IC navigation state
    const [activeMoneyValues, setActiveMoneyValues] = useQueryParam(ACTIVE_IC_VALUE_QUERY_PARAM.name, ACTIVE_IC_VALUE_QUERY_PARAM.type)
    // In some reason setActiveMoneyValues is not change activeMoneyValues without user button click and I have this state to activate default value first time
    const [activeMoneyValuseLocalFallback, setActiveMoneyValuseLocalFallback] = useState<string[]>([])

    const {
        selectedExtractedValSnapId, setSelectedExtractedValSnapId, 
    } = useFocusedValueId()

    const [scrollableAnnotationsReady, setScrollableAnnotationsReady] = useState(false)

    const { id: docId } = useParams()

    const {
        annotationManager, documentViewer, pdfDocument, pdfInstance, 
    } = useViewerDocument()

    const [reivewLinkObjectsSnap, revewLinkObjectsLoading] = useCollection<ReviewLinkedObjects>(query(reviewLinkedObjectsRef, and(
        where('reportId', '==', docId),
    )))

    useEffect(() => {
        if (!selectedExtractedValSnapId) {
            console.error('selectedExtractedValSnapId is not defined')
            return
        }
        // Initially it will be equal
        // setActiveMoneyValues([selectedExtractedValSnapId])
        setActiveMoneyValuseLocalFallback([selectedExtractedValSnapId])
    }, [selectedExtractedValSnapId]);

    const linksRelatedToFocusedSnap = 
        reivewLinkObjectsSnap?.docs
            .find(doc => (
                doc.data().linksGroup.some(link => link.extractedValueId === selectedExtractedValSnapId)
            ))
    
    const reviewLinkedObjectsUpdateMutation = useReviewLinkedObjectsUpdateMutation()
    const reviewLinkedObjectsCreateMutation = useReviewLinkedObjectsCreateMutation()
    
    useEffect(() => {
        // Create new linked objects group
        const extractedValueData = reportExtractedValuesQuery.data?.find(
            doc => doc.id === selectedExtractedValSnapId,
        )

        if(!extractedValueData) {
            message.error('Something went wrong. Plese try again.')
            console.error('linking failed')
        }

        if(addIntoGroupMode) {
            if (linksRelatedToFocusedSnap) {
                const data = linksRelatedToFocusedSnap.data()
                
                reviewLinkedObjectsUpdateMutation.mutateAsync({
                    id: linksRelatedToFocusedSnap.ref.id,
                    data: {
                        ...data,
                        linksGroup: [
                            ...data.linksGroup,
                            {
                                content: extractedValueData.content || '',
                                coords: extractedValueData.coord,
                                page: extractedValueData.page,
                                extractedValueId: selectedExtractedValSnapId,
                            },
                        ], 
                    },
                })
            } else {
                if (extractedValueData && selectedExtractedValSnapId) {
                    reviewLinkedObjectsCreateMutation.mutateAsync({
                        data: {
                            companyId: authData.company.id,
                            reportId: docId,
                            type: 'text',
                            linksGroup: [{
                                content: extractedValueData.content || '',
                                coords: extractedValueData.coords,
                                page: extractedValueData.page,
                                extractedValueId: selectedExtractedValSnapId,
                            }],
                        },
                    });
                }
            }

            setAddIntoGroupMode(false)
        }
    }, [selectedExtractedValSnapId]);
    
    const linkIds = linksRelatedToFocusedSnap?.data().linksGroup.map(link => link.extractedValueId) ?? []

    const {
        data: valueValidation, isFetching: valueValidationLoading,
    } = useValueValidationQuery({
        filters: [
            'and',
            ['reportId', '==', docId],
            ['extractedValueId', 'in', linkIds],
        ],
    }, { enabled: !!linkIds?.length })

    const valueValidationUpdateMutation = useValueValidationUpdateMutation()

    const [itemsList, setItemsList] = useState<LinkedItemsByPage>({});

    const [classification, classificationLoading] = useCollection(query(reportPageClassificationRef, where('reportId', '==', docId)))

    const createAnnotations = useCreateAnnotations()

    const reportExtractedValuesQuery = useReportExtractedValuesQuery({
        filters: [
            'and',
            ['reportId', '==', docId as string],
            ['type', 'in', reportExtractedVlueTypesToFetch],
        ],
    })

    const authData = useContext<AuthData>(AuthDataContext)

    /**
     * Create annotations to make the money values clickable
     */
    useAsyncEffect(async () => {
        if (!annotationManager || !linksRelatedToFocusedSnap || reportExtractedValuesQuery.isLoading) return

        const annotationsList = annotationManager.getAnnotationsList()

        try {
            for (const link of linksRelatedToFocusedSnap.data().linksGroup) {
                const moneyValId = link.extractedValueId
                const moneyValueSnap = reportExtractedValuesQuery.data?.find(doc => doc.id === moneyValId)

                if (moneyValueSnap) {
                    let extractedValAnnot = getAnnotationBySnapId(annotationManager, moneyValueSnap.id, annotationsList)

                    // not created scenario
                    if (!extractedValAnnot) {
                        extractedValAnnot = await createExtractedValueAnnotation({
                            pdfInstance,
                            moneyValue: moneyValueSnap,
                            annotationsList,
                            annotationManager,
                            createAnnotations,
                            authData,
                        })
                    }
                }
            }
        } finally {
            setScrollableAnnotationsReady(true)
        }
    }, [linksRelatedToFocusedSnap?.data().linksGroup.map(link => link.extractedValueId).join(','), annotationManager, reportExtractedValuesQuery.isLoading]);

    // Reset on initial load
    // useEffect(() => {
    //     setActiveMoneyValues([])
    // }, []);

    useAsyncEffect(async () => {
        if (!selectedExtractedValSnapId || !reivewLinkObjectsSnap || reivewLinkObjectsSnap.empty) {
            setItemsList({})
            return
        }

        if (!linksRelatedToFocusedSnap) {
            setItemsList({})
            return
        }

        // // Get all linked objects for the active money value
        // const relatedExtractedValuesIds = reivewLinkObjectsSnap?.docs.map(doc => doc.data().extractedValueId) || [];
        //
        //
        // const relatedExtractedValues = await getDocs(query(reportExtractedValuesRef, where('id', 'in', relatedExtractedValuesIds)))
        //
        // Group the linked objects by page and include extracted value data
        const groupedByPage = linksRelatedToFocusedSnap.data().linksGroup
            .reduce((acc, item) => {
                const pageNumber = item.page;

                if (!acc[pageNumber]) {
                    acc[pageNumber] = [];
                }

                acc[pageNumber].push(item);

                return acc;
            }, {} as LinkedItemsByPage) || {};

        setItemsList(groupedByPage);
    }, [
        selectedExtractedValSnapId,
        linksRelatedToFocusedSnap?.data().linksGroup.map(link => link.extractedValueId).join(','),
        linksRelatedToFocusedSnap?.data().linksGroup.map(link => link.rejected ? '1' : '0').join(','),
    ]);

    const anchorItems = Object.entries(itemsList)

    const [addIntoGroupMode, setAddIntoGroupMode] = useState(false)

    const valuesValidationQuery = useValueValidationQuery({ filters: ['extractedValueId', 'in', linkIds as string[]] }, { enabled: !!((linkIds.length || 0) > 0) })

    const getContentVariations = (strings: string[] = []): string[] => {
        // Get all unique contents, filtering out empty/undefined values
        const variations = [...new Set(
            strings
                .filter(content => content != null && content !== ''),
        )];

        // Return null if there's only one or no variations
        return variations
    };

    if (revewLinkObjectsLoading) return <Spin size='default'/>
    if (!anchorItems.length) return null
    
    const handleManualGroupValidation = async (conf: ValueValidationConfidence) => {
        try {
            if (!reivewLinkObjectsSnap || !linksRelatedToFocusedSnap) return;

            // Reject links if not applicable for all
            // if (conf === 'notApplicable') {
            //     const updatedLinksGroup = linksRelatedToFocusedSnap?.data().linksGroup
            //         .map(link =>
            //             linkIds.includes(link.extractedValueId)
            //                 ? {
            //                     ...link,
            //                     rejected: true,
            //                     rejectedAt: new Date(),
            //                 }
            //                 : link,
            //         );     
            //
            //     await updateDoc(linksRelatedToFocusedSnap.ref, { linksGroup: updatedLinksGroup });
            // }

            // Update valueValidation status for all items in the group
            const validationPromises = (valuesValidationQuery.data || [])
                .filter(data => {
                    const conf = data.manual?.ic || data.auto?.ic
                    return conf !== 'notApplicable'
                })
                .map(data => {
                    return valueValidationUpdateMutation.mutateAsync({
                        id: data.id,
                        data: {
                            manual: {
                                ...data.manual,
                                ic: conf,
                            },
                        },
                    })
                });

            await Promise.all(validationPromises);
        } catch (error) {
            console.error('Error updating validation:', error);
        }
    }

    const allvalid = valueValidation.every(el => el.manual?.ic === 'valid' || el.auto?.ic === 'valid') // FIXME FILTer
    const allinvalid = valueValidation.every(el => el.manual?.ic === 'invalid' || el.auto?.ic === 'invalid')
    const alliNotApplicable = valueValidation.every(el => el.manual?.ic === 'notApplicable' || el.auto?.ic === 'notApplicable')
    const allConf = allvalid ? 'valid' : allinvalid ? 'invalid' : alliNotApplicable ? 'notApplicable' : undefined

    const contentVariations: string[] = getContentVariations(linksRelatedToFocusedSnap?.data().linksGroup.map(el => el.content) || []);

    return (
        <Flex
            vertical
            gap={6}
            style={{
                background: 'white',
                borderRadius: 8,
                overflowY: 'auto',
                maxHeight: 300,
                position: 'relative',
            }}
        >
            {addIntoGroupMode && (
                <Typography> Choose the number</Typography>
            )}
            {!addIntoGroupMode && anchorItems.map(([page, annotations]) => {
                const pageClassificationLabel = classification?.docs[0]?.data()?.labels.find((label: any) => Number(label.pageIndex) === Number(page) && label.include)

                const title = pageClassificationLabel?.pageLabel ? `${firstLetterToUpperCase(pageClassificationLabel?.pageLabel.split('_').join(' '))} - Page ${Number(page) + 1}` : `Page ${Number(page) + 1}`

                return (
                    <Flex
                        key={page}
                        vertical
                        gap={4}
                    >
                        <Typography.Text strong>{title}</Typography.Text>
                        <Flex
                            gap={8}
                            style={{
                                paddingLeft: 8 ,
                                flexDirection: 'column', 
                            }}
                        >
                            {annotations.map((annotation, index) => (
                                <IcValidationItem
                                    key={index + '_' + page}
                                    item={annotation}
                                    contentVariations={contentVariations}
                                    reivewLinkObjectsSnap={reivewLinkObjectsSnap}
                                    valueValidation={valueValidation}
                                    scrollableAnnotationsReady={scrollableAnnotationsReady}
                                    setSelectedExtractedValSnapId={setSelectedExtractedValSnapId}
                                    page={page}
                                    index={index}
                                    activeMoneyValues={activeMoneyValues}
                                    activeMoneyValuseLocalFallback={activeMoneyValuseLocalFallback}
                                />
                            ))}
                        </Flex>
                    </Flex>
                )
            })}
            
            <Flex
                gap={4}
                justify='center'
                align='center'
                style={{
                    position: 'sticky',
                    bottom: 0,
                    paddingTop: 8,
                    background: 'white',
                    borderTop: 'solid 1px rgb(217, 217, 217)',
                }}
            >
                <Flex
                    flex={1}
                    gap={4}
                    justify='flex-start'
                    align='center'
                >
                    <Spin spinning={reportExtractedValuesQuery.isFetching || valuesValidationQuery.isFetching || valueValidationLoading} size='small'/>
                </Flex>
                <Flex
                    flex='1'
                    gap={6}
                >
                    <ConfigProvider
                        theme={{ token: { colorPrimary: COLORS.green } }}
                    >
                        <Button
                            style={{ opacity: allConf === 'valid' ? 1 : 0.7 }}
                            type='primary'
                            onClick={() => {
                                handleManualGroupValidation('valid')
                            }}
                            disabled={alliNotApplicable}
                        >
                          All Valid
                        </Button>
                    </ConfigProvider>
                    <Button
                        danger
                        style={{ opacity: allConf === 'invalid' ? 1 : 0.7 }}
                        type='primary'
                        onClick={() => {
                            handleManualGroupValidation('invalid')
                        }}
                        disabled={alliNotApplicable}
                    >
                          All Invalid
                    </Button>
                    <Button
                        disabled={alliNotApplicable}
                        style={{ opacity: allConf === 'notApplicable' ? 1 : 0.7 }}
                        type='default'
                        onClick={() => {
                            handleManualGroupValidation('notApplicable')
                        }}
                    >
                            Reject All
                    </Button>
                </Flex>
                <Flex
                    flex={1}
                    justify='flex-end'
                >
                    <Flex/>
                    {/*<Button*/}
                    {/*    onClick={() => {*/}
                    {/*        setAddIntoGroupMode(true)*/}
                    {/*    }}*/}
                    {/*    icon={<PlusOutlined/>}*/}
                    {/*/>*/}
                </Flex>
            </Flex>
        </Flex>
    )
}
