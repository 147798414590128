import {
    ArrayParam, BooleanParam, withDefault,
} from 'use-query-params';

export const SUM_SELECT_MULTY_COMPONENT_QUERY_CONFIG = {
    name: 'ss_mc',
    type: withDefault(BooleanParam, true),
}

export const SUM_SELECT_COMPARE_TO_VALUE_QUERY_CONFIG = {
    name: 'ss_ctv',
    type: BooleanParam,
}

export const SUM_SELECT_COMPONENTS_QUERY_PARAM = {
    name: 'sum_comp',
    type: ArrayParam,
}
