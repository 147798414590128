import { collection } from 'firebase/firestore';

import { getRequestQueries } from '@/api/utils/getRequestQueries';

import { db } from '../config.ts';

export type ReportPipelineTwoStatus = 'DONE' | 'PARTIALLY_DONE' | 'ERROR' | 'IN_PROGRESS' | 'PENDING' | 'INITIAL'

export interface ReportPipelineTwo {
    current_state:ReportPipelineTwoStatus
}

export const reportPipelineTwoRef = collection(db, 'reportPipelineTwo')

export const {
    useFBItemsQuery: useReportPipelineTwoQuery,
    useFBItemByIdQuery: useReportPipelineTwoByIdQuery,
} = getRequestQueries<ReportPipelineTwo, {}>({ url: 'reportPipelineTwo' })
