import {
    useEffect, useRef, useState,
} from 'react';

import { message } from 'antd';
import { useParams } from 'react-router-dom';
import { useQueryParam } from 'use-query-params';

import { QUERY_PARAMS_CONFIG } from '@/config/queryParams.ts';
import { CustomDataKey } from '@/constants/pdfViewer/customDataKey.ts';
import { useReportReviewByIdQuery } from '@/firestore/api/reportReview.ts';
import { downloadBlobFile } from '@/firestore/utils/downloadFile';
import { useLocalStorage } from '@/hooks/useLocalStorage.ts';
import { useViewerDocument } from '@/hooks/useViewerDocument';
import {
    EXPORT_DEFAULT_VALUES, EXPORT_STORAGE_KEYS,
} from '@/pages/ReviewPage';
import { wait } from '@/utils/wait.ts';
import { AnnotationVariant } from '@/widgets/PdfViewer';

/**
 * Hook for downloading report on different stages
 *
 * Steps
 * - Hide non-red elements - OK
 * - Create all red - OK
 * - Optional: Add legend page
 * - Generate PDF - OK
 * - Save to the storage to make it available for downloads from reports list table
 * - Recover current page annotations
 * - Allow downloading the PDF from the table
 */
export const useDownloadReportPdf = () => {
    const { id: docId } = useParams();

    const [includeGreenTicks, setIncludeGreenTicks] = useLocalStorage(
        EXPORT_STORAGE_KEYS.INCLUDE_GREEN_TICKS,
        EXPORT_DEFAULT_VALUES.INCLUDE_GREEN_TICKS,
    );
    const [includeRedTicks, setIncludeRedTicks] = useLocalStorage(
        EXPORT_STORAGE_KEYS.INCLUDE_RED_TICKS,
        EXPORT_DEFAULT_VALUES.INCLUDE_RED_TICKS,
    );
    const [summaryPdfMode, setSummaryPdfMode] = useQueryParam<'waitingAnnotations' | 'annotationsReady'>(QUERY_PARAMS_CONFIG.ANNOTATIONS_SUMMARY_PDF_MODE.key, QUERY_PARAMS_CONFIG.ANNOTATIONS_SUMMARY_PDF_MODE.type)
    const [generatingPdf, setGeneratingPdf] = useQueryParam(QUERY_PARAMS_CONFIG.GENERATING_SUMMARY_PDF.key, QUERY_PARAMS_CONFIG.GENERATING_SUMMARY_PDF.type)

    const [hiddedHided, setHiddenHided] = useState(false)

    // Now because of global state all hook instances will be triggered to download PDF
    const thisHookIsWaitingPdf = useRef(false)

    useEffect(() => {
        setSummaryPdfMode(null)
        setGeneratingPdf(false)
    }, []);

    const reportReviewByIdQuery = useReportReviewByIdQuery(docId)
    const reportData = reportReviewByIdQuery.data

    const {
        annotationManager,
        documentViewer,
        pdfDocument,
        pdfInstance,
    } = useViewerDocument();

    console.log('annotationManager', annotationManager)
    
    const visibilityBeforeSummaryCreation = useRef([])

    useEffect(() => {
        if(!annotationManager) return
        if(summaryPdfMode !== 'annotationsReady' || !generatingPdf || !thisHookIsWaitingPdf) return;

        setHiddenHided(true)
    }, [generatingPdf,annotationManager, summaryPdfMode]);

    const handleDownload = async () => {
        setSummaryPdfMode('waitingAnnotations')
        setGeneratingPdf(true);
        thisHookIsWaitingPdf.current = true
    };
    
    const recoverHiddenAnnotations = () => {
        if(!annotationManager) return

        visibilityBeforeSummaryCreation.current.forEach(({
            annotation, hidden, 
        }) => {
            if(hidden) {
                annotationManager.hideAnnotation(annotation)
            } else {
                annotationManager.showAnnotation(annotation)
            }
        })

    }
    
    useEffect(() => {
        // Start generation when PDF is normalized and annotations are ready
        if(summaryPdfMode === 'annotationsReady' && generatingPdf === true && thisHookIsWaitingPdf.current && hiddedHided) {
            generatePdf()
            setSummaryPdfMode(null)
            thisHookIsWaitingPdf.current = false
            setHiddenHided(false)
            
            recoverHiddenAnnotations()
        }
    }, [summaryPdfMode, generatingPdf, hiddedHided])
    
    const generatePdf = async () => {
        if(!annotationManager || !pdfDocument || !pdfInstance) return

        // Hack to get all annotations rendered
        await wait(3000)

        const annotations = annotationManager.getAnnotationsList();

        const testAnn207 = annotations.find(el => el.getCustomData(CustomDataKey.originalValue) === '207.25')
        const testAnn40 = annotations.find(el => el.getCustomData(CustomDataKey.originalValue) === '40.20')

        // const testAnn207Exclude = testAnn207?.getCustomData(CustomDataKey.excludeFromSummary) === 'true'
        // const testAnn207Hidden = testAnn207?.Hidden === true
        //
        // const testAnn40Exclude = testAnn40?.getCustomData(CustomDataKey.excludeFromSummary) === 'true'
        // const testAnn40Hidden = testAnn40?.Hidden === true

        const exportableAnnotations = annotations
            .filter(
                annot => annot.getCustomData(CustomDataKey.excludeFromSummary) !== 'true',
            ).filter(
                annot => !(annot.Hidden || annot.NoView),
            ) .filter(
                // Emptry ticks/conf box
                annot => annot.getCustomData('annotationVariant') === AnnotationVariant.valueConfidence ? Boolean(annot.getContents()?.length) : true,
            )

        const xfdfString = await annotationManager.exportAnnotations({ annotList: exportableAnnotations });
        const data = await pdfDocument.getFileData({ xfdfString });

        const arr = new Uint8Array(data);
        const blob = new Blob([arr], { type: 'application/pdf' });

        // Only add legend page if at least one tick type is selected
        let finalBlob = blob;
        if (
            includeGreenTicks ||
            includeRedTicks
        ) {
            const docToInsert = await pdfInstance.Core.createDocument('/files/legendV2.pdf', {});
            const mainDoc = await pdfInstance.Core.createDocument(blob, {});

            await mainDoc.insertPages(docToInsert, [1], 1);
            
            const updatedData = await mainDoc.getFileData();
            finalBlob = new Blob([new Uint8Array(updatedData)], { type: 'application/pdf' });
        }

        // Download the file
        downloadBlobFile(
            finalBlob,
            `${reportData?.name || 'report'}_reviewed.pdf`,
        );

        await wait(500)

        setGeneratingPdf(false)

        message.success('PDF downloaded successfully');
    }

    return {
        downloadPdf: () => handleDownload(),
        downloadingPdf: generatingPdf,
    };
};
