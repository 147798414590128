import { collection } from 'firebase/firestore';

import { getRequestQueries } from '@/api/utils/getRequestQueries';

import { db } from '../config.ts';

export interface CompanyEntity {
    name: string
    /**
     * Parent company
     */
    clientCompanyId: string

    companyId: string
    /**
     * Will be added for auto generated items
     */
    providedBy?: 'ai'
}

export const companyEntityRef = collection(db, 'companyEntity')

export const { useFBItemsQuery: useCompanyEntityQuery } = getRequestQueries<CompanyEntity, {}>({ url: 'companyEntity' })
